import { FormLabel as Label, Radio } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import { Column } from "devextreme-react/data-grid";
import React, { Component } from 'react';
import Config from '../../../../config/index';
import Icons from "../../../common/icons/";
import GridContainer from "../../../grid-container/grid-container";
import PropTypes from "prop-types";

const styles = {
    dataGridHover: {
        left: '0 !important',
        transform: 'translateY(-1px)',
        '& button': {
            margin: '0 !important',
            padding: '0 !important'
        }
    },
    columns: {
        height: '40px !important',
        minHeight: '40px !important',
    },
    textTitle: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '20px',
        color: '#000000',
    }
};

class W06F0007PO extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
        this.tmpSelectedRowData = [];
    }


    componentDidMount = () => {
        if (this.props.onRefPO) this.props.onRefPO(this);
    }

    onDelete = (e) => {
        const dataFilter = this.props.dataGridMappingInfo.filter(rs => rs.PurchaseItemID === e.data.PurchaseItemID);
        if (dataFilter.length > 0) {
            Config.popup.show("INFO", Config.lang("ERP_Du_lieu_da_duoc_su_dung_ban_khong_duoc_xoa"));
        } else {
            Config.popup.show("YES_NO", Config.lang("ERP_Ban_co_chac_muon_xoa?"),
                () => { // YES
                    e.component.deleteRow(e.rowIndex);
                    this.gridTabPO.instance.saveEditData();
                    this.props.refW06F0007.onCheckDelete();
                    this.props.refW06F0007.setIsUpdate();
                },
                () => { } // NO
            );
        }
    }

    renderBtnActions = (e) => {
        const { classes } = this.props;
        return (
            <div className={classes.dataGridHover + ' grid-action-hover hidden'}>
                <IconButton
                    disabled={false}
                    size={"small"}
                    aria-label={"delete"}
                    onClick={() => this.onDelete(e)}
                    color={"default"}
                >
                    <Icons name={"delete"} />
                </IconButton>
            </div>
        )
    }

    setData = () => {
        const { dataGridPO } = this.props;
        dataGridPO.map(item => {
            item.isUsed = 0;
            return item;
        });
    }

    onChangePO = (data) => {
        const { dataGridPO } = this.props;
        dataGridPO.map(item => {
            if (item.PurchaseItemID === data.PurchaseItemID) {
                item.isUsed = 1;
            } else {
                item.isUsed = 0;
            }
            return item;
        });
        this.props.refW06F0007.onCheckPO(dataGridPO);
    }

    cellRenderRadio = (e) => {
        return (
            <Radio style={{ width: 21, height: 21 }}
                size={"small"}
                color={"primary"}
                onChange={() => this.onChangePO(e.data)}
                value={e.data.isUsed}
                checked={e.data.isUsed === 1} />
        )
    }

    render() {
        const { dataGridPO, isView, refW06F0007, classes, btnActionStatus } = this.props;
        return (
            <div style={{ marginBottom: 30 }}>
                <Label style={{ fontWeight: 500, color: 'black', fontSize: 16, }} className={"cursor-pointer pdb10 text-title"} >{Config.lang("ERP_Hop_dong_mua")}</Label>
                <GridContainer
                    reference={ref => this.gridTabPO = ref}
                    // disabled={btnActionStatus}
                    dataSource={dataGridPO}
                    columnAutoWidth={true}
                    listPerPage={[5, 10, 15, 30, 45, 60]}
                    itemPerPage={5}
                    typePaging={"normal"}
                    keyExpr={"PurchaseItemID"}
                    height={300}
                    typeShort={true}
                    pagerFullScreen={false}
                    showColumnLines={false}
                    showRowLines={false}
                    hoverStateEnabled={true}
                    sorting={{ mode: "none" }}
                >
                    {!btnActionStatus &&
                        <Column
                            minWidth={50}
                            caption={Config.lang("ERP_Chon")}
                            alignment={"center"}
                            dataField={"isUsed"}
                            visible={!isView}
                            cellRender={this.cellRenderRadio}
                            cssClass={`${classes.columnGrid} ${classes.radioButton}`}
                        />
                    }
                    <Column
                        caption={Config.lang("ERP_So_hop_dong_mua")}
                        dataField={"ContractNo"}
                        allowEditing={false}
                        minWidth={150}
                    />
                    <Column
                        caption={Config.lang("ERP_Nha_cung_cap")}
                        dataField={"SupplierName"}
                        allowEditing={false}
                        minWidth={150}
                    />
                    <Column
                        caption={Config.lang("ERP_Ma_hang")}
                        dataField={"InventoryID"}
                        allowEditing={false}
                        minWidth={150}
                    />
                    <Column
                        caption={Config.lang("ERP_Ten_hang")}
                        dataField={"InventoryNameU"}
                        allowEditing={false}
                        minWidth={150}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong")}
                        dataField={"SUMQuantity"}
                        allowEditing={false}
                        minWidth={150}
                        cellRender={refW06F0007.renderNumberFormat}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong_da_mapping")}
                        dataField={"MAPQuantity"}
                        allowEditing={false}
                        minWidth={150}
                        cellRender={refW06F0007.renderNumberFormat}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong_con_lai")}
                        dataField={"Quantity"}
                        allowEditing={false}
                        minWidth={150}
                        cellRender={refW06F0007.renderNumberFormat}
                    />
                </GridContainer>
            </div>
        );
    }
}

W06F0007PO.propTypes = {
    isView: PropTypes.bool,
    btnActionStatus: PropTypes.bool,
    dataGridPO: PropTypes.array,
    dataGridMappingInfo: PropTypes.array,
    onRefPO: PropTypes.any,
    refW06F0007: PropTypes.any,
};
export default withStyles(styles)(W06F0007PO);