import withStyles from "@material-ui/core/styles/withStyles";
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import React from "react";
import { Animated } from "react-animated-css";
import InlineSVG from "react-inlinesvg";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as W94F1000Actions from "../../../../redux/W9X/W94F1000/W94F1000_actions";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import GridContainer from "../../../grid-container/grid-container";

const drawerWidth = '47%';
const heightHeader = 50;
const styles = theme => {
    return {
        drawer: {
            width: drawerWidth,
            maxWidth: 700,
            flexShrink: 0,
            [theme.breakpoints.down('sm')]: {
                zIndex: '1502 !important',
            },
        },
        drawerHeader: {
            // height: heightHeader,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            padding: '5px 0'
        },
        drawerPaper: {
            width: drawerWidth,
            maxWidth: 700,
            [theme.breakpoints.down(768)]: {
                width: '100%',
            },
            top: heightHeader,
            backgroundColor: '#3e3e3e',
            opacity: 1,
            // zIndex: 1502,
            padding: '0 15px 15px 15px',
            overflow: 'hidden',
            height: 'calc(100% - 50px)'
        },
        tabs: {
            maxWidth: 'calc(100% - 60px)',
        },
        tabContent: {
            padding: '10px 0',
            minHeight: 400
        },
        itemContent: {
            lineClamp: 3,
            maxHeight: '4.2rem',
            whiteSpace: 'pre-line',
            [theme.breakpoints.down(768)]: {
                lineClamp: 2,
                maxHeight: '2.8rem',
            }
        },
        item: {
            width: 200,
            height: 260,
            borderRadius: 24,
            margin: 14,
            padding: 28,
            color: '#3E4050',
            position: 'relative',
            overflow: 'hidden',
            '& span': {
                fontSize: '2.85rem',
                lineHeight: '2.85rem',
                fontWeight: 700,
                // color: '#979797'
            },
            '&:hover .btn-hover': {
                display: 'flex !important',
            },
            '& .btn-hover': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.4)',
                justifyContent: 'center',
                display: 'none',
                '& span': {
                    border: '1px solid white',
                    borderRadius: 6,
                    fontSize: '1.7rem',
                    fontWeight: 300,
                    color: 'white',
                    width: '60%',
                    display: 'flex',
                    cursor: 'pointer',
                    justifyContent: 'center',
                }
            },
            '& b': {
                fontSize: '1.4rem'
            },
            [theme.breakpoints.down(768)]: {
                width: '100%',
                height: 50,
                padding: '5px 10px',
                margin: '15px 0 0',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'flex-start',
                '& span': {
                    marginRight: 10,
                    fontSize: '2.2rem',
                },
                '& b': {
                    display: 'none'
                },
                '& .btn-hover span': {
                    borderRadius: 100,
                    fontSize: '0.8rem',
                    fontWeight: 300,
                },
            },
        },
        numberIdx: {
            width: 30,
            height: 30,
            borderRadius: 6,
            fontSize: 14,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            margin: 'auto',
            fontWeight: 700
        },
        iconEye: {
            borderRadius: 100,
            padding: 3,
            margin: 'auto',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#E1E1E1'
            }
        },
        reportsListMode: {
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center'
        }
    }
};

class W94F1000 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            reportDataLoadded: false,
            tabIdx: null,
            isGrid: Config.getLocalStorage("ERP_VIEW") !== "list" // boolean
        };
        this.Language = Config.language || "84";
        this.FormID = "W94F1000";
        this.paramGetReports = {
            MenuID: "",
            MReportID: ""
        }
    }

    componentDidMount = () => {
        this.getDataReports();
        // const { listGroupChart } = this.props;
        // if (_.isEmpty(listGroupChart)) {
        //     this.getDataReports();
        // } else {
        //     this.setState({
        //         tabIdx: listGroupChart[0].ReportGroupID,
        //         isGrid: Config.getLocalStorage("ERP_VIEW") !== "list"
        //     });
        // }
    }

    getMenuID = () => {
        const { state } = this.props.location;
        let MenuID = "";
        MenuID = this.paramGetReports.MenuID = state?.MenuID;
        // if (_.isUndefined(MenuID)) { // Lấy mặt định (Không cần)
        //     const menu = JSON.parse(Config.getLocalStorage('MENUERP'));
        //     const currentPage = menu.listMenu.find(item => item.FormID === this.FormID);
        //     MenuID = this.paramGetReports.MenuID = JSON.parse(currentPage?.Parameters).MenuID; // Get Default MenuID
        // }
        return { MenuID }
    }

    getDataReports = () => {
        let { MReportID } = this.paramGetReports;
        const { tabIdx } = this.state;
        const { MenuID } = this.getMenuID();
        if (_.isUndefined(MenuID)) {
            browserHistory.push(Config.getRootPath() + "dashboard");
            return null;
        } else {
            const params = {
                MenuID,
                MReportID
            };
            this.setState({ reportDataLoadded: true })
            this.props.W94F1000Actions.getGroupList(params, (err, data) => {
                this.setState({ reportDataLoadded: false })
                if (err) {
                    let message = err.message || Config.lang("Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                }
                if (!_.isEmpty(data) && !tabIdx) {
                    this.setState({
                        tabIdx: data[0].ReportGroupID,
                        isGrid: Config.getLocalStorage("ERP_VIEW") !== "list"
                    });
                }
            });
        }
    }

    onLink = (item) => {
        if (!item) return null;
        if (item.ReportType === "EMBED" && !item.EmbedCode) {
            Config.popup.show("INFO", Config.lang("No_link"));
            return;
        }
        const { ModuleID = "", MReportID = "", MReportName = "", URL = "", strSQL = "", Sheet = "", FileName = "" } = item;
        browserHistory.push({
            pathname: Config.getRootPath() + (item.ReportType === "EMBED" ? "W94F1001" : "W94F1002"),
            state: {
                MenuID: this.paramGetReports.MenuID,
                URL,
                Sheet,
                strSQL,
                ModuleID,
                FileName,
                MReportID,
                MReportName,
            }
        })
    };

    renderReportName = (data) => {
        return <div style={{ fontWeight: 200 }}>{data.value}</div>;
    };

    onBack = () => {
        browserHistory.goBack();
    };

    render() {
        const { listGroupChart, classes, themes } = this.props;
        const { tabIdx, isGrid, reportDataLoadded } = this.state;
        this.indexHidden = 0;
        return (
            <div>
                <ActionToolbar allwaysTop title={Config.lang("Danh_sach_bao_cao")} >
                    <div className={classes.reportsListMode}
                        onClick={() => {
                            this.setState({ isGrid: !this.state.isGrid }, () => {
                                Config.setLocalStorage("ERP_VIEW", this.state.isGrid ? "grid" : "list");
                                browserHistory.push({
                                    pathname: Config.getRootPath() + "W94F1000",
                                    state: {
                                        ReportGroupID: tabIdx,
                                        isGrid: this.state.isGrid
                                    }
                                });
                            })
                        }} >
                        <InlineSVG width={14} height={14}
                            src={isGrid ? require("../../../../assets/images/general/icon-grid.svg") : require("../../../../assets/images/general/icon-th-list.svg")}
                        />
                    </div>
                </ActionToolbar>
                <div className={"display_row flex-wrap"}>
                    {isGrid && (
                        <GridContainer
                            dataSource={listGroupChart}
                            selection={{
                                mode: "single",
                            }}
                            loading={reportDataLoadded}
                            onCellClick={(e) => this.onLink(e.data)}
                            showBorders={false}
                            showColumnLines={false}
                            hoverStateEnabled={true}
                        >
                            <Column
                                style={{ backgroundColor: "#b3bbc1" }}
                                caption={Config.lang("So_thu_tu")}
                                sortOrder={"asc"}
                                cellRender={(data) => {
                                    let indexColor = "none";
                                    if (themes && themes.length > 0) {
                                        indexColor = parseInt(data.value) % themes.length;
                                    }
                                    return (
                                        <span className={classes.numberIdx}
                                            style={{
                                                backgroundColor: indexColor === 'none' ? 'white' : themes[indexColor].SubColor,
                                                color: indexColor === 'none' ? '#707070' : themes[indexColor].MainColor,
                                                border: indexColor === 'none' ? '1px solid #979797' : 'none',
                                            }}>#{data.value}</span>)
                                }}
                                dataType={"number"}
                                dataField={"OrderNo"}
                                alignment={"center"}
                                width={window.innerWidth < 576 ? "120" : "140"}
                            />
                            <Column
                                style={{ backgroundColor: "#b3bbc1" }}
                                caption={Config.lang("Bao_cao")}
                                cellRender={this.renderReportName}
                                dataField={"MReportName"}
                                alignment={"left"}
                            />
                        </GridContainer>
                    )}
                    {!isGrid && listGroupChart && listGroupChart.map((item, idx) => {
                        let indexColor = "none";
                        if (themes && themes.length > 0) indexColor = idx % themes.length;
                        return (
                            <Animated key={idx}
                                animationIn={"flipInX"}
                                animationOut={"rotateOut"}
                                isVisible={true}
                                className={`display_col jus-between ${classes.item}`}
                                style={{
                                    display: 'flex',
                                    backgroundColor: indexColor === 'none' ? 'white' : themes[indexColor].SubColor,
                                    border: indexColor === 'none' ? '1px solid #979797' : 'none'
                                }}
                                onClick={() => this.onLink(item)}
                            >
                                <span style={{ color: indexColor === 'none' ? '#707070' : themes[indexColor].MainColor }} >#{idx - this.indexHidden + 1}</span>
                                <div className={`txt-two-line ${classes.itemContent}`}>
                                    <b>{Config.lang("Bao_cao") + "\n"}</b>
                                    {item.MReportName}
                                </div>
                                <div className={"btn-hover display_row align-center"}
                                    onClick={() => this.onLink(item)}>
                                    <span>{Config.lang("Xem")}</span>
                                </div>
                            </Animated>
                        )
                    })}
                </div>
            </div>
        )
    }
}

export default compose(connect((state) => ({
    themes: state.main.themes,
    listGroupChart: state.W94F1000.listGroupChart,
}), (dispatch) => ({
    W94F1000Actions: bindActionCreators(W94F1000Actions, dispatch)
})), withStyles(styles, { withTheme: true }))(W94F1000);
