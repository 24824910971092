/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/13/2020
 * @Example
 */
import { FormLabel as Label, withStyles } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TextField from '@material-ui/core/TextField';
import ReorderIcon from '@material-ui/icons/Reorder';
import { Popover } from "devextreme-react";
import { Column } from "devextreme-react/data-grid";
import { Lookup } from 'devextreme-react/lookup';
import _ from "lodash";
import moment from 'moment';
import PropTypes from "prop-types";
import React from "react";
import { Col, FormGroup, Row } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config/index";
import * as W06F0002Actions from "../../../../redux/W0X/W06F0002/W06F0002_actions";
import ButtonGeneral from "../../../common/button/button-general";
import Icons from "../../../common/icons/";
import SelectIDNameModal from "../../../common/selectIDNameModal/SelectIDNameModal";
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";

const styles = {
  distance: {
    marginTop: 30,
    marginBottom: 15,
    width: '100%'
  },
  divHeaderTitle: {
    fontWeight: 'bold',
    fontStyle: 'normal',
    fontSize: '12px',
    textTransform: 'uppercase',
    color: '#8F9BB3',
    paddingRight: 15,
  },
  divHeaderItem: {
    fontWeight: '600',
    fontStyle: 'normal',
    color: '#222B45',
    fontSize: 16
  },
  numberFormatInput: {
    backgroundColor: 'transparent',
    border: 'none',
    margin: 0
  },
  specPopup: {
    '& .MuiFilledInput-root': {
      height: 210,
      paddingRight: '2px !important',
      '& textarea': {
        height: '100%'
      }
    }
  },
  specPopupMobile: {
    '& .MuiFilledInput-root': {
      height: 170,
      paddingRight: '2px !important',
      '& textarea': {
        height: '100%'
      }
    }
  },
  ellipsis: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  specCell: {
    display: 'flex',
    alignItems: 'center',
    width: '-webkit-fill-available'
  }
};
class W06F0002Inventory extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      disabledQDField: false,
      gridLoading: false,
      showInventorySelect: false,
      dataCboUnit: {},
      selectedRow: [],
      formDataList: [],
      dataAvailable: [],
      dataCboPackingType: [],
      dataDetailSupplement: []
    };
    this.loadCboUnit = false;
    this.countPurchaseItemID = 0;
    this.specificationValue = "";
    this.rowEdit = null;
    this.dataGrid = null;
    this.txtSpecification = null;
    this.selectedRange = {};
    this.OTotal = this.props.formData.OTotal;
    this.Ctotal = this.props.formData.Ctotal;
    this.resultOTotal = null;
    this.resultCTotal = null;
  }

  componentDidMount = async () => {
    const { formDataList, dataDetailSupplement, getCboPackingMethod, getCboPackingType, getCboBaseCurrency, dataCboUnit, formData } = this.props;
    formDataList && await this.setState({ formDataList: [...formDataList], dataDetailSupplement });
    const Language = Config.language || "84";
    const formDataListKeys = formDataList.map(item => item.InventoryID);
    const formDataListKeysUnique = [...new Set(formDataListKeys)];
    if ((Object.keys(dataCboUnit).length === formDataListKeysUnique.length)) {
      this.setState({ dataCboUnit });
    }
    if (_.isEmpty(getCboPackingMethod)) {
      this.loadApi("loadCboPackingMethod", {}, true);
    }
    if (_.isEmpty(getCboPackingType)) {
      this.loadApi("loadCboPackingType", { Language }, true);
    }
    if (_.isEmpty(getCboBaseCurrency)) {
      this.loadApi("loadBaseCurrency", {}, true);
    } else {
      if (getCboBaseCurrency.BaseCurrencyID && formData.CurrencyID) this.setState({ disabledQDField: getCboBaseCurrency.BaseCurrencyID === formData.CurrencyID });
    }
  }

  componentWillUnmount = () => {
    this.setState = (state, callback) => {
      return;
    };
  }

  loadApi = (cboName, params = {}, ownAction = false) => {
    this.setState({ gridLoading: true });
    this.props[ownAction ? "W06F0002Actions" : "generalActions"][cboName](
      params,
      (error, data = null) => {
        this.setState({ gridLoading: false });
        if (error) {
          let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
          Config.popup.show("INFO", message);
          return false;
        }
        if (data && cboName === "loadBaseCurrency") {
          const { formData } = this.props;
          if (data.BaseCurrencyID && formData.CurrencyID) this.setState({ disabledQDField: data.BaseCurrencyID === formData.CurrencyID });
        }
      }
    );
  };

  loadApiUnit = async (InventoryID, cb) => {
    if (!InventoryID) return;
    const param = { InventoryID };
    await this.props.W06F0002Actions.loadCboUnit(param, cb)
  };

  openInventorySelect = () => {
    this.setState({ showInventorySelect: true });
  };

  onSelectedInventory = async (dataSelected) => {
    await dataSelected.forEach(item => {
      item["Quantity"] = "";
      item["UnitPrice"] = "";
      item["PackingMethodID"] = "";
      item["PackingTypeID"] = "";
    })
    const { formDataList } = this.state;
    const { onChangeInventories } = this.props;
    let inventoryIDList = [];
    formDataList && formDataList.map((item) => inventoryIDList.push(item.InventoryID));
    dataSelected = dataSelected && dataSelected.map(d => {
      this.countPurchaseItemID++;
      return { ...d, PurchaseItemID: this.countPurchaseItemID };
    });
    formDataList.unshift(...dataSelected);
    onChangeInventories(formDataList);
    this.setState({ showInventorySelect: false });
    this.loadCboUnit = false;
  };

  closeInventorySelect = () => {
    this.setState({ showInventorySelect: false });
  };

  numberFormat = (number, limitNumber = 0) => {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: limitNumber || 0,
      maximumFractionDigits: limitNumber || 0,
    });
    const _number = formatter.format(String(number));
    return !_number || _number === "NaN" ? 0 : _number;
  };

  mathAmountValue = async (e) => {
    const { formData } = this.props;
    const { formDataList, dataCboUnit } = this.state;
    const { ExchangeRate = 1, OTotal, Ctotal } = formData;
    if (e) { // Add or Edit Row
      const { newData, oldData } = e;
      const rowIndex = e.component.getRowIndexByKey(e.key);
      const rowData = formDataList[rowIndex];
      const Quantity = newData.Quantity || oldData.Quantity;
      const UnitPrice = newData.UnitPrice || oldData.UnitPrice;
      const UnitID = newData.UnitID || oldData.UnitID;
      rowData.OAmount = Quantity * UnitPrice;
      rowData.CAmount = Quantity * UnitPrice * ExchangeRate;
      if ((UnitID || Quantity) && !_.isEmpty(dataCboUnit)) {
        const cboUnitChosen = (dataCboUnit[rowData.InventoryID]).find(item => item.UnitID === UnitID);
        if (cboUnitChosen && cboUnitChosen.ConversionFactor) {
          const { ConversionFactor } = cboUnitChosen;
          rowData.CQuantity = Quantity * ConversionFactor;
        }
      }
      formDataList[rowIndex] = rowData;
    } else { // Delete Row
      await formDataList.forEach(async item => {
        const { Quantity, UnitPrice, UnitID, InventoryID } = item;
        if (!_.isUndefined(Quantity) && !_.isUndefined(UnitPrice)) {
          item.OAmount = Quantity * UnitPrice;
          if (!_.isUndefined(ExchangeRate)) {
            item.CAmount = Quantity * UnitPrice * ExchangeRate;
          }
        }
        if (!_.isUndefined(Quantity) && !_.isUndefined(UnitID) && !_.isEmpty(dataCboUnit[InventoryID])) {
          const cboUnitChosen = (dataCboUnit[InventoryID]).find(item => item.UnitID === UnitID);
          if (cboUnitChosen && cboUnitChosen.ConversionFactor) {
            const { ConversionFactor } = cboUnitChosen;
            item.CQuantity = Quantity * ConversionFactor;
          }
        }
      });
    }
    await this.handleNumberFormat(OTotal, "OTotal");
    await this.handleNumberFormat(Ctotal, "Ctotal");
  }

  handleNumberFormat = async (number, fieldName) => {
    const { onChangeFormData, formData, getDecimalQuantity } = this.props;
    const { formDataList, disabledQDField } = this.state;
    const { OriginalDecimal, ConvertedDecimal } = getDecimalQuantity;
    if (fieldName === "OTotal") { // OTotal
      const amountData = formDataList.filter(item => item.OAmount);
      number = amountData.reduce((currentValue, sum) => currentValue + sum.OAmount, 0);
      if (formData.OTotal !== number) {
        this.OTotal = number;
        const formatNumberOTotal = this.numberFormat(number, OriginalDecimal);
        this.resultOTotal.innerHTML = formatNumberOTotal;
        onChangeFormData(number, "OTotal")
      }
    }
    else if (fieldName === "Ctotal") { // Ctotal
      const { OTotal, ExchangeRate } = formData;
      number = OTotal * ExchangeRate;
      if (formData.Ctotal !== number) {
        this.Ctotal = number;
        if (!disabledQDField) {
          const formatNumberOTotal = this.numberFormat(number, ConvertedDecimal);
          this.resultCTotal.innerHTML = formatNumberOTotal;
        }
        onChangeFormData(number, "Ctotal")
      }
    }
  };

  renderInventoryColumn = (activeInventoryColumn) => {
    const supColumn = activeInventoryColumn.map((item, i) => {
      const { Caption } = item;
      let { FieldName } = item;
      let fieldType = "";
      let formatDate = "";
      let alignment = "left";
      const width = Caption.length > 20 ? 200 : 150;
      const limitFieldName = FieldName.slice(0, parseInt("-" + (FieldName.length - 3).toString()));
      switch (limitFieldName) {
        case "Num":
          fieldType = "number";
          alignment = "right";
          break;
        case "Dat":
          fieldType = "date";
          alignment = "center";
          formatDate = "dd/MM/yyyy";
          break;
        case "Str":
          fieldType = "string";
          FieldName = FieldName.indexOf("U") > -1 && FieldName.indexOf("U") === FieldName.length - 1 ? FieldName : FieldName.concat("U")
          break;
        default:
          break;
      }
      return (
        <Column
          key={i}
          caption={Caption}
          dataField={FieldName}
          dataType={fieldType}
          format={formatDate}
          width={width}
          alignment={alignment}
          cellRender={(e) => this.cellRender(e)}
        />
      )
    });
    return supColumn;
  };

  cellRender = (e) => {
    const { column } = e;
    const { data } = e.row;
    const { mode, contractTypesData, classes } = this.props;
    if (!data || !column || !column.dataField) return;
    let tranFormField = mode === "addFinal" ? (_.isUndefined(data[column.dataField]) ? contractTypesData["Default" + column.dataField] : data[column.dataField]) : data[column.dataField];
    //Nếu mode là ADD thì lấy default ngược lại thì lấy từ APi
    if (!_.isEmpty(tranFormField) || _.isNumber(tranFormField)) { //Get Data Default
      data[column.dataField] = data && (tranFormField || tranFormField === 0) ? tranFormField : "";
    }
    const limitFieldName = (column.dataField).slice(0, parseInt("-" + ((column.dataField).length - 3).toString()));
    const parseDateTime = mode === "addFinal" ? moment(tranFormField).format("DD/MM/YYYY") : moment.utc(tranFormField).format("DD/MM/YYYY");
    if (limitFieldName === "Dat" && !_.isNull(tranFormField) && !_.isNumber(tranFormField)) {
      tranFormField = !_.isUndefined(tranFormField) ? parseDateTime : tranFormField
    }
    return <div className={classes.ellipsis} title={tranFormField}>{tranFormField}</div>;
  };

  onDelete = (e) => {
    const { formDataList } = this.state;
    const { onChangeInventories } = this.props;
    e.component.deleteRow(e.rowIndex);
    onChangeInventories(formDataList);
    this.mathAmountValue()
  };

  onEditRow = (e) => {
    this.setState({ isEditing: true }, () => {
      setTimeout(() => {
        if (e) e.component.editRow(e.rowIndex);
      }, 300);
    });
  };

  renderIconAction = (e) => {
    return (
      <IconButton
        aria-label={"delete"}
        size={"small"}
        onClick={() => this.onDelete(e, 0)}
      >
        <Icons name={"delete"} />
      </IconButton>
    );
  };

  renderButtonAction = (e, mode) => {
    return (
      mode === "mobile" ?
        this.renderIconAction(e)
        : <GridActionBar>
          {this.renderIconAction(e)}
        </GridActionBar>
    );
  };


  renderNumberFormat = (e) => {
    if (!e) return false;
    let limitDecimal = 0;
    const { data } = e.row;
    const { column } = e;
    const { getDecimalQuantity } = this.props;
    const dataField = column.dataField;
    let decimalValue = data[dataField];
    if (_.isUndefined(decimalValue)) return;
    if (getDecimalQuantity) {
      const { UnitPriceDecimals = 0, QuantityDecimals = 0, OriginalDecimal = 0, ConvertedDecimal = 0, ConvertedQuantityDecimals = 0 } = getDecimalQuantity;
      switch (dataField) {
        case "UnitPrice":
          limitDecimal = UnitPriceDecimals;
          break;
        case "Quantity":
          limitDecimal = QuantityDecimals;
          break;
        case "OAmount":
          limitDecimal = OriginalDecimal;
          break;
        case "CAmount":
          limitDecimal = ConvertedDecimal;
          break;
        case "CQuantity":
          limitDecimal = ConvertedQuantityDecimals;
          break;
        default:
          break;
      }
    }
    return <NumberFormat decimalScale={limitDecimal} value={decimalValue} displayType={"text"} thousandSeparator={true} />
  };

  onChangeCboInven = (data, valueID) => {
    //Hàm này dùng để get những name combo thay đổi để ta lấy name thay đổi đó lưu lịch sử 
    const { getCboPackingMethod, getCboPackingType, formDataList } = this.props;
    let { newData, key } = data;
    let fieldName = valueID.replace(/ID/gi, "Name") || null;
    let valueName = null;
    switch (valueID) {
      case "PackingMethodID": {
        const dataPackingMethodChosen = getCboPackingMethod.find(item => item.PackingMethodID === newData[valueID]);
        valueName = dataPackingMethodChosen[fieldName];
        break;
      }
      case "PackingTypeID": {
        const dataPackingTypeChosen = getCboPackingType.find(item => item.PackingTypeID === newData[valueID]);
        valueName = dataPackingTypeChosen[fieldName];
        break;
      }
      default:
        return;
    }
    formDataList.forEach(item => {
      if (item.PurchaseItemID === key.PurchaseItemID && !_.isUndefined(valueName)) {
        item[fieldName] = valueName;
      }
    });
  }

  showEditSpecPopup = (evt, cellData) => {
    if (!cellData) return false;
    const rowData = cellData.data;
    this.rowEdit = { rowIndex: cellData.rowIndex || 0 };
    if (this.popover && this.txtSpecification) {
      this.txtSpecification.value = this.specificationValue = rowData.Specification || "";
      this.popover.instance.option("target", evt.currentTarget);
      setTimeout(() => this.popover.instance.show(), 300);
    }
  };

  cellRenderPopupAction = (cellData) => {
    const dataField = cellData.column.dataField,
      rowData = cellData.data;
    const { classes } = this.props;
    return (
      <div className={`${classes.specCell} pull-left mgr5`}>
        <IconButton className={'btn_edit_spec'} ref={ref => this.btnPopover = ref} size={"small"}
          onClick={(e) => this.showEditSpecPopup(e, cellData)}>
          <ReorderIcon fontSize={"small"} />
        </IconButton>
        <div className={`${classes.ellipsis} pull-left textCellHidden`} title={rowData[dataField] || ""}>{rowData[dataField]}</div>
      </div>
    );
  };

  onClosePopup = () => {
    this.specificationValue = "";
  };

  onChangeSpecification = (e) => {
    this.specificationValue = e.target && e.target.value ? e.target.value : "";
  };

  onSaveSpecification = () => {
    if (this.dataGrid && this.rowEdit) {
      this.dataGrid.instance.cellValue(this.rowEdit.rowIndex, "Specification", this.specificationValue);
      this.dataGrid.instance.saveEditData();
      this.popover.instance.hide();
    }
  };

  renderContentPopover = () => {
    return (
      <div style={{ padding: 15, overflow: "hidden", height: '100%' }}>
        <TextField
          label={Config.lang("ERP_Tieu_chuan_chat_luong")}
          {...(!(window.innerWidth < 576) ? {
            className: this.props.classes.specPopup
          } : { className: this.props.classes.specPopupMobile })}
          variant={"filled"}
          margin={"none"}
          defaultValue={this.specificationValue || ""}
          multiline={true}
          rows={4}
          inputProps={{
            ref: ref => this.txtSpecification = ref
          }}
          InputLabelProps={{
            style: { fontWeight: 'bold' },
            shrink: true
          }}
          onChange={e => this.onChangeSpecification(e)}
          fullWidth
        />
        <div className={"pull-right"} style={{ marginTop: 15 }}>
          <ButtonGeneral
            name={Config.lang("ERP_Luu")}
            typeButton={"save"}
            style={{ textTransform: "uppercase" }}
            size={"small"}
            onClick={this.onSaveSpecification}
          />
        </div>
      </div>
    );
  };

  render() {
    const { classes, gridRef, getCboPackingMethod, getCboPackingType, mode, activeInventoryColumn, getDecimalQuantity, isUpdatedStatus } = this.props;
    const { isSaving, gridLoading, showInventorySelect, selectedRow, formDataList, disabledQDField } = this.state;
    const { QuantityDecimals = 0, UnitPriceDecimals = 0, OriginalDecimal = 0, ConvertedDecimal = 0 } = getDecimalQuantity;
    const dropDownNameList = ['PackingMethodID', 'PackingTypeID'];
    return (
      <React.Fragment>
        <div style={{ width: "100%", paddingTop: 16 }}>
          <ButtonGeneral
            disabled={gridLoading}
            size={"large"}
            typeButton={"add"}
            style={{ textTransform: "uppercase", magin: '16px 0' }}
            name={Config.lang("ERP_Them_hang_hoa")}
            onClick={this.openInventorySelect}
          />
          {showInventorySelect && <SelectIDNameModal
            formID={"W06F0002"}
            maxWidth={"sm"}
            modalType={"Inventory"}
            selectedRow={selectedRow}
            openModal={showInventorySelect}
            selectedRowKeys={(e) => this.setState({ selectedRow: e })}
            onChosen={this.onSelectedInventory}
            onClose={this.closeInventorySelect}
          />}
          <Popover
            ref={ref => this.popover = ref}
            target={null}
            position={"bottom"}
            {...(window.innerWidth < 576 ? {
              width: '100%'
            } : {
                width: 580,
                height: 290
              })}
            shading={true} //block scroll
            shadingColor='transparent'
            deferRendering={false}
            onHidden={this.onClosePopup}
            contentRender={this.renderContentPopover}
          />
          <Row className={classes.distance}>
            <Col xs={12} sm={6} md={6} lg={6}>
              <div>
                <Label className={classes.divHeaderTitle}>{Config.lang("ERP_Tong_gia_tri_hop_dong")}:</Label>
                <span className={`${classes.divHeaderItem}`}>
                  <NumberFormat getInputRef={ref => this.resultOTotal = ref} decimalScale={OriginalDecimal} value={this.OTotal} displayType={'text'} thousandSeparator={true} />
                </span>
              </div>
            </Col>
            {!disabledQDField &&
              <Col xs={12} sm={6} md={6} lg={6}>
                <div>
                  <Label className={classes.divHeaderTitle}>{Config.lang("ERP_Tong_gia_tri_hop_dong_QD")}:</Label>
                  <span className={`${classes.divHeaderItem}`}>
                    <NumberFormat getInputRef={ref => this.resultCTotal = ref} decimalScale={ConvertedDecimal} value={this.Ctotal} displayType={'text'} thousandSeparator={true} />
                  </span>
                </div>
              </Col>}
          </Row>
          <FormGroup>
            <GridContainer
              reference={ref => {
                gridRef && gridRef(ref);
                this.dataGrid = ref;
              }}
              dataSource={formDataList}
              rowAlternationEnabled={true}
              allowCellSelection={true}
              hoverStateEnabled={true}
              columnAutoWidth={true}
              showBorders={false}
              keyExpr={"PurchaseItemID"}
              columnResizingMode={'widget'}
              elementAttr={{ class: `noBGSelect`, style: 'min-height: 300px' }}
              typeShort={window.innerWidth < 768}
              loading={gridLoading}
              disabled={isSaving}
              editing={{
                mode: "cell",
                refreshMode: "reshape",
                allowUpdating: true,
                texts: { confirmDeleteMessage: "" },
                startEditAction: "click",
              }}
              onRowUpdating={(e) => {
                const { newData } = e;
                const calculatingField = ["Quantity", "UnitPrice", "UnitID"];
                const columnName = (Object.keys(newData))[0];
                const { startRowIndex, startColumnIndex } = this.selectedRange;
                if (calculatingField.includes(columnName)) {
                  this.mathAmountValue(e)
                } else if (mode !== "addFinal") {
                  this.onChangeCboInven(e, columnName);
                }
                if (!isUpdatedStatus) { // Mục đích vì setState lần đầu tiên nên edit cell sẽ bị mất dấu nháy
                  this.props.isUpdated(); // SetState UpdatedData
                  setTimeout(() => this.dataGrid?.instance.editCell(startRowIndex, startColumnIndex), 100); // Mở edit cell đó
                }
              }}
              onEditorPreparing={(e) => {
                if (e.parentType === "dataRow" && dropDownNameList.includes(e.dataField)) {
                  //Hàm này dùng để add title để show tooltip trong dropdown khi ellipsis
                  e.editorOptions.itemTemplate = (itemData, itemIndex, itemElement) => {
                    const changeIDToName = (e.dataField.slice(0, e.dataField.indexOf('ID'))).concat("Name");
                    itemElement.innerHTML += itemData[changeIDToName];
                    itemElement.setAttribute("title", itemData[changeIDToName]);
                  }
                }
              }}
              onEditingStart={(e) => {
                if (e.column.dataField !== "Specification") return;
                e.cancel = true;
                const rowIndex = e.component.getRowIndexByKey(e.key);
                this.rowEdit = { rowIndex };
                if (this.popover && this.txtSpecification) {
                  this.txtSpecification.value = this.specificationValue = e.data.Specification || "";
                  const specElement = e.component.getCellElement(rowIndex, "Specification");
                  this.popover.instance.option("target", specElement.getElementsByClassName('btn_edit_spec')[0]);
                  setTimeout(() => this.popover.instance.show(), 300);
                }
              }}
              onContentReady={(e) => {
                if (!this.loadCboUnit) {
                  this.loadCboUnit = true;
                  const { dataCboUnit } = this.state;
                  const dataSource = e.component.option("dataSource")
                  dataSource.forEach(ivenItem => {
                    if (!dataCboUnit.hasOwnProperty(ivenItem.InventoryID)) {
                      this.loadApiUnit(ivenItem.InventoryID, (error, data) => {
                        this.setState({
                          dataCboUnit: {
                            ...this.state.dataCboUnit,
                            [ivenItem.InventoryID]: data
                          }
                        }, () => this.props.W06F0002Actions.onSaveDataUnit(this.state.dataCboUnit));
                      });
                    }
                  });
                }
              }}
              onKeyDown={(e) => {
                Config.onEnterAndTabKeyNavigation(e, this.selectedRange, this.dataGrid,
                  (rowIndex) => this.selectedRange.startRowIndex = rowIndex
                  , (columnIndex) => this.selectedRange.startColumnIndex = columnIndex);
              }}
              onCellSelectionChanged={(e) => {
                if (e.selectedRange) this.selectedRange = e.selectedRange;
              }}
            >
              <Column
                caption={Config.lang("ERP_Hanh_dong")}
                visible={Config.isMobile}
                alignment={"center"}
                cellRender={(e) => this.renderButtonAction(e, "mobile")}
              />
              <Column
                caption={Config.lang("ERP_Ma_hang")}
                width={220}
                allowEditing={false}
                fixed={!Config.isMobile}
                dataField={"InventoryID"}
              />
              <Column
                caption={Config.lang("ERP_Ten_hang")}
                width={220}
                allowEditing={false}
                fixed={!Config.isMobile}
                dataField={"InventoryNameU"}
              />
              <Column
                caption={Config.lang("ERP_Don_vi_tinh")}
                width={120}
                minWidth={120}
                dataField={"UnitID"}
                cellRender={(e) => {
                  const { data } = e;
                  const { dataCboUnit } = this.state;
                  const _dataCboUnit = dataCboUnit[data.InventoryID];
                  const Unit = _dataCboUnit && _dataCboUnit.find(cboUnit => cboUnit.UnitID === data.UnitID);
                  return Unit && Unit.UnitName;
                }}
                editCellRender={(e) => {
                  const { data } = e;
                  const { dataCboUnit } = this.state;
                  return <Lookup
                    height={24}
                    dataSource={dataCboUnit[data.InventoryID]}
                    value={data.UnitID}
                    valueExpr={"UnitID"}
                    displayExpr={"UnitName"}
                    placeholder={Config.lang("ERP_Chon") + "..."}
                    onValueChanged={l => {
                      if (l.value === data["UnitID"]) return;
                      e.component.cellValue(e.rowIndex, e.columnIndex, l.value);
                    }}
                  />
                }}
              />
              <Column
                caption={Config.lang("ERP_So_luong")}
                headerCellRender={(header) =>
                (<> {Config.lang("ERP_So_luong")}
                  <span style={{ color: 'red' }}>&nbsp;*</span>
                </>)}
                dataField={"Quantity"}
                alignment={"right"}
                dataType={"number"}
                width={150}
                cellRender={this.renderNumberFormat}
                editCellRender={(e) => {
                  // setTimeout(() => e.component.focus(e.cellElement), 300); //DbClick focus cell only
                  return (
                    <NumberFormat
                      style={{ width: '100%', height: 40 }}
                      className={classes.numberFormatInput}
                      thousandSeparator={true}
                      isNumericString={true}
                      value={e.value}
                      decimalScale={QuantityDecimals}
                      onValueChange={(c) => e.setValue(_.toNumber(c.value))}
                    />
                  )
                }}
              />
              <Column
                caption={Config.lang("ERP_So_luong_QD")}
                width={150}
                allowEditing={false}
                alignment={"right"}
                dataField={"CQuantity"}
                cellRender={this.renderNumberFormat}
              />
              <Column
                caption={Config.lang("ERP_Gia")}
                headerCellRender={(header) =>
                (<>
                  {Config.lang("ERP_Gia")}
                  <span style={{ color: 'red' }}>&nbsp;*</span>
                </>)}
                dataField={"UnitPrice"}
                dataType={"number"}
                alignment={"right"}
                width={150}
                cellRender={this.renderNumberFormat}
                editCellRender={(e) => {
                  // setTimeout(() => e.component.focus(e.cellElement), 300); //DbClick focus cell only
                  return (
                    <NumberFormat
                      style={{ width: '100%', height: 40 }}
                      className={classes.numberFormatInput}
                      thousandSeparator={true}
                      isNumericString={true}
                      value={e.value}
                      decimalScale={UnitPriceDecimals}
                      onValueChange={(c) => e.setValue(_.toNumber(c.value))}
                    />
                  )
                }}
              />
              <Column
                caption={Config.lang("ERP_Thanh_tien")}
                width={150}
                alignment={"right"}
                dataField={"OAmount"}
                allowEditing={false}
                cellRender={this.renderNumberFormat}
              />
              {!disabledQDField &&
                <Column
                  caption={Config.lang("ERP_Thanh_tien_QD")}
                  width={150}
                  alignment={"right"}
                  dataField={"CAmount"}
                  allowEditing={false}
                  cellRender={this.renderNumberFormat}
                />}
              <Column
                caption={Config.lang("ERP_Quy_cach_dong_goi")}
                dataField={"PackingMethodID"}
                width={180}
                lookup={{
                  dataSource: getCboPackingMethod,
                  valueExpr: "PackingMethodID",
                  displayExpr: "PackingMethodName",
                  itemRender: (e) => {
                    if (!e) return null;
                    return e.PackingMethodID + ' - ' + e.VoucherTypeName;
                  }
                }}
                editorOptions={{
                  placeholder: Config.lang("ERP_Chon") + "..."
                }}
              />
              <Column
                caption={Config.lang("ERP_Loai_bao_dong_goi")}
                dataField={"PackingTypeID"}
                lookup={{
                  dataSource: getCboPackingType,
                  valueExpr: "PackingTypeID",
                  displayExpr: "PackingTypeName",
                }}
                editorOptions={{
                  placeholder: Config.lang("ERP_Chon") + "..."
                }}
                width={250}
              />
              {!_.isEmpty(activeInventoryColumn) && this.renderInventoryColumn(activeInventoryColumn)}
              <Column
                caption={Config.lang("ERP_Tieu_chuan_chat_luong")}
                dataField={"Specification"}
                cellRender={this.cellRenderPopupAction}
                width={220}
              />
              <Column
                fixed={true}
                alignment={"right"}
                fixedPosition={"right"}
                allowEditing={false}
                visible={!Config.isMobile}
                cellRender={(e) => this.renderButtonAction(e, 'desktop')}
              />
            </GridContainer>
          </FormGroup>
        </div>
      </React.Fragment>
    );
  }
}

W06F0002Inventory.propTypes = {
  isUpdatedStatus: PropTypes.bool,
  mode: PropTypes.string,
  formData: PropTypes.object,
  contractTypesData: PropTypes.object,
  formDataList: PropTypes.array,
  activeInventoryColumn: PropTypes.array,
  gridRef: PropTypes.func,
  isUpdated: PropTypes.func,
  onChangeInventories: PropTypes.func,
  onChangeFormData: PropTypes.func,
};

export default compose(
  connect(
    (state) => ({
      getCboBaseCurrency: state.W06F0002.getCboBaseCurrency,
      getCboPackingType: state.W06F0002.getCboPackingType,
      getCboPackingMethod: state.W06F0002.getCboPackingMethod,
      getDecimalQuantity: state.W06F0002.getDecimalQuantity,
      dataCboUnit: state.W06F0002.dataCboUnit,
    }),
    (dispatch) => ({
      W06F0002Actions: bindActionCreators(W06F0002Actions, dispatch),
    })
  ),
  withStyles(styles, { withTheme: true })
)(W06F0002Inventory);
