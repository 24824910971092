/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/13/2020
 * @Example
 */
import { Accordion as ExpansionPanel, AccordionDetails as ExpansionPanelDetails, AccordionSummary as ExpansionPanelSummary, Tab, Tabs, Typography } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import _ from "lodash";
import moment from 'moment';
import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as generalActions from "../../../../redux/general/general_actions";
import * as W06F0003Actions from "../../../../redux/W0X/W06F0003/W06F0003_actions";
import ButtonExportExcel from "../../../common/button/button-export-excel";
import ButtonGeneral from "../../../common/button/button-general";
import AttachmentCustom from "../../../common/files/Attachments";
import TabContent, { TabPanel } from "../../../common/tabs/tab-content";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ViewHistory from '../../../common/view-history/ViewHistory';
import W06F0003GeneralInfo from "./W06F0003GeneralInfo";
import W06F0003Inventory from "./W06F0003Inventory";
import W06F0003ObjectCustomer from "./W06F0003ObjectCustomer";
import W06F0003OtherInfo from "./W06F0003OtherInfo";
import W06F0003TabMapping from "./W06F0003TabMapping";

const styles = theme => {
    return {
        collapseBoardContent: {
            marginBottom: 18,
            padding: '0px !important'
        },
        heading: {
            fontSize: 12,
            fontWeight: 'bold',
            textTransform: 'uppercase',
            transform: 'translateY(3px)',
            color: theme.palette.info.main
        },
        collapseBoard: {
            position: 'initial !important',
            boxShadow: 'initial !important',
            margin: '0 0 5px 0 !important'
        },
        ExpansionPanelSummary: {
            minHeight: 'initial !important',
            padding: '0 !important',
            width: '100%',
            color: theme.palette.primary.main,
            '& .MuiAccordionSummary-content': {
                margin: '0 !important'
            },
            '&.Mui-expanded': {
                minHeight: 'inherit'
            }
        }
    };
};

class W06F0003 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            supplierExpand: true,
            generalInforExpand: true,
            otherInforExpand: false,
            formViewLoading: false,
            inventoryExpand: false,
            historyLoading: false,
            historyExpand: false,
            loadApiStatus: true,
            tabKey: 0,
            totalHistory: 0,
            mappingPermission: 0,
            ReportID: "",
            dataMaster: {},
            dataHistory: [],
            dataAttachments: [],
            dataInventories: [],
            dataSaleContracts: [],
            activeInventoryColumn: [],
            W06F0004Permission: 0,
            W06F0005Permission: 0
        };
        this.filterHistory = {
            skip: 0,
            limit: 10,
        };
        this.dataInfo = {
            mode: "",
            ContractID: "",
            isPermission: "",
            cboContractTypes: [],
            Mode: 0
        };
        this.TableName = "D49T2000-PO";
        this.language = Config.language || "84";
    }

    getInfo = () => {
        const { location } = this.props;
        if (location && !_.isEmpty(location.state)) {
            if (location.state.isPermission <= 0) return;
            this.dataInfo.mode = location.state.mode || "add";
            this.dataInfo.ContractID = location.state.ContractID || "";
            this.dataInfo.cboContractTypes = location.state.cboContractTypes || [];
            this.dataInfo.isPermission = location.state.isPermission || "";
            this.dataInfo.Mode = location.state.Mode || 0;
        } else {
            this.setState({ loadApiStatus: false }, () => {
                browserHistory.push(Config.getRootPath() + "W06F0001");
                return null;
            })
        }
    };

    componentDidMount = async () => {
        await this.getInfo();
        if (this.state.loadApiStatus) {
            const sort = "";
            const keyword = "";
            const TableName = this.TableName;
            const { ContractID, Mode } = this.dataInfo;
            const Language = this.Language;
            const { getBaseCurrency, paramsMasterSup } = this.props;
            const condition = JSON.stringify({ codeID: ContractID });
            //Load phase permision and mapping permision 
            await this.loadPermission(["W05F0006", "W06F0004", "W06F0005"]);
            //Load Data
            await this.loadApi("loadFormView", { ContractID, Language, Mode }, true);
            //Load Data History
            this.loadApi("loadHistory", { keyword, condition, limit: this.filterHistory.limit, skip: this.filterHistory.skip, sort }, false);
            //Other Information 
            if (paramsMasterSup && paramsMasterSup.TableName !== TableName) this.loadApi("getMasterSupplement", { TableName, Language }, true);
            //Base Currency
            if (_.isEmpty(getBaseCurrency)) this.loadApi("loadBaseCurrency", {}, true);
        }
    };

    handleLoadDataApi = (apiName, data) => {
        const Language = Config.language || "84";
        switch (apiName) {
            case "loadFormView":
                const { master = {}, attachments = [], inventories = [] } = data;
                const _master = { ...master };
                const listDates = [
                    "ContractDate", "StartDate", "EndDate", "Date01", "Date02", "Date03", "Date04",
                    "Date05", "Date06", "Date07", "Date08", "Date09", "Date10"];
                Object.keys(_master).forEach(dataMaster => {
                    if (listDates.indexOf(dataMaster) > -1 && (!_.isNumber(_master[dataMaster]) && moment(_master[dataMaster], moment.ISO_8601, true).isValid())) {
                        _master[dataMaster] = moment(_master[dataMaster]).format("DD/MM/YYYY")
                    }
                });
                const { cboContractTypes } = this.dataInfo;
                const contractTypesData = cboContractTypes && cboContractTypes.find(
                    (element) => element.TransTypeID === master.TransTypeID
                );
                if (_master?.ExceedContract) {
                    _master.ExceedContract = _master.ExceedContract * 100;
                }
                this.setState({
                    contractTypesData,
                    dataMaster: {
                        ..._master,
                        Employee: {
                            EmployeeID: master && master.EmployeeID ? master.EmployeeID : "",
                            EmployeeName: master && master.EmployeeName ? master.EmployeeName : master.EmployeeID,
                            UserPictureURL: master && master.UserPictureURL ? master.UserPictureURL : null,
                        }
                    },
                    dataAttachments: attachments,
                    dataInventories: inventories
                }, () => //Load Detail Supplement
                    this.loadApi("getDetailSupplement", { Language }, true));
                if (master.CurrencyID) {
                    const { CurrencyID } = data.master;
                    this.loadApi("getDecimalQuanlity", { CurrencyID }, true);
                }
                break;
            case "loadHistory":
                this.setState({
                    dataHistory: data && data.rows ? data.rows : [],
                    totalHistory: data && data.total ? data.total : 0
                });
                break;
            case "getDetailSupplement":
                let activeInventoryColumn = [];
                const { contractTypesData: contractTypesDataState = {} } = this.state;
                if (data.length > 0) {
                    const displayName = "Display";
                    activeInventoryColumn = data.filter((keyName) => {
                        if (keyName.FieldName.includes("Str") && keyName.FieldName.includes("U")) {
                            keyName.FieldName = keyName.FieldName.slice(0, -1) // Bỏ chứ U phía sau DisplayStr..U  vì Data trả về là DisplayStr..
                        } else if (keyName.FieldName.includes("Dat")) {
                            keyName.DefaultValueU = moment(keyName.DefaultValueU, "DD/MM/YYYY").format("YYYY-MM-DD");
                        }
                        return contractTypesDataState[displayName.concat(keyName.FieldName)] === 1
                    });
                }
                this.setState({ activeInventoryColumn })
                break;
            default:
                break;
        }
    }

    showLoadingFollowApi = (apiName) => {
        switch (apiName) {
            case "loadHistory":
                this.setState({ historyLoading: true });
                break;
            default:
                this.setState({ formViewLoading: true });
                break;
        }
    }

    closeLoadingFollowApi = (apiName) => {
        switch (apiName) {
            case "loadHistory":
                this.setState({ historyLoading: false });
                break;
            default:
                break;
        }
    }

    loadApi = (cboName, params = {}, ownAction = false) => {
        this.showLoadingFollowApi(cboName);
        this.props[ownAction ? "w06f0003Actions" : "generalActions"][cboName](params, (error, data = null) => {
            this.setState({ formViewLoading: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            else if (cboName && data) {
                this.handleLoadDataApi(cboName, data);
            }
            this.closeLoadingFollowApi(cboName);
        });
    };

    checkPageName = async (permisionItem) => {
        const { ScreenID, Permission } = permisionItem;
        const { tabKey } = this.state;
        let _currentTabKey = 0;
        switch (ScreenID) {
            case "W05F0006":
                if (!(Permission > 0)) {
                    _currentTabKey = 1;
                }
                await this.setState({ mappingPermission: Permission });
                break;
            case "W06F0004":
                await this.setState({ W06F0004Permission: Permission });
                break;
            case "W06F0005":
                await this.setState({ W06F0005Permission: Permission });
                break;
            default:
                break;
        }
        if (tabKey !== _currentTabKey) this.setState({ tabKey: _currentTabKey });
    }

    loadPermission = async (formID) => {
        await this.props.generalActions.getPermission(formID, (isPer) => {
            if (!_.isEmpty(isPer)) {
                isPer.map(item => this.checkPageName(item));
            }
        }, true);
    };

    onBack = () => {
        browserHistory.push(Config.getRootPath() + "W06F0001");
    };

    redirectEditScreen = () => {
        const { contractTypesChosen, cboContractTypes, ContractID, isPermission } = this.dataInfo;
        const screen = "W06F0002";
        const mode = "edit"
        browserHistory.push({
            pathname: Config.getRootPath() + screen,
            state: {
                mode,
                ContractID,
                isPermission,
                cboContractTypes,
                contractTypesChosen
            }
        });
    };

    onChangeHisoryPage = (page) => {
        const { ContractID } = this.dataInfo;
        this.filterHistory.skip = page * this.filterHistory.limit;
        this.loadApi("loadHistory", {
            keyword: "",
            condition: JSON.stringify({ codeID: ContractID }),
            limit: this.filterHistory.limit,
            skip: this.filterHistory.skip,
            sort: ""
        }, false);
    };

    onChangeHistoryPerPage = (perPage) => {
        const { ContractID } = this.dataInfo;
        this.filterHistory.skip = 0;
        this.filterHistory.limit = perPage;
        this.loadApi("loadHistory", {
            keyword: "",
            condition: JSON.stringify({ codeID: ContractID }),
            limit: this.filterHistory.limit,
            skip: this.filterHistory.skip,
            sort: ""
        }, false);
    };

    redirectScreen = (mode, e = "") => {
        const { ContractID } = this.dataInfo;
        let param = {
            mappingID: ""
        };
        let screen = "";
        switch (mode) {
            case "addPhase":
                screen = "W05F0005";
                param.ContractID = ContractID;
                param.mode = "add";
                break;
            case "addMapping":
                screen = "W05F0007";
                param.mode = "add";
                break;
            case "W06F0004":
                screen = "W06F0004";
                break;
            case "W06F0005":
                screen = "W06F0005";
                break;
            default:
                break;
        }
        browserHistory.push({
            pathname: Config.getRootPath() + screen,
            state: param
        });
    };

    render() {
        const { isPermission, Mode } = this.dataInfo;
        if (isPermission <= 0) return null;
        const { generalInforExpand, supplierExpand, otherInforExpand, inventoryExpand, historyExpand, formViewLoading, dataHistory, totalHistory,
            ReportID, historyLoading, dataAttachments, dataMaster, dataInventories, W06F0004Permission, W06F0005Permission,
            tabKey, mappingPermission, activeInventoryColumn, contractTypesData } = this.state;
        const { classes, getDecimalQuantity } = this.props;
        const { TransTypeID, TransTypeName, ContractID, ContractNo, SupplierShortName } = dataMaster;
        const checkInvenLoadingExpand = inventoryExpand ? formViewLoading : false;
        const checkHistoryLoadingExpand = historyExpand ? historyLoading : false;
        const iconArrowDown = require("../../../../assets/images/general/arrow_down.svg");
        const iconArrowRight = require("../../../../assets/images/general/arrow_right.svg");
        const generalIconExpandSrc = generalInforExpand ? iconArrowDown : iconArrowRight;
        const supplierIconExpandSrc = supplierExpand ? iconArrowDown : iconArrowRight;
        const otherInforIconExpandSrc = otherInforExpand ? iconArrowDown : iconArrowRight;
        const inventoryIconExpandSrc = inventoryExpand ? iconArrowDown : iconArrowRight;
        const historyIconExpandSrc = historyExpand ? iconArrowDown : iconArrowRight;
        const StoreParams = [
            { id: "ContractID", type: "VarChar", value: ContractID },
            { id: "ReportID", type: "VarChar", value: ReportID },
            { id: "Language", type: "Varchar", value: Config.language || "84" },
            { id: "UserID", type: "VarChar", value: Config.profile.UserID || "" },
            { id: "FormID", type: "VarChar", value: 'W06F0003' },
            { id: "DataType", type: "VarChar", value: 'ExportReport' },
            { id: "Mode", type: "Tinyint", value: Mode },
        ];
        const btnAddMappingStatus = !(mappingPermission > 1) || (_.toNumber(dataMaster.EStatus) !== 3 && _.toNumber(dataMaster.EStatus) !== 4);
        const customReportFileName = (ContractNo + ' ' + SupplierShortName).replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, ' ');
        return (
            <>
                <ActionToolbar
                    allwaysTop
                    style={{ display: "initial !important" }}
                    title={Config.lang("ERP_Chi_tiet_hop_dong_mua")}
                    onBack={this.onBack}>
                    <Row style={{ width: '100%', display: window.innerWidth < 576 ? '' : 'inline-flex' }}>
                        <Col xs={12} sm={6} md={6} lg={6}>
                            {Mode !== 1 &&
                                <ButtonGeneral
                                    name={Config.lang("ERP_Sua")}
                                    size={"large"}
                                    typeButton={"edit"}
                                    className={"mgr10"}
                                    disabled={formViewLoading || isPermission < 3}
                                    style={{ textTransform: 'uppercase' }}
                                    onClick={this.redirectEditScreen}
                                />
                            }
                            <ButtonExportExcel
                                name={Config.lang("ERP_Xuat_du_lieu")}
                                ModuleID={"06"}
                                size={"large"}
                                color={"primary"}
                                variant={"outlined"}
                                ReportTypeID={"W06F0003"}
                                style={{ textTransform: 'uppercase' }}
                                disabled={formViewLoading}
                                StoreParams={StoreParams}
                                customReportFileName={customReportFileName}
                            />
                        </Col>
                        <Col xs={12} sm={6} md={6} lg={6} {...(window.innerWidth >= 768 ? { style: { textAlign: 'right', paddingRight: 0 } } : {})} >
                            {TransTypeName &&
                                <ButtonGeneral
                                    className={"bg_none"}
                                    name={`${TransTypeID} - ${TransTypeName}`}
                                    typeButton={"custom_edit"}
                                    reverseIcon={true}
                                    variant={"custom"}
                                    size={"large"}
                                    disabled={true} //Mode = view = disable
                                    style={{ textTransform: 'uppercase', transform: window.innerWidth < 576 ? 'translateY(6px)' : '' }}
                                />}
                        </Col>
                    </Row>
                </ActionToolbar>
                {(!_.isEmpty(dataMaster) && !_.isEmpty(getDecimalQuantity)) &&
                    <React.Fragment>
                        <div style={{ paddingTop: 16, width: '100%' }}>
                            <ExpansionPanel
                                expanded={generalInforExpand}
                                className={classes.collapseBoard}
                                onChange={() => this.setState({ generalInforExpand: !generalInforExpand })}
                            >
                                <ExpansionPanelSummary className={classes.ExpansionPanelSummary} >
                                    <Image src={generalIconExpandSrc} style={{ marginRight: 5 }} />
                                    <Typography className={classes.heading}>{Config.lang("ERP_Thong_tin_chung")}</Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                    <W06F0003GeneralInfo
                                        formDataMaster={dataMaster}
                                        getDecimalQuantity={getDecimalQuantity}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={supplierExpand}
                                className={classes.collapseBoard}
                                onChange={() => this.setState({ supplierExpand: !supplierExpand })}
                            >
                                <ExpansionPanelSummary
                                    className={classes.ExpansionPanelSummary}
                                >
                                    <Image src={supplierIconExpandSrc} style={{ marginRight: 5 }} />
                                    <Typography className={classes.heading}>
                                        {Config.lang("ERP_Nha_cung_cap")}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                    <W06F0003ObjectCustomer
                                        contractTypesData={contractTypesData}
                                        formDataMaster={dataMaster}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={otherInforExpand}
                                className={classes.collapseBoard}
                                onChange={() => this.setState({ otherInforExpand: !otherInforExpand })}
                            >
                                <ExpansionPanelSummary
                                    className={classes.ExpansionPanelSummary}
                                >
                                    <Image src={otherInforIconExpandSrc} style={{ marginRight: 5 }} />
                                    <Typography className={classes.heading}>
                                        {Config.lang("ERP_Thong_tin_khac")}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                    <W06F0003OtherInfo
                                        formDataMaster={dataMaster}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={inventoryExpand}
                                className={classes.collapseBoard}
                                onChange={() => this.setState({ inventoryExpand: !inventoryExpand })}
                            >
                                <ExpansionPanelSummary
                                    className={classes.ExpansionPanelSummary}
                                >
                                    <Image src={inventoryIconExpandSrc} style={{ marginRight: 5 }} />
                                    <Typography className={classes.heading}>
                                        {Config.lang("ERP_Hang_hoa")}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={classes.collapseBoardContent}>
                                    <W06F0003Inventory
                                        formDataMaster={dataMaster}
                                        loading={checkInvenLoadingExpand}
                                        contractTypesData={contractTypesData}
                                        formDataInventories={dataInventories}
                                        getDecimalQuantity={getDecimalQuantity}
                                        activeInventoryColumn={activeInventoryColumn}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            <ExpansionPanel
                                expanded={historyExpand}
                                className={classes.collapseBoard}
                                onChange={() => this.setState({ historyExpand: !historyExpand })}
                            >
                                <ExpansionPanelSummary
                                    className={classes.ExpansionPanelSummary}
                                >
                                    <Image src={historyIconExpandSrc} style={{ marginRight: 5 }} />
                                    <Typography className={classes.heading}>
                                        {Config.lang("ERP_Lich_su")}
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{ padding: '0px 18px' }}>
                                    <ViewHistory
                                        dataHistory={dataHistory}
                                        totalItems={totalHistory}
                                        skip={this.filterHistory.skip}
                                        limit={this.filterHistory.limit}
                                        loading={checkHistoryLoadingExpand}
                                        onChangePage={this.onChangeHisoryPage}
                                        onChangePerPage={this.onChangeHistoryPerPage}
                                    />
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                            {!_.isEmpty(dataAttachments) && <div style={{ marginTop: 30 }}>
                                <AttachmentCustom data={dataAttachments} />
                            </div>}

                            <Tabs
                                value={tabKey}
                                onChange={this.handleTabChange}
                                indicatorColor={"primary"}
                                textColor={"primary"}
                                component={"div"}
                                variant={"scrollable"}
                                scrollButtons={"off"}
                                style={{ fontSize: 16, marginTop: 13 }}
                                aria-label="full width tabs"
                            >
                                {mappingPermission > 0 && <Tab value={0} label={Config.lang("ERP_Mapping")} />}
                            </Tabs>
                            <TabContent
                                style={{ backgroundColor: '#fff' }}
                                activeKey={tabKey}
                                disableSwipe={true}
                                lazyLoading={false}
                                onChangeIndex={this.handleChangeIndex}>
                                <TabPanel index={0}>
                                    <ButtonGeneral
                                        name={Config.lang("ERP_Them_mapping")}
                                        size={"large"}
                                        typeButton={"add"}
                                        disabled={btnAddMappingStatus}
                                        style={{ textTransform: "uppercase", margin: '24px 0 28px 0' }}
                                        onClick={() => this.redirectScreen("addMapping")}
                                    />
                                    {W06F0004Permission > 1 &&
                                        <ButtonGeneral
                                            name={Config.lang("ERP_Thong_bao_giao_hang")}
                                            size={"large"}
                                            typeButton={"W06F0004"}
                                            style={{ textTransform: "uppercase", margin: '24px 0 28px 0', marginLeft: Config.isMobile ? 0 : 10 }}
                                            onClick={() => this.redirectScreen("W06F0004")}
                                        />
                                    }
                                    {W06F0005Permission > 0 &&
                                        <ButtonGeneral
                                            name={Config.lang("ERP_Xac_nhan_giao_hang")}
                                            size={"large"}
                                            typeButton={"confirm_delivery"}
                                            style={{ textTransform: "uppercase", margin: '24px 0 28px 0', marginLeft: Config.isMobile ? 0 : 10 }}
                                            onClick={() => this.redirectScreen("W06F0005")}
                                        />
                                    }
                                    <W06F0003TabMapping
                                        permission={mappingPermission}
                                        dataMaster={dataMaster}
                                    />
                                </TabPanel>
                            </TabContent>
                        </div>
                    </React.Fragment>
                }
            </>
        );
    }
}
export default compose(
    connect((state) => ({
        getDecimalQuantity: state.W06F0003.getDecimalQuantity,
        paramsMasterSup: state.W06F0003.paramsMasterSup,
        getBaseCurrency: state.W06F0003.getBaseCurrency,
    }),
        dispatch => ({
            generalActions: bindActionCreators(generalActions, dispatch),
            w06f0003Actions: bindActionCreators(W06F0003Actions, dispatch)
        })),
    withStyles(styles, { withTheme: true })
)(W06F0003);
