import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import { Column } from "devextreme-react/data-grid";
import _ from 'lodash';
import PropTypes from "prop-types";
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import Config from '../../../../config/index';
import { TextField } from "../../../common/form-material";
import Icons from "../../../common/icons/";
import GridActionBar from '../../../grid-container/grid-actionbar';
import GridContainer from "../../../grid-container/grid-container";
import HeadClick from "../../../grid-container/head-click";

const styles = {
    wordWrap: {
        '& div': {
            whiteSpace: 'normal !important',
            maxWidth: 130,
            textAlign: 'right'
        }
    },
    scaleHeader: {
        '& .dx-datagrid-headers .dx-header-row': {
            '& td': {
                padding: '5px !important'
            }
        }
    },
    columnActions: {
        height: '37px !important',
    },
    removeBorder: {
        height: '37px !important',
        "& div": {
            "&:before": {
                borderBottomStyle: 'none !important',
                border: 'none !important'
            },
            "&:after": {
                border: 'none !important'
            },
            "& input": {
                height: '37x !important',
                textAlign: 'right !important',
            },
        }
    },
    numberInput: {
        width: '100%',
        paddingRight: 8,
        paddingTop: 9,
        marginBottom: -6,
        marginTop: -6,
        backgroundColor: 'transparent'
    },
    headerCenter: {
        '& .dx-datagrid-text-content': {
            textAlign: 'center'
        }
    },
    removeBg: {
        '& .cell-selected': {
            backgroundColor: '#EFF5FF !important'
        }
    },
    hoverDetail: {
        '& :hover': {
            '& path': {
                fill: '#111D5E !important'
            }
        }
    }
};

class W06F0007MappingInfo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flag: false,
            isEditing: false,
            selectedRange: {},
        }
        this.rowData = null;
        this.selectedRange = {};
    }

    componentDidMount = () => {
        if (this.props.refGridMappingInfo) this.props.refGridMappingInfo(this);
    }

    onDeleteMapping = async (e) => {
        this.rowData = e.data;
        if (e.data.packing.length > 0 || e.data.delivery.length > 0) {
            Config.popup.show("INFO", Config.lang("ERP_Du_lieu_da_duoc_su_dung_ban_khong_duoc_xoa"));
        } else {
            Config.popup.show("YES_NO", Config.lang("ERP_Ban_co_chac_muon_xoa?"),
                () => {
                    this.gridTabMappingInfo.instance.deleteRow(e.rowIndex);
                    this.props.refW06F0007.deleteDataMappingInfo(this.rowData.QuotationItemID, this.rowData.PurchaseItemID, () => {
                        this.rowData = null;
                        this.props.refW06F0007.setIsUpdate();
                    });
                });
        }
    }

    handleClose = (stateName) => {
        this.setState({ [stateName]: null });
    };

    renderHeaderColumns = (e) => {
        if (!e || this.state.flag) return false;
        return <HeadClick selectedRange={this.state.selectedRange}
            style={{ whiteSpace: 'normal' }}
            dataHeaderCell={e}
            allowClick={true}
            callbackAfterCopy={rs => {
                this.setState({ isUpdated: rs.IsUpdated });
            }}
        />
    };

    renderButtonAction = (e) => {
        return (
            <GridActionBar >
                <IconButton
                    disabled={this.props.btnActionStatus}
                    style={{ padding: 4 }}
                    onClick={() => this.onDeleteMapping(e)}
                    aria-label={"delete"}
                    color={"default"}
                >
                    <Icons name={"delete"} />
                </IconButton>
            </GridActionBar>
        )
    };

    headerCellRender = (e) => (
        <label>{e.column.caption}</label>
    )

    onSaveGrid = async () => {
        await this.gridTabMappingInfo.instance.saveEditData();
    }

    render() {
        const { isView, dataGridMappingInfo, decimals, classes, refW06F0007, btnActionStatus } = this.props;
        return (
            <div>
                <GridContainer
                    reference={ref => this.gridTabMappingInfo = ref}
                    dataSource={dataGridMappingInfo}
                    // disabled={btnActionStatus}
                    typeShort={true}
                    showRowLines={false}
                    columnAutoWidth={true}
                    showColumnLines={false}
                    listPerPage={[5, 10, 15, 30, 45, 60]}
                    itemPerPage={5}
                    typePaging={"normal"}
                    pagerFullScreen={false}
                    keyExpr={"MappingItemID"}
                    height={300}
                    elementAttr={{
                        class: `${classes.scaleHeader} noBGSelect`
                    }}
                    onKeyDown={(e) => {
                        Config.onEnterAndTabKeyNavigation(e, this.selectedRange, this.gridTabMappingInfo,
                            (rowIndex) => this.selectedRange.startRowIndex = rowIndex
                            , (columnIndex) => this.selectedRange.startColumnIndex = columnIndex);
                    }}
                    allowCellSelection={true}
                    hoverStateEnabled={true}
                    sorting={{
                        mode: "none"
                    }}
                    editing={{
                        mode: "cell",
                        refreshMode: "reshape",
                        allowUpdating: !isView && !btnActionStatus,
                        texts: {
                            confirmDeleteMessage: ""
                        },
                        // startEditAction: "dblClick"
                    }}
                    onRowUpdated={(e) => {
                        if (!btnActionStatus) {
                            e.data.IsUpdated = 1;
                            this.setState({ isUpdated: true, isEditing: false });
                            this.props.refW06F0007.setIsUpdate();
                            const { startRowIndex, startColumnIndex } = this.selectedRange;
                            const dataField = ["QuantityPack", "Quantity", "StartDate", "EndDate"];
                            const currentFieldName = this.gridTabMappingInfo.instance.getVisibleColumns()[startColumnIndex]?.dataField;
                            if (dataField.includes(currentFieldName)) {
                                setTimeout(() => {
                                    this.gridTabMappingInfo.instance.editCell(startRowIndex, startColumnIndex)
                                }, 100);
                            }
                        }
                        if (this.state.flag) this.setState({ flag: false }); // Fix lỗi headClick lần đầu tiên 
                    }}
                    onRowUpdating={async (e) => {
                        if (!_.isUndefined(e.newData.Quantity) && !btnActionStatus) {
                            const QuantityActual = !_.isUndefined(e.newData.QuantityActual) ? e.newData.QuantityActual : !_.isUndefined(e.oldData.QuantityActual) ? e.oldData.QuantityActual : 0;
                            const Quantity = !_.isUndefined(e.newData.Quantity) ? e.newData.Quantity : !_.isUndefined(e.oldData.Quantity) ? e.oldData.Quantity : 0;
                            if ((Number(Quantity) >= Number(QuantityActual))) {
                                await this.props.refW06F0007.setSumQuantity(e.oldData, e.newData, (bool) => {
                                    if (bool) {
                                        // e.cancel = true
                                        e.newData.Quantity = e.oldData.Quantity;
                                        Config.popup.show("INFO", Config.lang("ERP_So_luong_khong_hop_le"));
                                        if (!this.state.flag) this.setState({ flag: true });  // Fix lỗi headClick lần đầu tiên 
                                    } else {
                                        e.newData.QuantityRemain = Number(Quantity) - Number(QuantityActual);
                                        if (this.state.flag) this.setState({ flag: false });  // Fix lỗi headClick lần đầu tiên 

                                    }
                                });
                            } else {
                                e.newData.Quantity = e.oldData.Quantity;
                                Config.popup.show("INFO", Config.lang("ERP_So_luong_khong_hop_le"));
                                if (!this.state.flag) this.setState({ flag: true });  // Fix lỗi headClick lần đầu tiên 
                            }

                        }
                    }}
                    onCellSelectionChanged={(e) => {
                        if (!btnActionStatus) {
                            const invalid = ["StartDate", "EndDate"];
                            const invalidFieldName = e.component.getVisibleColumns()[e.selectedRange.startColumnIndex]?.dataField;
                            if (e.component.hasEditData() && !invalid.includes(invalidFieldName)) {
                                this.gridTabMappingInfo.instance.saveEditData();
                            }
                            if (e.selectedRange && e.selectedRange.rowType !== "header" && e.selectedRange.rowType !== "" && !this.state.isEditing && !isView) {
                                if (JSON.stringify(this.state.selectedRange) !== JSON.stringify(e.selectedRange)) {
                                    this.selectedRange = e.selectedRange;
                                    this.setState({ selectedRange: e.selectedRange }, () => {
                                        const dataField = ["QuantityPack", "Quantity", "StartDate", "EndDate"];
                                        const currentFieldName = e.component.getVisibleColumns()[e.selectedRange.startColumnIndex]?.dataField;
                                        if (dataField.includes(currentFieldName)) {
                                            setTimeout(() => {
                                                this.gridTabMappingInfo.instance.editCell(e.selectedRange.startRowIndex, e.selectedRange.startColumnIndex)
                                            }, 100);
                                        }
                                    });
                                }
                            }
                        }
                    }
                    }
                >
                    <Column
                        caption={Config.lang("ERP_So_mapping")}
                        dataField={"MappingNo"}
                        allowEditing={false}
                        minWidth={150}
                    />
                    <Column
                        caption={Config.lang("ERP_So_hop_dong_mua")}
                        dataField={"ContractNo"}
                        allowEditing={false}
                        minWidth={150}
                    />
                    <Column
                        caption={Config.lang("ERP_Nha_cung_cap")}
                        dataField={"Supplier"}
                        allowEditing={false}
                        minWidth={150}
                    />
                    <Column
                        caption={Config.lang("ERP_So_lot")}
                        dataField={"LotNo"}
                        allowEditing={false}
                        minWidth={150}
                    />
                    <Column caption={Config.lang("ERP_Hop_dong_mua")} alignment={"center"}>
                        <Column
                            caption={Config.lang("ERP_Ma_hang")}
                            dataField={"InventoryIDPO"}
                            alignment={"center"}
                            allowEditing={false}
                            minWidth={180}
                            cssClass={classes.headerCenter}
                            headerCellRender={this.headerCellRender}
                        />
                        <Column
                            caption={Config.lang("ERP_Ten_hang")}
                            dataField={"InventoryNamePO"}
                            alignment={"center"}
                            allowEditing={false}
                            minWidth={180}
                            cssClass={classes.headerCenter}
                            headerCellRender={this.headerCellRender}
                        />
                    </Column>
                    <Column caption={Config.lang("ERP_Hop_dong_ban")} alignment={"center"}>
                        <Column
                            caption={Config.lang("ERP_Ma_hang")}
                            dataField={"InventoryIDSO"}
                            alignment={"center"}
                            allowEditing={false}
                            minWidth={180}
                            cssClass={classes.headerCenter}
                            headerCellRender={this.headerCellRender}
                        />
                        <Column
                            caption={Config.lang("ERP_Ten_hang")}
                            dataField={"InventoryNameSO"}
                            alignment={"center"}
                            allowEditing={false}
                            minWidth={180}
                            cssClass={classes.headerCenter}
                            headerCellRender={this.headerCellRender}
                        />
                    </Column>
                    <Column
                        caption={Config.lang("ERP_So_luong_bao_yeu_cau")}
                        dataField={"QuantityPack"}
                        dataType={"number"}
                        minWidth={200}
                        headerCellRender={this.renderHeaderColumns}
                        cellRender={refW06F0007.renderNumberFormat}
                        cssClass={classes.removeBorder}
                        editCellRender={e => {
                            setTimeout(() => e.component.focus(e.cellElement), 100);
                            return (
                                <NumberFormat
                                    className={classes.numberInput}
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    autoFocus={true}
                                    decimalScale={decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5}
                                    value={String(e.value) || ""}
                                    onValueChange={rs => e.setValue(_.toNumber(rs.value))}
                                />
                            )
                        }}
                        visible={false}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong_yeu_cau_mapping")}
                        dataField={"Quantity"}
                        width={150}
                        dataType={"number"}
                        headerCellRender={this.renderHeaderColumns}
                        cellRender={refW06F0007.renderNumberFormat}
                        cssClass={classes.removeBorder}
                        editCellRender={e => {
                            setTimeout(() => e.component.focus(e.cellElement), 100);
                            return (
                                <NumberFormat
                                    className={classes.numberInput}
                                    customInput={TextField}
                                    thousandSeparator={true}
                                    autoFocus={true}
                                    decimalScale={decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5}
                                    value={String(e.value) || ""}
                                    onValueChange={rs => e.setValue(_.toNumber(rs.value))}
                                />
                            )
                        }}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong_thuc_hien_mapping")}
                        cssClass={classes.wordWrap}
                        dataField={"QuantityActual"}
                        width={150}
                        allowEditing={false}
                        dataType={"number"}
                        format={{
                            type: "decimal",
                            min: 0
                        }}
                        editorOptions={{
                            min: 0,
                            showClearButton: true,
                            format: {
                                type: "fixedPoint",
                                precision: decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5,
                            },
                        }}
                        cellRender={refW06F0007.renderNumberFormat}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong_con_lai")}
                        dataField={"QuantityRemain"}
                        dataType={"number"}
                        minWidth={150}
                        allowEditing={false}
                        format={{
                            type: "decimal",
                            min: 0
                        }}
                        editorOptions={{
                            min: 0,
                            showClearButton: true,
                            format: {
                                type: "fixedPoint",
                                precision: decimals && decimals.QuantityDecimals ? decimals.QuantityDecimals : 5,
                            },
                        }}
                        cellRender={refW06F0007.renderNumberFormat}
                    />
                    <Column caption={Config.lang("ERP_Thoi_gian_dong_hang")}
                        dataField={"dateFormTo"}
                        alignment={"center"} >
                        <Column
                            caption={Config.lang("ERP_Bat_dau")}
                            dataField={"StartDate"}
                            format={"dd/MM/yyyy"}
                            alignment={"center"}
                            dataType={"date"}
                            width={160}
                            editorOptions={{
                                placeholder: "DD/MM/YYYY",
                                allowUpdating: !isView && !btnActionStatus,
                            }}
                            headerCellRender={this.renderHeaderColumns}
                        />
                        <Column
                            caption={Config.lang("ERP_Ket_thuc")}
                            dataField={"EndDate"}
                            format={"dd/MM/yyyy"}
                            alignment={"center"}
                            dataType={"date"}
                            width={160}
                            editorOptions={{
                                placeholder: "DD/MM/YYYY",
                                allowUpdating: !isView && !btnActionStatus,
                            }}
                            headerCellRender={this.renderHeaderColumns}
                        />
                    </Column>
                    {!btnActionStatus &&
                        <Column
                            fixed={true}
                            fixedPosition={"right"}
                            alignment={"center"}
                            cssClass={classes.columnActions}
                            cellRender={this.renderButtonAction}
                            visible={!isView || Config.isMobile}
                            allowEditing={false}
                        />
                    }
                </GridContainer>
            </div>
        );
    }
}

W06F0007MappingInfo.propTypes = {
    isView: PropTypes.bool,
    btnActionStatus: PropTypes.bool,
    decimals: PropTypes.object,
    rowDataInfoMapping: PropTypes.object,
    dataGridMappingInfo: PropTypes.array,
    refW06F0007: PropTypes.any,
    refGridMappingInfo: PropTypes.any,
};
export default withStyles(styles)(W06F0007MappingInfo);