import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import Config from '../../../../config/index';
import * as GeneralActions from '../../../../redux/general/general_actions';
import * as W06F0006Actions from '../../../../redux/W0X/W06F0006/W06F0006_actions';
import Icons from "../../../common/icons/";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import UserImage from "../../../common/user/user-image";
import GridActionBar from '../../../grid-container/grid-actionbar';
import GridContainer from "../../../grid-container/grid-container";

const styles = theme => {
    return {
        divText: {
            fontWeight: 500,
            whiteSpace: 'no-wrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
    }
};

class W06F0006 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            gridLoading: false,
            totalRequestLot: 0,
            requestLotData: [],
        }
        this.filterRequestLot = {
            limit: 10,
            skip: 0
        };
        this.infoGrid = null;
    }

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.isPermission <= 0) return;
        this.loadRequestMapping();
    }

    loadPermission = async () => {
        await this.props.GeneralActions.getPermission(["W06F0006"], (isPer) => {
            if (!_.isEmpty(isPer)) {
                const indexW06F0006 = isPer.findIndex(item => item.ScreenID === "W06F0006");
                this.setState({
                    isPermission: isPer[indexW06F0006].Permission,
                });
            }
        });
    };

    loadRequestMapping = () => {
        const { skip, limit } = this.filterRequestLot;
        const params = {
            skip,
            limit
        };
        this.setState({ gridLoading: true })
        this.props.W06F0006Actions.getRequestLot(params, (error, data) => {
            if (error) {
                this.setState({ gridLoading: false })
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            else if (data) {
                this.setState({
                    gridLoading: false,
                    requestLotData: data.rows || [],
                    totalRequestLot: data.total || 0
                })
            }
        });
    };

    onChangeInfoPage = (page) => {
        this.filterRequestLot.skip = page * this.filterRequestLot.limit;
        this.loadRequestMapping();
    };

    onChangePerPage = (perPage) => {
        this.filterRequestLot.skip = 0;
        this.filterRequestLot.limit = perPage;
        this.loadRequestMapping();
    };

    renderEmpProfile = e => {
        const { classes } = this.props;
        const { data } = e.row;
        const user = Config.getUser({ UserID: data.UserID });
        return (
            <div className={"display_row align-center pdt10 pdb10"}>
                <UserImage style={{ display: 'content' }} data={user} />
                <div className={classes.divText}>{user?.ObjectNameU || ""}</div>
            </div>
        );
    };

    renderDetail = (e) => {
        const { data } = e.row;
        return (
            <div style={{
                whiteSpace: "pre-wrap",
                fontWeight: 500
            }}>{data?.Detail || ""}
            </div>
        );
    };

    renderButtonAction = (e) => {
        let params = {
            mode: "edit",
            RequestID: e.data.RequestID,
            permission: this.state.isPermission
        };
        return (
            <GridActionBar>
                <IconButton
                    disabled={false}
                    color={"default"}
                    aria-label={"Edit"}
                    onClick={() => browserHistory.push({
                        pathname: Config.getRootPath() + "W06F0007",
                        state: params
                    })} >
                    <Icons name={"edit"} />
                </IconButton>
            </GridActionBar>
        )
    };
    render() {
        const { isPermission, gridLoading, requestLotData, totalRequestLot } = this.state;
        if (!isPermission) return null;
        return (
            <>
                <ActionToolbar allwaysTop title={Config.lang("ERP_Yeu_cau_tach_lot")}>
                </ActionToolbar>
                <GridContainer
                    style={{ border: "none" }}
                    height={Config.getHeightGrid()}
                    reference={ref => this.infoGrid = ref}
                    dataSource={requestLotData}
                    totalItems={totalRequestLot}
                    itemPerPage={this.filterRequestLot.limit}
                    skipPerPage={this.filterRequestLot.skip}
                    typePaging={"remote"}
                    keyExpr={"RequestID"}
                    elementAttr={{ class: `noBGSelect` }}
                    loading={gridLoading}
                    showRowLines={true}
                    showBorders={false}
                    showColumnLines={false}
                    hoverStateEnabled={true}
                    showColumnHeaders={false}
                    rowAlternationEnabled={true}
                    onChangePage={this.onChangeInfoPage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column
                        caption={Config.lang("ERP_Hanh_dong")}
                        visible={Config.isMobile}
                        alignment={"center"}
                        cellRender={this.renderButtonAction}
                    />
                    <Column cellRender={this.renderEmpProfile} width={280} />
                    <Column cellRender={this.renderDetail} minWidth={320} />
                    <Column
                        fixed={true}
                        allowEditing={false}
                        alignment={"right"}
                        fixedPosition={"right"}
                        cellRender={this.renderButtonAction}
                        visible={!Config.isMobile}
                    />
                </GridContainer>
            </>
        );
    }
}

export default compose(
    connect((state) => ({
    }), dispatch => ({
        GeneralActions: bindActionCreators(GeneralActions, dispatch),
        W06F0006Actions: bindActionCreators(W06F0006Actions, dispatch)
    })), withStyles(styles, { withTheme: true })
)(W06F0006);