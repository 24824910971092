/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/13/2020
 * @Example
 */
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import Config from "../../../../config/index";
import { TextField } from "../../../common/form-material";
class W06F0003ObjectCustomer extends Component {
  render() {
    const { formDataMaster, contractTypesData } = this.props;
    const { ObjectNameU = "", ObjectAddressU = "", VATNo = "", BankAccountNo = "", TelNo = "", FaxNo = "", NotesU = "", GObjectName } = formDataMaster;
    const { IsGuarantee } = contractTypesData || {};
    return (
      <div style={{ width: "100%" }}>
        <Row>
          <Col xs={12} sm={6} md={4} lg={3}>
            <TextField
              label={Config.lang("ERP_Nha_cung_cap")}
              variant={"standard"}
              margin={"normal"}
              value={ObjectNameU}
              InputLabelProps={{
                shrink: true
              }}
              disabled={true}
              fullWidth
            />
          </Col>
          <Col xs={12} sm={12} md={8} lg={9}>
            <TextField
              label={Config.lang("ERP_Dia_chi")}
              variant={"standard"}
              margin={"normal"}
              value={ObjectAddressU}
              disabled={true}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <TextField
              label={Config.lang("ERP_Ma_so_thue")}
              variant={"standard"}
              margin={"normal"}
              value={VATNo}
              disabled={true}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <TextField
              label={Config.lang("ERP_So_tai_khoan")}
              variant={"standard"}
              margin={"normal"}
              value={BankAccountNo}
              disabled={true}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <TextField
              label={Config.lang("ERP_So_dien_thoai")}
              variant={"standard"}
              margin={"normal"}
              value={TelNo}
              disabled={true}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Col>
          <Col xs={12} sm={6} md={4} lg={3}>
            <TextField
              label={Config.lang("ERP_So_Fax")}
              variant={"standard"}
              margin={"normal"}
              value={FaxNo}
              disabled={true}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Col>
          {IsGuarantee === 1 && <Col xs={12} sm={6} md={4} lg={3}>
            <TextField
                label={Config.lang("Don_vi_uy_thac")}
                variant={"standard"}
                margin={"normal"}
                value={GObjectName}
                disabled={true}
                InputLabelProps={{
                  shrink: true
                }}
                fullWidth
            />
          </Col>}
          <Col xs={12} sm={12} md={IsGuarantee === 1 ? 8 : 12} lg={IsGuarantee === 1 ? 9 : 12}>
            <TextField
              label={Config.lang("ERP_Ghi_chu")}
              variant={"standard"}
              margin={"normal"}
              disabled={true}
              value={NotesU}
              inputProps={{ min: 0 }}
              InputLabelProps={{
                shrink: true
              }}
              fullWidth
            />
          </Col>
        </Row>
      </div>
    );
  }
}

W06F0003ObjectCustomer.propTypes = {
  formDataMaster: PropTypes.object,
};

export default (W06F0003ObjectCustomer);