import { FormLabel as Label } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { LoadPanel } from 'devextreme-react/load-panel';
import _ from 'lodash';
import moment from 'moment';
import React, { Component } from 'react';
import { FormGroup } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { Collapse } from 'reactstrap';
import { bindActionCreators, compose } from 'redux';
import Config from '../../../../config/index';
import * as GeneralActions from '../../../../redux/general/general_actions';
import * as W06F0007Actions from '../../../../redux/W0X/W06F0007/W06F0007_actions';
import ButtonGeneral from "../../../common/button/button-general";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import './W06F0007.scss';
import W06F0007MappingInfo from './W06F0007MappingInfo';
import W06F0007PO from './W06F0007PO';
import W06F0007PopupAllocateDelivery from './W06F0007PopupAllocateDelivery';
import W06F0007SO from './W06F0007SO';

const styles = {
    btnDiv: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    }
};

class W06F0007 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MappingID: "",
            mappingNo: "",
            quotationID: "",
            quotationItemID: "",
            isAdd: false,
            isView: false,
            isEdit: true,
            isSave: false,
            loading: false,
            onDelete: false,
            isMapping: true,
            isUpdated: false,
            isSCMapping: false,
            isOpenContract: true,
            btnActionStatus: false,
            isOpenInfoMapping: true,
            btnAllocateDelivery: false,
            showAllocateDelivery: false,
            rowDataInfoMapping: null,
            dataGridSO: [],
            dataGridPO: [],
            cbUnitPack: [],
            decimals: [],
            listDataPacking: [],
            listDataDelivery: [],
            dataGridMappingInfo: [],
            selectedRowKeysPopupSO: [],
            selectedRowKeysPopupPO: [],
        }
        this.oldDataGridSO = [];
        this.oldDataGridPO = [];
        this.oldDataGridDelivery = [];
        this.oldDataGridPacking = [];
        this.oldDataGridMappingInfo = [];
        this.tmpDataPO = [];
        this.key = 0;
        this.packingLength = 0;
        this.deliveryLength = 0;
        this.filterUnitPack = {
            StrSearch: "",
            ObjectTypeID: "CC",
            skip: 0,
            limit: 15
        };
        this.filterWareHousePacking = {
            StrSearch: "",
            skip: 0,
            limit: 15
        };
        this.filterCbDelivery = {
            StrSearch: "",
            skip: 0,
            limit: 15
        };
        this.dataInfo = {
            mode: "edit",
            RequestID: "",
            permission: null
        };
    }

    loadPermission = async () => {
        await this.props.GeneralActions.getPermission(["W06F0006"], (isPer) => {
            if (!_.isEmpty(isPer)) {
                const indexW06F0006 = isPer.findIndex(item => item.ScreenID === "W06F0006");
                this.dataInfo.permission = isPer[indexW06F0006].Permission;
                this.setState({
                    isPermission: isPer[indexW06F0006].Permission,
                });
            }
        });
    };

    getInfo = (flag) => {
        const { location } = this.props;
        if (location && location.state) {
            const RequestID = (location.state.RequestID ? location.state.RequestID : location.state.voucher_id) || "";
            if (location.state.permission <= 0) return;
            this.dataInfo.mode = location.state?.mode || "";
            this.dataInfo.RequestID = RequestID;
            this.dataInfo.permission = location.state?.permission || "";
            return {
                RequestID,
                mode: location.state.mode,
                permission: location.state.permission
            }
        } else {
            if (flag) {
                browserHistory.push(Config.getRootPath() + "W06F0006");
                return null;
            }
            return false;
        }
    };

    componentDidMount = async () => {
        if (!this.getInfo(true)) return;
        Config.checkRedirect(this.props.location.pathname);
        const { RequestID, mode, permission } = this.getInfo();
        if (_.isEmpty(RequestID)) return;
        if (_.isUndefined(permission)) await this.loadPermission();
        if (this.state.isPermission <= 0) return;
        this.getFormEdit(RequestID);
        this.getDecimals();
        if (mode) {
            this.setState({
                isView: mode === "view",
                isEdit: mode === "edit",
                isAdd: mode === "add",
                requestID: mode === "add" ? "" : RequestID
            }, () => {
                if (mode !== "add") {
                    this.getFormEdit(RequestID);
                }
            });
        }
    }

    componentWillUnmount = () => {
        if (!this.state.isView) Config.unCheckRedirect();
        this.setState = (state, callback) => {
            return;
        };
    }

    getDecimals = () => {
        const { decimals } = this.props;
        if (_.isEmpty(decimals)) {
            this.props.W06F0007Actions.getDecimals((error, data) => {
                if (error) {
                    const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                } else if (data) {
                    this.setState({ decimals: data });
                }
            });
        } else {
            this.setState({ decimals });
        }
    }

    getFormEdit = (RequestID) => {
        this.props.W06F0007Actions.getFormEdit({ RequestID }, (error, data) => {
            if (error) {
                const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                if (_.isEmpty(this.key)) this.key = data.MappingNo;
                this.packingLength = data.packing.length;
                this.deliveryLength = data.delivery.length;
                this.setState({
                    quotationID: !_.isEmpty(data.salesContract) ? data.salesContract[0]?.QuotationID : "",
                    quotationItemID: !_.isEmpty(data.salesContract) ? data.salesContract[0]?.QuotationItemID : "",
                    MappingID: data.MappingID,
                    mappingNo: data.MappingNo,
                    dataGridSO: data.salesContract,
                    dataGridPO: data.purchaseContract,
                    listDataDelivery: data.delivery,
                    listDataPacking: data.packing
                }, () => {
                    this.oldDataGridSO = data.salesContract.map(rs => ({ ...rs }));
                    this.oldDataGridPO = data.purchaseContract.map(rs => ({ ...rs }));
                    this.oldDataGridDelivery = data.delivery.map(rs => ({ ...rs }));
                    this.oldDataGridPacking = data.packing.map(rs => ({ ...rs }));
                    // this.oldDataGridMappingInfo = this.state.dataGridMappingInfo.map(rs => ({ ...rs, delivery: rs.delivery.map(rs1 => ({ ...rs1 })), packing: rs.packing.map(rs2 => ({ ...rs2 })) }));
                });
            }
        });
    }

    onOpenList = (mode, close = false) => {
        switch (mode) {
            case "AllocateDelivery":
                this.setState({ showAllocateDelivery: close });
                break;
            default:
                break;
        }
    }

    onClosePopup = (openPopName) => {
        this.setState({ [openPopName]: false });
    }

    onCheckSO = () => {
        const isSO = this.state.dataGridSO.find(rs => rs.isUsed === 1);
        let isMapping = true;
        if (this.tmpDataPO.length > 0 && !_.isUndefined(isSO)) isMapping = false;
        this.setState({ isMapping });
    }

    onCheckPO = (data) => {
        const isPO = this.state.dataGridSO.find(rs => rs.isUsed === 1);
        this.tmpDataPO = data;
        let isMapping = true;
        if (data.length > 0 && !_.isUndefined(isPO)) isMapping = false;
        this.setState({ isMapping });
    }

    onMapping = () => {
        let btnAllocateDeliveryStatus = false;
        let dataFilter = [...this.state.dataGridMappingInfo];
        const { dataGridPO } = this.state;
        const isSO = this.state.dataGridSO.find(rs => rs.isUsed === 1);
        this.tmpDataPO.forEach((item) => {
            if (item.isUsed === 1) {
                this.key++;
                let el = {};
                el.ContractIDS = isSO.ContractID;
                el.QuotationID = isSO.QuotationID;
                el.QuotationItemID = isSO.QuotationItemID;
                el.ContractIDP = item.ContractID;
                el.PurchaseItemID = item.PurchaseItemID;
                el.MappingNo = this.key >= 10 && this.key < 100 ? '0' + (this.key) : this.key < 10 ? '00' + (this.key) : (this.key);
                el.ContractNo = item.ContractNo;
                el.Supplier = item.Supplier;
                el.LotNo = isSO.LotNo;
                el.InventoryIDPO = item.InventoryID;
                el.InventoryNamePO = item.InventoryNameU;
                el.InventoryIDSO = isSO.InventoryID;
                el.LotNo = isSO.LotNo;
                el.InventoryNameSO = isSO.InventoryNameU;
                el.MappingItemID = this.key;
                el.UnitPackID = !_.isUndefined(isSO.UnitPackID) ? isSO.UnitPackID : "";
                el.UnitPackName = !_.isUndefined(isSO.UnitPackName) ? isSO.UnitPackName : "";
                el.key = this.key;
                el.packing = [];
                el.delivery = [];
                el.StartDate = null;
                el.EndDate = null;
                dataFilter.push(el);
            }
        });
        // Mapping hết số lượng của lưới đồng mua (PO)- Số lượng còn lại <> 0 thì disable)
        if (!_.isEmpty(dataGridPO)) {
            dataGridPO.forEach(item => {
                if (_.toNumber(item.Quantity) !== 0) {
                    btnAllocateDeliveryStatus = true; // Disable
                }
            });
        }
        this.setState({ dataGridMappingInfo: dataFilter, isMapping: true, isUpdated: true, isSCMapping: true, btnAllocateDelivery: btnAllocateDeliveryStatus });
    }

    sumData = (array, field) => {
        const SumQ = array.reduce((accumulator, currentValue) => {
            return (!_.isUndefined(accumulator) ? Number(accumulator) : 0) + (!_.isUndefined(currentValue[field]) ? Number(currentValue[field]) : 0)
        }, 0);
        return SumQ;
    }

    deleteDataMappingInfo = (QuotationItemID, PurchaseItemID, cb) => {
        const { dataGridMappingInfo, dataGridSO, dataGridPO } = this.state;
        const dataGridSOFilter = [...dataGridSO];
        const dataGridPOFilter = [...dataGridPO];
        let sumSO = 0;
        let sumPO = 0;
        dataGridSOFilter.map(item => {
            if (item.QuotationItemID === QuotationItemID) {
                dataGridMappingInfo.forEach(rs => {
                    if (rs.QuotationItemID === QuotationItemID) {
                        sumSO += (!_.isUndefined(rs.Quantity) ? rs.Quantity : 0);
                    }
                });
                item.MAPQuantity = sumSO;
                item.Quantity = item.SUMQuantity - sumSO;
            }
            return item;
        });
        dataGridPOFilter.map(item => {
            if (item.PurchaseItemID === PurchaseItemID) {
                dataGridMappingInfo.forEach(rs => {
                    if (rs.PurchaseItemID === PurchaseItemID) {
                        sumPO += (!_.isUndefined(rs.Quantity) ? rs.Quantity : 0);
                    }
                });
                item.MAPQuantity = sumPO;
                item.Quantity = item.SUMQuantity - sumPO;
            }
            return item;
        });
        if (cb) cb();
        if (_.isEmpty(dataGridMappingInfo)) {
            this.setState({ dataGridSO: dataGridSOFilter, isUpdated: true, isMapping: false });
        } else {
            this.setState({ dataGridSO: dataGridSOFilter, isUpdated: true });
        }
    }

    setSumQuantity = async (oldData, newData, cb) => {
        const { dataGridMappingInfo, dataGridSO, dataGridPO, btnAllocateDelivery } = this.state;
        let btnAllocateDeliveryStatus = btnAllocateDelivery;
        const dataGridSOFilter = [...dataGridSO];
        const dataGridPOFilter = [...dataGridPO];
        const { LotNo, PurchaseItemID } = oldData;
        let sumSO = 0;
        let sumPO = 0;
        let isCancel = false;
        dataGridSOFilter.map(item => {
            if (item.LotNo === LotNo) {
                dataGridMappingInfo.forEach(rs => {
                    if (rs.LotNo === LotNo) {
                        if (oldData.key === rs.key) {
                            sumSO += Number(!_.isUndefined(newData.Quantity) ? newData.Quantity : 0);
                        } else {
                            sumSO += Number(!_.isUndefined(rs.Quantity) ? rs.Quantity : 0)
                        }
                    }
                });
                if (item.SUMQuantity - sumSO < 0) {
                    isCancel = true;
                    if (cb) cb(true);
                } else {
                    item.MAPQuantity = sumSO;
                    item.Quantity = item.SUMQuantity - sumSO;
                }
            }
            return item;
        });
        if (!isCancel && !_.isEmpty(dataGridPOFilter)) {
            dataGridPOFilter.map(item => {
                if (item.PurchaseItemID === PurchaseItemID) {
                    dataGridMappingInfo.forEach(rs => {
                        if (rs.PurchaseItemID === PurchaseItemID) {
                            if (oldData.key === rs.key) {
                                sumPO += Number(!_.isUndefined(newData.Quantity) ? newData.Quantity : 0);
                            } else {
                                sumPO += Number(!_.isUndefined(rs.Quantity) ? rs.Quantity : 0)
                            }
                        }
                    });
                    if (item.SUMQuantity - sumPO < 0) {
                        isCancel = true;
                        if (cb) cb(true);
                    } else {
                        item.MAPQuantity = sumPO;
                        item.Quantity = item.SUMQuantity - sumPO;
                    }
                }
                return item;
            });
        }
        if (!_.isEmpty(dataGridPOFilter)) {
            btnAllocateDeliveryStatus = !(dataGridPOFilter.map(obj => obj.Quantity).every(item => item === 0));
        }
        if (!isCancel) {
            if (cb) cb(isCancel);
            this.setState({ dataGridSO: dataGridSOFilter, btnAllocateDelivery: btnAllocateDeliveryStatus });
        }
    }

    setDataTemp = () => {
        this.setState({ loading: false });
    }

    saveHistory = async (MappingID) => {
        const { dataGridSO, dataGridMappingInfo, isSCMapping } = this.state;
        if (_.isEmpty(dataGridSO) || !isSCMapping) return;
        let data = [];
        dataGridMappingInfo.forEach((dataMoi) => {
            const dataCu = dataGridSO.find(item => item.LotNo === dataMoi.LotNo);
            if (dataCu && dataMoi.LotNo === dataCu.LotNo) {
                let el = {};
                el.codeID = MappingID;
                el.formID = "W06F0007";
                el.type = "text";
                el.linkedTrans = "";
                el.oldValue = dataCu["LotNoOld"];
                el.newValue = dataMoi["LotNo"];
                el.description84 = `${Config.lang("ERP_Duyet_yeu_cau_tach_lot", "vi")} `;
                el.description01 = `${Config.lang("ERP_Duyet_yeu_cau_tach_lot", "en")} `;
                el.action = 1;
                data.push(el);
            }
        });
        //Yêu cầu mapping
        // console.log("============data========>", data);
        if (data.length > 0) {
            const params = {
                attributes: JSON.stringify(data),
            };
            await Config.saveHistory(params, (err, data) => {
                if (err) {
                    this.setState({ isSave: false });
                    const message = err.message || Config.lang("ERP_Luu_lich_su_khong_thanh_cong");
                    Config.popup.show("INFO", message);
                    return false;
                }
            });
        }
    }

    createSave = async (newData, oldData, options) => {
        if (!(newData && oldData && typeof newData === typeof oldData) || !options || (!newData && !oldData)) return false;
        //Process..
        const { keyExpr, mode,
            //  excludeFields
        } = options || {};
        let data = {
            added: [],
            edited: [],
            removed: []
        };
        // let keys = [];
        let dataType = newData ? _.isArray(newData) ? "array" : (_.isObject(newData) ? "object" : null) : null;
        dataType = dataType ? dataType : (oldData ? _.isArray(oldData) ? "array" : (_.isObject(oldData) ? "object" : null) : null);
        switch (dataType) {
            case "array":
                //Array..
                newData = newData || [];
                oldData = oldData || [];
                const newDataKeys = newData.map(d => d[keyExpr]);
                const oldDataKeys = oldData.map(d => d[keyExpr]);
                const added = newData.filter(d => oldDataKeys.indexOf(d[keyExpr]) <= -1);
                const edited = newData.filter(d => oldDataKeys.indexOf(d[keyExpr]) > -1);
                const removed = oldData.filter(d => newDataKeys.indexOf(d[keyExpr]) <= -1);
                //Added..
                if (!mode || mode.length < 1 || mode.indexOf("add") > -1) {
                    added.forEach(item => {
                        data.added.push(item);
                    });
                }
                //Edited..
                if (!mode || mode.length < 1 || mode.indexOf("edit") > -1) {
                    edited.forEach(item => {
                        if (item.IsUpdated === 1) {
                            data.edited.push(item);
                        }
                    });
                }
                //Removed..
                if (!mode || mode.length < 1 || mode.indexOf("remove") > -1) {
                    removed.forEach(item => {
                        data.removed.push(item);
                    });
                }
                break;
            default:
                break;
        }
        return await data;
    };

    onRefuse = async () => {
        const { RequestID } = this.dataInfo;
        const { quotationID: QuotationID = "" } = this.state;
        const { UserNameU: UserName = "" } = Config.profile;
        const params = {
            UserName,
            RequestID,
            QuotationID
        };
        this.setState({ isSave: true });
        this.props.W06F0007Actions.onRefuse(params, (error, data) => {
            if (error) {
                this.setState({ isSave: false });
                const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                Config.notify.show("success", Config.lang("ERP_Tu_choi_thanh_cong"), 2000);
                this.setState({ btnActionStatus: true, isSave: false, isMapping: true }, () => {
                    Config.unCheckRedirect();
                })
            }
        });
    }

    formatDateTime = (data) => {
        if (!_.isArray(data)) return;
        const cloneData = [...data];
        cloneData.forEach(item => {
            Object.keys(item).forEach(key => {
                if (item[key] && Config.isValidDateTime(item[key])) {
                    item[key] = moment(item[key]).format('YYYY-MM-DD')
                }
            });
        });
        return cloneData;
    }

    handleParamsSaveSplitSlot = () => {
        const { dataGridPO, dataGridSO, dataGridMappingInfo, quotationItemID: QuotationItemID = "", quotationID: QuotationID = "", MappingID } = this.state;
        const { UserNameU: UserName = "" } = Config.profile;
        const dataPurchaseContract = dataGridPO.filter(item => item.isUsed);
        const { RequestID } = this.dataInfo;
        const params = {
            UserName,
            MappingID,
            RequestID,
            QuotationID,
            QuotationItemID,
            salesContract: JSON.stringify(dataGridSO),
            purchaseContract: JSON.stringify(dataPurchaseContract),
            mapping: JSON.stringify(dataGridMappingInfo)
        };
        return params;
    }

    onSaveSplitLot = async () => {
        const { isEdit, isSCMapping, MappingID } = this.state;
        // let packingLength = [];
        // let deliveryLength = [];
        // let numberPacking = 0;
        // let numberDelivery = 0;
        // const {  dataGridPO, dataGridMappingInfo } = this.state;
        // let quantityGridPO = false;
        // dataGridPO.forEach(item => {
        //     if (item.Quantity > 0) {
        //         quantityGridPO = true;
        //     }
        // })
        // if (!_.isEmpty(dataGridMappingInfo)) {
        //     dataGridMappingInfo.forEach(item => {
        //         packingLength.push(item.packing.length);
        //         deliveryLength.push(item.delivery.length);
        //     });
        //     numberPacking = packingLength.reduce((currentValue, sum) => currentValue + sum, 0);
        //     numberDelivery = deliveryLength.reduce((currentValue, sum) => currentValue + sum, 0);
        // }
        // if (quantityGridPO) {
        //     Config.popup.show("INFO", `${Config.lang("ERP_So_luong_con_lai_cua_hop_dong_mua_phai_bang_0")} ${Config.lang("ERP_Ban_khong_the_luu")}`);
        // } else if (this.packingLength !== 0 && this.deliveryLength !== 0 && (numberPacking !== this.packingLength || numberDelivery !== this.deliveryLength)) {
        //     Config.popup.show("INFO", `${Config.lang("ERP_Phai_thuc_hien_phan_bo_cac_dong_chi_tiet_bao_bi_va_chi_tiet_giao_hang")} ${Config.lang("ERP_Ban_khong_the_luu")} `);
        //     return;
        // }
        // else {
        if (isSCMapping) await this.refGridMappingInfo.onSaveGrid();
        const params = this.handleParamsSaveSplitSlot();
        this.setState({ isSave: true });
        this.props.W06F0007Actions.saveApproval(params, async (error, data) => {
            if (error) {
                this.setState({ isSave: false });
                const message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            } else if (data) {
                await this.saveHistory(!isEdit ? data.MappingID : MappingID);
                this.getFormEdit(MappingID);
                this.key = 0;
                Config.notify.show("success", Config.lang("ERP_Xac_nhan_thanh_cong"), 2000);
                this.setState({ isSave: false, isView: true, isEdit: false, isUpdated: false, MappingID: MappingID, isAdd: false, isMapping: true, btnActionStatus: true }, () => {
                    Config.unCheckRedirect();
                    browserHistory.push({
                        pathname: Config.getRootPath() + "W06F0006",
                    });
                });
            }
        });
        // }
    }

    renderNumberFormat = (e) => {
        if (!e) return false;
        const { decimals } = this.state;
        const { data } = e.row;
        const { column } = e;
        const dataField = column.dataField;
        const decimalValue = data[dataField];
        let decimalScale = 5;
        switch (dataField) {
            case "Quantity":
            case "QuantityActual":
            case "QuantityRemain":
                if (decimals && decimals.QuantityDecimals) decimalScale = decimals.QuantityDecimals;
                break;
            default:
                break;
        }
        return <NumberFormat value={decimalValue} displayType={"text"} thousandSeparator={true} decimalScale={decimalScale} />
    };

    onCheckDelete = () => {
        if (this.state.dataGridSO.length <= 0 && this.state.dataGridPO.length <= 0) {
            this.setState({ onDelete: true });
        }
    }

    setIsUpdate = () => {
        if (!this.state.isUpdated) this.setState({ isUpdated: true });
    }

    goBack = () => {
        if (!this.state.btnActionStatus) {
            Config.popup.show("YES_NO", Config.lang("ERP_Du_lieu_chua_duoc_luu_ban_co_muon_tiep_tuc_khong"), () => {
                browserHistory.goBack();
            });
        } else {
            browserHistory.goBack();
        }
    }

    onAllocateDelivery = () => {
        this.setState({ showAllocateDelivery: true });
    }

    onTachBtnAction = (dataGridDelivery, dataGridMappingInfo) => {
        this.setState({
            listDataDelivery: dataGridDelivery,
            dataGridMappingInfo,
            showAllocateDelivery: false
        }, () => this.onSaveSplitLot());
    }

    render() {
        if (this.dataInfo.permission <= 0) return null;
        const { btnActionStatus, isView, isEdit, dataGridSO, isOpenContract, dataGridPO, isMapping, isOpenInfoMapping,
            dataGridMappingInfo, decimals, rowDataInfoMapping,
            isSave, loading, listDataDelivery, showAllocateDelivery, btnAllocateDelivery, listDataPacking } = this.state;
        const { classes } = this.props;

        return (
            <div id={"W06F0007"}>
                <LoadPanel
                    position={{ my: 'center', of: '#W06F0007' }}
                    visible={isSave || loading}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                />
                {showAllocateDelivery &&
                    <W06F0007PopupAllocateDelivery
                        keyExpr={"DeliveryItemID"}
                        decimals={decimals}
                        dataGridPacking={listDataPacking}
                        dataGridDelivery={listDataDelivery}
                        dataGridMappingInfo={dataGridMappingInfo}
                        refW06F0007={this}
                    />}
                <FormGroup className={"W06F0007-ActionToolbar"}>
                    <ActionToolbar allwaysTop title={Config.lang("ERP_Phan_bo_mapping_tu_yeu_cau_tach_lot")} onBack={this.goBack} >
                        <div className={classes.btnDiv}>
                            {isEdit &&
                                <ButtonGeneral
                                    disabled={btnActionStatus}
                                    color={"danger"}
                                    className={"mgr10"}
                                    typeButton={"close"}
                                    variant={"contained"}
                                    name={Config.lang("ERP_Tu_choi")}
                                    style={{ textTransform: 'uppercase' }}
                                    onClick={async () => await this.onRefuse()}
                                />}
                        </div>
                    </ActionToolbar>
                </FormGroup>
                <div style={{ paddingTop: 10 }}>
                    <div className={"cursor-pointer"} style={{ display: 'inline-block', padding: '0 0 20px 10px' }} onClick={() => this.setState({ isOpenContract: !this.state.isOpenContract })}>
                        <i className={`${isOpenContract ? "fa fa-chevron-down" : "fa fa-chevron-right"} pdr10`} aria-hidden={"true"}></i>
                        <Label style={{ color: '#0095FF', fontWeight: 'bold', userSelect: 'none' }} className={"cursor-pointer text-uppercase"} >{Config.lang("ERP_Hop_dong_mapping")}</Label>
                    </div>
                    <Collapse isOpen={isOpenContract}>
                        <W06F0007SO refW06F0007={this} btnActionStatus={btnActionStatus} isView={isView} dataGridSO={dataGridSO} />
                        <W06F0007PO refW06F0007={this} btnActionStatus={btnActionStatus} isView={isView} dataGridPO={dataGridPO} onRefPO={ref => this.refGridPO = ref} dataGridMappingInfo={dataGridMappingInfo} />
                        {!isView &&
                            <div className={"pdt10"}>
                                <ButtonGeneral
                                    disabled={isMapping}
                                    name={"mapping"}
                                    color={"primary"}
                                    typeButton={"mapping"}
                                    variant={"contained"}
                                    style={{ textTransform: 'uppercase' }}
                                    onClick={this.onMapping}
                                />
                            </div>}

                    </Collapse>
                </div>
                {dataGridMappingInfo && dataGridMappingInfo.length > 0 &&
                    <div style={{ paddingTop: 20 }}>
                        <div className={"cursor-pointer pdb10 pdl10"}
                            style={{ display: 'inline-block' }}
                            onClick={() => this.setState({ isOpenInfoMapping: !this.state.isOpenInfoMapping })}>
                            <i className={`${isOpenInfoMapping ? "fa fa-chevron-down" : "fa fa-chevron-right"} pdr10`} aria-hidden={"true"}></i>
                            <Label style={{ color: '#0095FF', fontWeight: 'bold', userSelect: 'none' }} className={"cursor-pointer text-uppercase"} >{Config.lang("ERP_Thong_tin_mapping")}</Label>
                        </div>
                        <Collapse
                            isOpen={isOpenInfoMapping}
                        >
                            <W06F0007MappingInfo
                                refW06F0007={this}
                                isView={isView}
                                decimals={decimals}
                                btnActionStatus={btnActionStatus}
                                rowDataInfoMapping={rowDataInfoMapping}
                                dataGridMappingInfo={dataGridMappingInfo}
                                refGridMappingInfo={ref => this.refGridMappingInfo = ref}
                            />
                        </Collapse>
                        <div className={"pdt10"}>
                            <ButtonGeneral
                                disabled={btnAllocateDelivery}
                                name={Config.lang("ERP_Phan_bo_thong_tin_chi_tiet_giao_hang")}
                                color={"primary"}
                                typeButton={"mapping"}
                                variant={"contained"}
                                onClick={this.onAllocateDelivery}
                            />
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default compose(
    connect((state) => ({
        decimals: state.W06F0007.decimals,
    }), dispatch => ({
        GeneralActions: bindActionCreators(GeneralActions, dispatch),
        W06F0007Actions: bindActionCreators(W06F0007Actions, dispatch)
    })), withStyles(styles, { withTheme: true }))(W06F0007);