import { FormLabel as Label } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Column } from "devextreme-react/data-grid";
import { LoadPanel } from 'devextreme-react/load-panel';
import _ from "lodash";
import moment from 'moment';
import React, { Component } from 'react';
import { Col, FormGroup, Row } from "react-bootstrap";
import { connect } from 'react-redux';
import { browserHistory } from 'react-router';
import { bindActionCreators, compose } from 'redux';
import Config from '../../../../config/index';
import * as GeneralActions from '../../../../redux/general/general_actions';
import * as W06F0005Actions from '../../../../redux/W0X/W06F0005/W06F0005_actions';
import ButtonExportExcel from '../../../common/button/button-export-excel';
import ButtonGeneral from "../../../common/button/button-general";
import { Combo, TextField } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import GridActionBar from '../../../grid-container/grid-actionbar';
import GridContainer from "../../../grid-container/grid-container";

const styles = theme => {
    return {
        cellColumn: {
            display: 'flex',
            flexDirection: 'row',
        },
        gridLabel: {
            color: theme.palette.info.main,
            fontWeight: 'bold',
            textTransform: 'uppercase'
        },
        rowSpacing: {
            marginBottom: 15,
            display: 'flex'
        },
        datePicker: {
            margin: '0 !important',
        },
        cellColumnContent: {
            minHeight: 100,
            '& label': {
                width: 150
            },
            '& span': {
                paddingLeft: 10,
                minWidth: 100,
            }
        },
        headerFlexCap: {
            display: 'inline-block !important'
        }
    }
};

class W06F0005 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            infoMappingGridLoaded: false,
            loadingCboListObjects: false,
            detailMappingGridLoaded: false,
            loadingCboListObjectsCC: false,
            loadingCboListInventories: false,
            isPermission: 0,
            isPermissionView: 0,
            totalInfoMapping: 0,
            totalDetailMapping: 0,
            LotNo: "",
            Buyer: "",
            ReportID: "",
            Supplier: "",
            VoucherNo: "",
            StrSearch: "",
            InventoryID: "",
            ContractNoSO: "",
            ContractNoPO: "",
            DatePrint: moment().format("YYYY-MM-DD"), // Default Date,
            StoreParams: [],
            infoMappingdata: [],
            filterGridValue: [],
            selectedRowKeys: [],
            detailMappingdata: [],
            dataCboListObjects: {
                rows: [],
                total: 0
            },
            dataCboListObjectsCC: {
                rows: [],
                total: 0
            },
            dataCboListInventoris: {
                rows: [],
                total: 0
            },
        }
        this.filterCboListObject = {
            StrSearch: "",
            ObjectTypeID: "KH",
            skip: 0,
            limit: 10
        };
        this.filterCboListObjectCC = {
            StrSearch: "",
            ObjectTypeID: "CC",
            skip: 0,
            limit: 10
        };
        this.filterCboListInventories = {
            StrSearch: "",
            ContractID: "",
            skip: 0,
            limit: 10
        };
        this.filterInfoMappingPage = {
            limit: 5,
            skip: 0
        };
        this.filterDetailMappingPage = {
            limit: 5,
            skip: 0
        };
        this.filterMappingChosen = {
            Language: Config.language || "84",
            limit: 10,
            skip: 0
        };
        this.changeInfoPage = false;
        this.filterFlexCaption = {
            InfoID: "W05S",
            GroupInfo: "S",
            Language: Config.language || "84",
        }
        this.selectedInfoRowKeys = [];
        this.selectedInfoRowData = [];
        this.selectedDetailRowKeys = [];
        this.selectedDetailRowData = [];
        this.infoGrid = null;
        this.detailGrid = null;
        this.customReportFileName = null;
    }

    componentDidMount = async () => {
        await this.loadPermission();
        if (this.state.isPermission <= 0) return;
        const { getFlexCaption } = this.props;
        this.loadCboListObjects();
        this.loadCboListObjectsCC();
        this.getCboListInventories();
        if (_.isEmpty(getFlexCaption)) this.getFlexCaption();
    }

    cellRenderMapping = (e) => {
        const { classes } = this.props;
        return (
            <div className={classes.cellColumnContent}>
                <div> <Label>{Config.lang("ERP_Ma_mapping")}</Label>
                    <span >{e.data.MappingID}</span> </div>
                <div> <Label>{Config.lang("ERP_So_mapping")}</Label>
                    <span>{e.data.MappingNo}</span> </div>
                <div> <Label>{Config.lang("ERP_Thoi_gian_dong_hang")}</Label>
                    <span>{moment(e.data.StartDate).format("DD/MM/YYYY")} - {moment(e.data.EndDate).format("DD/MM/YYYY")}</span></div>
            </div>
        )
    }

    cellRenderPurchase = (e) => {
        const { classes } = this.props;
        return (
            <div className={classes.cellColumnContent}>
                <div> <Label>{Config.lang("ERP_So_hop_dong_mua")}</Label>
                    <span >{e.data.ContractNoPO}</span>  </div>
                <div> <Label>{Config.lang("ERP_Nha_cung_cap")}</Label>
                    <span>{e.data.SupplierName}</span> </div>
                <div>
                    <Label>{Config.lang("ERP_Hang_hoa")}</Label>
                    <span>{e.data.InventoryIDPO} - {e.data.InventoryNamePO}</span>
                </div>
            </div>
        )
    }

    cellRenderSales = (e) => {
        const { classes } = this.props;
        return (
            <div className={classes.cellColumnContent}>
                <div>
                    <Label>{Config.lang("ERP_So_hop_dong_ban")}</Label>
                    <span >{e.data.ContractNoSO}</span>
                </div>
                <div>
                    <Label>{Config.lang("ERP_Khach_hang")}</Label>
                    <span>{e.data.BuyerName}</span>
                </div>
                <div>
                    <Label>{Config.lang("ERP_Hang_hoa")}</Label>
                    <span>{e.data.InventoryIDSO} - {e.data.InventoryNameSO}</span>
                </div>
                <div>
                    <Label>{Config.lang("ERP_Dot_trien_khai")} - Lot No</Label>
                    <span>{e.data.VoucherNo} - {e.data.LotNo}</span>
                </div>
            </div>
        )
    }

    cellRenderPacking = (e) => {
        const { classes } = this.props;
        return (
            <div className={classes.cellColumnContent}>
                <div>
                    <Label>{Config.lang("ERP_Quy_cach_dong_goi")}</Label>
                    <span >{e.data.PackingMethodName}</span>
                </div>
                <div>
                    <Label>{Config.lang("ERP_Loai_bao_dong_goi")}</Label>
                    <span>{e.data.PackingTypeName}</span>
                </div>
                <div>
                    <Label>{Config.lang("ERP_Nhan_mac")}</Label>
                    <span>{e.data.MarkingName}</span>
                </div>
                <div>
                    <Label>{Config.lang("ERP_Thong_tin_bao_bi")}</Label>
                    <span>{e.data.PackNote}</span>
                </div>
            </div>
        )
    }

    cellRenderQuantity = (e) => {
        const { classes } = this.props;
        return (
            <div className={classes.cellColumnContent}>
                <div>
                    <Label style={{ width: 230 }}>{Config.lang("ERP_So_luong_bao_yeu_cau")}</Label>
                    <span >{e.data.QuantityPack}</span>
                </div>
                <div>
                    <Label style={{ width: 230 }}>{Config.lang("ERP_So_luong_yeu_cau_mapping")}</Label>
                    <span>{e.data.Quantity}</span>
                </div>
                <div>
                    <Label style={{ width: 230 }}>{Config.lang("ERP_So_luong_thuc_hien_mapping")}</Label>
                    <span>{e.data.QuantityActual}</span>
                </div>
                <div>
                    <Label style={{ width: 230 }}>{Config.lang("ERP_So_luong_con_lai")}</Label>
                    <span>{e.data.QuantitRemain}</span>
                </div>
            </div>
        )
    }

    getFlexCaption = () => {
        const { GroupInfo, InfoID, Language } = this.filterFlexCaption;
        const params = {
            GroupInfo,
            InfoID,
            Language
        };
        this.setState({ loading: true });
        this.props.W06F0005Actions.getFlexCaption(params, (error, data) => {
            this.setState({ loading: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
        });
    };

    loadPermission = async () => {
        await this.props.GeneralActions.getPermission(["W06F0005", "W05F0006"], (isPer) => {
            if (!_.isEmpty(isPer)) {
                const indexW06F0005 = isPer.findIndex(item => item.ScreenID === "W06F0005");
                const indexW05F0006 = isPer.findIndex(item => item.ScreenID === "W05F0006");
                this.setState({
                    isPermission: isPer[indexW06F0005].Permission,
                    isPermissionView: isPer[indexW05F0006].Permission
                });
            }
        });
    };

    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({ ["loading" + key]: status });
    };

    loadCboListObjects = (isReset) => {
        const { skip, limit, ObjectTypeID, StrSearch } = this.filterCboListObject;
        const params = {
            ObjectTypeID,
            StrSearch,
            skip,
            limit
        };
        this.setLoading("CboListObjects", true);
        this.props.W06F0005Actions.getListObjects(params, (error, data) => {
            this.setLoading("CboListObjects", false);
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboListObjects } = this.state;
                const rowData = data.rows || [];
                const rows = isReset ? rowData : dataCboListObjects.rows.concat(rowData);
                const total = data.total || data.length;
                this.setState({
                    dataCboListObjects: {
                        rows,
                        total
                    }
                });
            }
        });
    };

    loadCboListObjectsCC = (isReset) => {
        const { skip, limit, ObjectTypeID, StrSearch } = this.filterCboListObjectCC;
        const params = {
            ObjectTypeID,
            StrSearch,
            skip,
            limit
        };
        this.setLoading("CboListObjectsCC", true);
        this.props.W06F0005Actions.getListObjects(params, (error, data) => {
            this.setLoading("CboListObjectsCC", false);
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboListObjectsCC } = this.state;
                const rowData = data.rows || [];
                const rows = isReset ? rowData : dataCboListObjectsCC.rows.concat(rowData);
                const total = data.total || data.length;
                this.setState({
                    dataCboListObjectsCC: {
                        rows,
                        total
                    }
                });
            }
        });
    };

    getCboListInventories = (isReset) => {
        const { skip, limit, ContractID, StrSearch } = this.filterCboListInventories;
        const params = {
            ContractID,
            StrSearch,
            skip,
            limit
        };
        this.setLoading('CboListInventories', true);
        this.props.W06F0005Actions.getInventories(params, (error, data) => {
            this.setLoading('CboListInventories', false);
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboListInventoris } = this.state;
                const rowData = data.rows || [];
                const rows = isReset ? rowData : dataCboListInventoris.rows.concat(rowData);
                const total = data.total || data.length;
                this.setState({
                    dataCboListInventoris: {
                        rows,
                        total
                    }
                });
            }
        });
    };

    onTextValueChanged = (fieldName, e) => {
        let stateValue = null;
        switch (fieldName) {
            case "StrSearch":
            case "ContractNoSO":
            case "ContractNoPO":
            case "VoucherNo":
            case "LotNo":
                stateValue = e.target.value; //TextField
                break;
            case "Buyer":
            case "Supplier":
            case "InventoryID":
                let _fieldName = fieldName;
                if (fieldName === "Buyer" || fieldName === "Supplier") _fieldName = "ObjectID";
                stateValue = !_.isNull(e.value) ? e.value[_fieldName] : '';   //Combo
                break;
            case "ReportID":
            case "DatePrint":
                stateValue = e.value;
                break;
            default:
                break;
        }
        this.setState({ [fieldName]: stateValue })
    }

    getMapping = (changeSkip, changeLimit) => {
        if (_.isUndefined(changeSkip)) this.filterInfoMappingPage.skip = 0; // reset default
        if (_.isUndefined(changeLimit)) this.filterInfoMappingPage.limit = 5; // reset default
        let skip = changeSkip || 0; //default
        let limit = changeLimit || 5; //default
        const { ContractNoSO, VoucherNo, LotNo, Buyer, ContractNoPO, Supplier, InventoryID, StrSearch } = this.state
        const params = {
            limit,
            skip,
            LotNo,
            Buyer,
            Supplier,
            VoucherNo,
            StrSearch,
            InventoryID,
            ContractNoSO,
            ContractNoPO,
        }
        this.setState({ infoMappingGridLoaded: true })
        this.props.W06F0005Actions.getMapping(params, (errors, data) => {
            this.setState({ infoMappingGridLoaded: false })
            if (errors) {
                let message = errors.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            this.setState({
                infoMappingdata: data.rows || [],
                totalInfoMapping: data.total || 0,
            });
        });
    }

    onChangeInfoPage = (page) => {
        this.filterInfoMappingPage.skip = page * this.filterInfoMappingPage.limit;
        this.getMapping(this.filterInfoMappingPage.skip, this.filterInfoMappingPage.limit);
        this.changeInfoPage = true;
    };

    onChangeDetailPage = (page) => {
        this.filterDetailMappingPage.skip = page * this.filterDetailMappingPage.limit;
        this.getDetailtMapping(this.filterDetailMappingPage.skip, this.filterDetailMappingPage.limit);
        this.changeDetailPage = true;
    };

    onChangePerPage = (perPage) => {
        this.filterInfoMappingPage.skip = 0;
        this.filterInfoMappingPage.limit = perPage;
        this.getMapping(this.filterInfoMappingPage.skip, this.filterInfoMappingPage.limit);
        this.changeInfoPage = true;
    };

    handleGridInfoChecked = (currentSelectedRowKeys, keyExpr) => {
        currentSelectedRowKeys.forEach((val) => {
            if (this.selectedInfoRowData.indexOf(val) < 0) {
                this.selectedInfoRowData.push(val);
                this.selectedInfoRowKeys.push(val[keyExpr]);
            }
        });
        this.handleSelected(); //Tick chọn thông tin load lưới chi tiết 
    }

    handleGridInfoUnChecked = (currentDeselectedRowKeys, keyExpr) => {
        this.selectedInfoRowData = this.selectedInfoRowData.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e[keyExpr]) < 0;
        });
        this.selectedInfoRowKeys = this.selectedInfoRowKeys.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e) < 0;
        });
        this.handleSelected(); //Tick chọn thông tin load lưới chi tiết 
    }

    handleGridDetailChecked = (currentSelectedRowKeys, keyExpr) => {
        currentSelectedRowKeys.forEach((val) => {
            if (this.selectedDetailRowData.indexOf(val) < 0) {
                this.selectedDetailRowData.push(val);
                this.selectedDetailRowKeys.push(val[keyExpr]);
            }
        });
    }

    handleGridDetailUnChecked = (currentDeselectedRowKeys, keyExpr) => {
        this.selectedDetailRowData = this.selectedDetailRowData.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e[keyExpr]) < 0;
        });
        this.selectedDetailRowKeys = this.selectedDetailRowKeys.filter((e) => {
            return currentDeselectedRowKeys.indexOf(e) < 0;
        });
    }

    onSelectionChanged = (e, gridType) => {
        const selectedRowKeys = e.selectedRowKeys;
        const currentSelectedRowKeys = e.selectedRowsData;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        if (this.changeInfoPage) {
            if (currentSelectedRowKeys.length > 0) {
                if (gridType === "InfoGrid") this.handleGridInfoChecked(currentSelectedRowKeys, "MappingItemID");
                if (gridType === "DetailGrid") this.handleGridDetailChecked(currentSelectedRowKeys, "DeliveryItemID");
            }
            this.changeInfoPage = false;
        } else {
            if (currentDeselectedRowKeys.length > 0) {// UnSelect
                if (gridType === "InfoGrid") this.handleGridInfoUnChecked(currentDeselectedRowKeys, "MappingItemID");
                if (gridType === "DetailGrid") this.handleGridDetailUnChecked(currentDeselectedRowKeys, "DeliveryItemID");
            }
            else if (currentSelectedRowKeys.length > 0) {
                if (gridType === "InfoGrid") this.handleGridInfoChecked(currentSelectedRowKeys, "MappingItemID");
                if (gridType === "DetailGrid") this.handleGridDetailChecked(currentSelectedRowKeys, "DeliveryItemID");
            }
        }
        if (gridType === "InfoGrid" && this.selectedInfoRowKeys.length > 0) this.selectedInfoRowKeys = [...new Set(this.selectedInfoRowKeys)]; //Unique
        if (gridType === "DetailGrid" && this.selectedDetailRowKeys.length > 0) this.selectedDetailRowKeys = [...new Set(this.selectedDetailRowKeys)]; //Unique
        this.setState({ selectedRowKeys })
    }

    onStoreParams = () => {
        let arrayParam = [];
        const { ReportID, DatePrint } = this.state;
        if (this.selectedDetailRowData.length > 0) {
            this.selectedDetailRowData.forEach(item => {
                this.customReportFileName = `XNGH ${item.ContractNo} ${item.SupplierShortName}`;
                this.customReportFileName = this.customReportFileName.replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, ' ');
                arrayParam.push([
                    { id: "MappingID", type: "VarChar", value: item.MappingID },
                    { id: "MappingItemID", type: "VarChar", value: item.MappingItemID },
                    { id: "DeliveryItemID", type: "VarChar", value: item.DeliveryItemID }
                ])
            });
        }
        const StoreParams = [
            { id: "UserID", type: "VarChar", value: Config.profile.UserID || "" },
            { id: "DivisionID", type: "VarChar", value: Config.getDivisionID() || "" },
            { id: "ReportID", type: "VarChar", value: ReportID || "" },
            { id: "Language", type: "Varchar", value: Config.language || "84" },
            { id: "DatePrint", type: "VarChar", value: DatePrint || "" },
            { id: "arrayParam", type: "VarChar", value: arrayParam },
        ];
        this.setState({ StoreParams })
    }

    handleSelected = () => {
        this.selectedInfoRowKeys = [...new Set(this.selectedInfoRowKeys)];
        if (_.isEmpty(this.selectedInfoRowKeys)) {
            this.setState({
                filterGridValue: [],
                detailMappingdata: [],
                totalDetailMapping: 0,
            }, () => this.getMapping(this.filterInfoMappingPage.skip, this.filterInfoMappingPage.limit));
        } else {
            const filterGridValue = ["ContractIDP", "contains", this.selectedInfoRowData[0]?.ContractIDP];
            const MappingItemID = JSON.stringify(this.selectedInfoRowKeys);
            const { Language, limit, skip } = this.filterMappingChosen;
            const paramsDetailMapping = {
                MappingItemID,
                Language,
                limit,
                skip
            };
            this.setState({ filterGridValue, detailMappingGridLoaded: true })
            this.props.W06F0005Actions.getChosenMapping(paramsDetailMapping, (error, data) => {
                this.setState({ detailMappingGridLoaded: false })
                if (error) {
                    let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                    Config.popup.show("INFO", message);
                    return false;
                }
                if (data) {
                    this.setState({
                        detailMappingdata: data.rows || [],
                        totalDetailMapping: data.total || 0,
                    });
                }
            });
        }
    }

    renderButtonAction = (e) => {
        let params = {
            mode: "view",
            MappingID: e.data.MappingID,
        };
        return (
            <GridActionBar>
                <IconButton
                    style={{ padding: 4 }}
                    disabled={false}
                    color={"default"}
                    aria-label={"View"}
                    onClick={() => browserHistory.push({
                        pathname: Config.getRootPath() + "W05F0007",
                        state: params
                    })} >
                    <VisibilityIcon />
                </IconButton>
            </GridActionBar>
        )
    };

    renderFlexColumn = () => {
        const { getFlexCaption, classes } = this.props;
        const supColumn = getFlexCaption.map((item, i) => {
            const { Caption, DataType } = item;
            const width = Caption.length > 20 ? 330 : 180;
            let { FieldName } = item;
            let fieldType = "";
            let formatDate = "";
            let alignment = "left";
            switch (DataType) {
                case "N":
                    fieldType = "number";
                    alignment = "right";
                    break;
                case "D":
                    fieldType = "date";
                    alignment = "center";
                    formatDate = "dd/MM/yyyy";
                    break;
                case "S":
                    fieldType = "string";
                    break;
                default:
                    break;
            }
            return (
                <Column
                    key={i}
                    caption={Caption}
                    cssClass={classes.headerFlexCap}
                    dataField={FieldName}
                    dataType={fieldType}
                    format={formatDate}
                    width={width}
                    alignment={alignment}
                    cellRender={(e) => this.cellRender(e, item)}
                />
            )
        });
        return supColumn;
    };

    cellRender = (e, currentFlexCaption) => {
        const { data } = e.row;
        const { column } = e;
        if (!data || !column || !column.dataField) return;
        let cellValue = _.isUndefined(data[column.dataField]) ? currentFlexCaption.ValueDefaultU : data[column.dataField];
        if (column.dataType === "date" && Config.isValidDateTime(cellValue)) cellValue = moment(cellValue).format("DD/MM/YYYY") //FOrmat Date
        return cellValue;
    };

    render() {
        const { dataCboListObjects, loadingCboListObjects, dataCboListInventoris, loadingCboListInventories, errorForm,
            ContractNoSO, ContractNoPO, VoucherNo, LotNo, dataCboListObjectsCC, loadingCboListObjectsCC, DatePrint,
            Buyer, InventoryID, Supplier, loading, infoMappingdata, totalInfoMapping, isPermissionView,
            StoreParams, isPermission, infoMappingGridLoaded, filterGridValue, detailMappingGridLoaded, detailMappingdata } = this.state;
        const { classes, getFlexCaption } = this.props;
        if (!isPermission) return null;
        return (
            <>
                <LoadPanel
                    shadingColor={"rgba(0,0,0,0.4)"}
                    position={{ my: "center", of: "#W06F0005" }}
                    visible={loading}
                    showIndicator={true}
                    shading={true}
                    showPane={true}
                />
                <FormGroup className="W06F004-ActionToolbar" style={{ marginBottom: Config.isMobile ? 50 : 15 }}>
                    <ActionToolbar allwaysTop title={Config.lang("ERP_Xuat_mau_in_xac_nhan_giao_hang")} onBack={() => browserHistory.goBack()} >
                        <div className={`text-row ${classes.cellColumn}`} style={{ width: '100%' }}>
                            <Col xs={12} sm={12} md={3} lg={3}>
                                <DateBoxPicker
                                    className={classes.datePicker}
                                    error={_.isEmpty(DatePrint) && _.get(errorForm, "DatePrint", false)}
                                    InputLabelProps={{
                                        shrink: true,
                                        style: { marginTop: -7 }
                                    }}
                                    useMaskBehavior={true}
                                    label={Config.lang("ERP_Ngay_in")}
                                    stylingMode={"underlined"}
                                    shrink={true}
                                    required={true}
                                    showClearButton={true}
                                    value={DatePrint}
                                    margin={"normal"}
                                    disabled={loading}
                                    onValueChanged={e => this.onTextValueChanged("DatePrint", e)}
                                />
                            </Col>
                            {this.selectedDetailRowKeys.length > 0 && !_.isEmpty(DatePrint) &&
                                <ButtonExportExcel
                                    name={Config.lang("ERP_Xuat_du_lieu")}
                                    ModuleID={"06"}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    ReportTypeID={"W06F0005"}
                                    style={{ textTransform: 'uppercase' }}
                                    disabled={detailMappingGridLoaded}
                                    StoreParams={StoreParams}
                                    onClick={this.onStoreParams}
                                    customReportFileName={this.customReportFileName}
                                />}
                        </div>
                    </ActionToolbar>
                </FormGroup>
                <Row style={{ margin: 'auto' }}>
                    <div className={classes.rowSpacing}>
                        <Col xs={12} sm={12} md={3} lg={3} style={{ height: 70 }}>
                            <TextField
                                label={Config.lang("ERP_So_hop_dong_ban")}
                                variant={"standard"}
                                margin={"normal"}
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={String(ContractNoSO)}
                                onChange={(e) => this.onTextValueChanged("ContractNoSO", e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3} style={{ height: 70 }}>
                            <TextField
                                label={Config.lang("ERP_Dot_trien_khai")}
                                variant={"standard"}
                                margin={"normal"}
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={String(VoucherNo)}
                                onChange={(e) => this.onTextValueChanged("VoucherNo", e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3} style={{ height: 70 }}>
                            <TextField
                                label={Config.lang("ERP_Ma_lot")}
                                variant={"standard"}
                                margin={"normal"}
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={String(LotNo)}
                                onChange={(e) => this.onTextValueChanged("LotNo", e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3} style={{ height: 70 }}>
                            <Combo.LoadMore
                                dataSource={dataCboListObjects}
                                skip={this.filterCboListObject.skip}
                                limit={this.filterCboListObject.limit}
                                displayExpr={"ObjectNameU"}
                                keyExpr={"ObjectID"}
                                valueExpr={"ObjectID"}
                                value={Buyer}
                                loading={loadingCboListObjects}
                                shrink={true}
                                margin={"normal"}
                                stylingMode={"underlined"}
                                label={Config.lang("ERP_Khach_hang")}
                                showClearButton={true}
                                onValueChanged={(e) => this.onTextValueChanged("Buyer", e)}
                                onInputChanged={(e) => {
                                    this.filterCboListObject.StrSearch = e.target.value;
                                    this.filterCboListObject.skip = 0;
                                    this.loadCboListObjects(true);
                                }}
                                itemRender={(e) => {
                                    const { data } = e;
                                    if (!data) return null;
                                    return `${data.ObjectID} - ${data.ObjectNameU}`;
                                }}
                                onLoadMore={(e) => {
                                    this.filterCboListObject.skip = e.skip;
                                    this.filterCboListObject.limit = e.limit;
                                    this.loadCboListObjects();
                                }}
                            />
                        </Col>
                    </div>
                    <div className={classes.rowSpacing}>
                        <Col xs={12} sm={12} md={3} lg={3} style={{ height: 70 }}>
                            <TextField
                                label={Config.lang("ERP_So_hop_dong_mua")}
                                variant={"standard"}
                                margin={"normal"}
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={String(ContractNoPO)}
                                onChange={(e) => this.onTextValueChanged("ContractNoPO", e)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3} style={{ height: 70 }}>
                            <Combo.LoadMore
                                dataSource={dataCboListObjectsCC}
                                skip={this.filterCboListObjectCC.skip}
                                limit={this.filterCboListObjectCC.limit}
                                displayExpr={"ObjectNameU"}
                                keyExpr={"ObjectID"}
                                valueExpr={"ObjectID"}
                                value={Supplier}
                                loading={loadingCboListObjectsCC}
                                shrink={true}
                                margin={"normal"}
                                stylingMode={"underlined"}
                                label={Config.lang("ERP_Nha_cung_cap")}
                                showClearButton={true}
                                onValueChanged={(e) => this.onTextValueChanged("Supplier", e)}
                                onInputChanged={(e) => {
                                    this.filterCboListObjectCC.StrSearch = e.target.value;
                                    this.filterCboListObjectCC.skip = 0;
                                    this.loadCboListObjectsCC(true);
                                }}
                                itemRender={(e) => {
                                    const { data } = e;
                                    if (!data) return null;
                                    return `${data.ObjectID} - ${data.ObjectNameU}`;
                                }}
                                onLoadMore={(e) => {
                                    this.filterCboListObjectCC.skip = e.skip;
                                    this.filterCboListObjectCC.limit = e.limit;
                                    this.loadCboListObjectsCC();
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3} style={{ height: 70 }}>
                            <Combo.LoadMore
                                dataSource={dataCboListInventoris}
                                skip={this.filterCboListInventories.skip}
                                limit={this.filterCboListInventories.limit}
                                displayExpr={"InventoryNameU"}
                                keyExpr={"InventoryID"}
                                valueExpr={"InventoryID"}
                                value={InventoryID}
                                loading={loadingCboListInventories}
                                shrink={true}
                                margin={"normal"}
                                stylingMode={"underlined"}
                                label={Config.lang("ERP_Hang_hoa")}
                                showClearButton={true}
                                onValueChanged={(e) => this.onTextValueChanged("InventoryID", e)}
                                onInputChanged={(e) => {
                                    this.filterCboListInventories.StrSearch = e.target.value;
                                    this.filterCboListInventories.skip = 0;
                                    this.getCboListInventories(true);
                                }}
                                itemRender={(e) => {
                                    const { data } = e;
                                    if (!data) return null;
                                    return `${data.InventoryID} - ${data.InventoryNameU}`;
                                }}
                                onLoadMore={(e) => {
                                    this.filterCboListInventories.skip = e.skip;
                                    this.filterCboListInventories.limit = e.limit;
                                    this.getCboListInventories();
                                }}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={3} lg={3} style={{ height: 70, display: 'flex', alignItems: 'flex-end' }}>
                            <ButtonGeneral
                                style={{ marginBottom: 10 }}
                                name={Config.lang("ERP_Tim_kiem")}
                                typeButton={"default"}
                                onClick={this.getMapping}
                            />
                        </Col>
                    </div>
                </Row>
                <FormGroup>
                    <Label className={classes.gridLabel} >{Config.lang("ERP_Thong_tin_mapping")}</Label>
                </FormGroup>
                <GridContainer
                    reference={ref => this.infoGrid = ref}
                    dataSource={infoMappingdata}
                    totalItems={totalInfoMapping}
                    itemPerPage={this.filterInfoMappingPage.limit}
                    skipPerPage={this.filterInfoMappingPage.skip}
                    listPerPage={[5, 15, 30, 45, 60]}
                    keyExpr={"MappingItemID"}
                    loading={infoMappingGridLoaded}
                    elementAttr={{ class: `noBGSelect`, style: 'min-height: 500px' }}
                    typeShort={true}
                    showRowLines={false}
                    columnAutoWidth={true}
                    showColumnLines={false}
                    hoverStateEnabled={true}
                    allowCellSelection={false}
                    rowAlternationEnabled={true}
                    sorting={{ mode: "none" }}
                    selection={{
                        allowSelectAll: true,
                        mode: "multiple",
                        selectAllMode: "allPages",
                        showCheckBoxesMode: "always"
                    }}
                    filterPanel={{
                        visible: false,
                    }}
                    filterBuilderPopup={{
                        onInitialized: (e) => {
                            e.component.dispose();
                            e.element.remove();
                        }
                    }}
                    filterValue={filterGridValue}
                    onSelectionChanged={(e) => this.onSelectionChanged(e, "InfoGrid")}
                    selectedRowKey={this.selectedInfoRowKeys}
                    onChangePage={this.onChangeInfoPage}
                    onChangePerPage={this.onChangePerPage}
                >
                    <Column
                        caption={Config.lang("ERP_Hanh_dong")}
                        visible={isPermissionView > 0 && Config.isMobile}
                        alignment={"center"}
                        cellRender={this.renderButtonAction}
                    />
                    <Column caption={Config.lang("ERP_Mapping")} minWidth={200} cellRender={this.cellRenderMapping} />
                    <Column caption={Config.lang("ERP_Hop_dong_mua")} minWidth={200} cellRender={this.cellRenderPurchase} />
                    <Column caption={Config.lang("ERP_Hop_dong_ban")} minWidth={200} cellRender={this.cellRenderSales} />
                    <Column caption={Config.lang("ERP_Dong_goi")} minWidth={200} cellRender={this.cellRenderPacking} />
                    <Column caption={Config.lang("ERP_So_luong")} minWidth={200} cellRender={this.cellRenderQuantity} />
                    <Column dataField={"ContractIDP"} visible={false} />
                    <Column
                        fixed={true}
                        allowEditing={false}
                        alignment={"right"}
                        fixedPosition={"right"}
                        cellRender={this.renderButtonAction}
                        visible={isPermissionView > 0 && !Config.isMobile}
                    />
                </GridContainer>
                <FormGroup style={{ marginTop: 20 }}>
                    <Label className={classes.gridLabel} >{Config.lang("ERP_Chi_tiet_giao_hang")}</Label>
                </FormGroup>
                <GridContainer
                    reference={ref => this.detailGrid = ref}
                    dataSource={detailMappingdata}
                    // totalItems={totalDetailMapping}
                    // itemPerPage={this.filterDetailMappingPage.limit}
                    // skipPerPage={this.filterDetailMappingPage.skip}
                    // sorting={{ mode: "none" }}
                    // onChangePage={this.onChangeDetailPage}
                    // onChangePerPage={this.onChangePerPage}
                    typePaging={"normal"}
                    keyExpr={"DeliveryItemID"}
                    elementAttr={{ class: `noBGSelect`, style: 'min-height: 250px' }}
                    typeShort={true}
                    showRowLines={false}
                    columnAutoWidth={true}
                    showColumnLines={false}
                    hoverStateEnabled={true}
                    allowCellSelection={false}
                    rowAlternationEnabled={true}
                    loading={detailMappingGridLoaded}
                    selection={{
                        allowSelectAll: true,
                        mode: "multiple",
                        selectAllMode: "allPages",
                        showCheckBoxesMode: "always"
                    }}
                    onSelectionChanged={(e) => this.onSelectionChanged(e, "DetailGrid")}
                    selectedRowKey={this.selectedDetailRowKeys}
                >
                    <Column
                        caption={Config.lang("ERP_So_chi_tiet_giao_hang")}
                        dataField={"DeliveryNo"}
                        allowEditing={false}
                        width={220}
                    />
                    <Column
                        caption={Config.lang("ERP_Phuong_tien_van_chuyen")}
                        dataField={"DeliveryName"}
                        allowEditing={false}
                        width={220}
                    />
                    <Column
                        caption={Config.lang("ERP_Dien_thoai")}
                        dataField={"PhoneNo"}
                        allowEditing={false}
                        width={150}
                    />
                    <Column
                        caption={Config.lang("ERP_So_luong_phuong_tien_da_dong")}
                        dataField={"QuantityPack"}
                        allowEditing={false}
                        dataType={"number"}
                        alignment={"right"}
                        width={220}
                    />
                    <Column
                        caption={Config.lang("ERP_Nhan_mac")}
                        dataField={"MarkingName"}
                        allowEditing={false}
                        width={180}
                    />
                    {getFlexCaption.length > 0 && this.renderFlexColumn()}
                    {/*Column ở dưới không có công dụng gì nhưng để fix lỗi nút select fixed và lỗi kéo trang */}
                    <Column
                        fixed={true}
                        fixedPosition={"right"}
                        alignment={"center"}
                        visible={false}
                        allowEditing={false}
                    />
                </GridContainer>
            </>
        );
    }
}

export default compose(
    connect((state) => ({
        getFlexCaption: state.W06F0005.getFlexCaption,
    }), dispatch => ({
        GeneralActions: bindActionCreators(GeneralActions, dispatch),
        W06F0005Actions: bindActionCreators(W06F0005Actions, dispatch)
    })), withStyles(styles, { withTheme: true })
)(W06F0005);