/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/13/2020
 * @Example
 */
import { withStyles } from "@material-ui/core";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import NumberFormat from 'react-number-format';
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as W06F0003Actions from "../../../../redux/W0X/W06F0003/W06F0003_actions";
import { Combo, TextField } from "../../../common/form-material";
import { MoneyInput } from 'diginet-core-ui/components';

const styles = {
  labelNumberFormat: {
    padding: '6.5px 0 !important',
    transform: 'translateY(10px) !important',
    color: '#8F9BB3 !important'
  },
};
class W06F0003GeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataCboEmployees: {
        rows: [],
        total: 0
      },
      loadingCboEmployee: false,
    };
    this.filterCboEmployees = {
      strSearch: "",
      skip: 0,
      limit: 10
    };
  }

  componentDidMount = () => {
    const { dataCboEmployees } = this.props;
    //Load Cbo Employee
    if (_.isEmpty(dataCboEmployees)) {
      this.loadCboEmployees();
    } else {
      const { total, rows } = dataCboEmployees;
      this.setState({
        dataCboEmployees: {
          rows,
          total
        }
      });
    }
  }

  loadCboEmployees = (isReset) => {
    const param = {
      StrSearch: this.filterCboEmployees.strSearch,
      skip: this.filterCboEmployees.skip,
      limit: this.filterCboEmployees.limit
    };
    this.setState({ loading: true, loadingCboEmployee: true });
    this.props.w06f0003Actions.loadCboEmployees(param, (error, data) => {
      this.setState({ loading: false, loadingCboEmployee: false });
      if (error) {
        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
        Config.popup.show("INFO", message);
        return false;
      }
      if (data) {
        const { dataCboEmployees } = this.state;
        const d = data.rows ? data.rows : data;
        const total = data.total ? data.total : 0;
        this.setState({
          dataCboEmployees: {
            rows: isReset ? d : dataCboEmployees.rows.concat(d),
            total: total
          }
        });
      }
    });
  };

  render() {
    const { formDataMaster, classes, getDecimalQuantity, dataCboEmployees } = this.props;
    const { ContractNo, ContractTypeName, EStatusName, CurrencyID, ContractDate, StartDate, EndDate, Employee, ExchangeRate, PaymentMethodName,
      ExceedContract } = formDataMaster;
    return (
      <div style={{ width: '100%' }}>
        <Row>
          <Col md={12} lg={9}>
            <Row>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Loai_hop_dong")}
                  variant={"standard"}
                  margin={"normal"}
                  value={ContractTypeName}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_So_hop_dong")}
                  variant={"standard"}
                  margin={"normal"}
                  value={ContractNo}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Trang_thai")}
                  variant={"standard"}
                  margin={"normal"}
                  value={EStatusName}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Loai_tien")}
                  variant={"standard"}
                  margin={"normal"}
                  value={CurrencyID}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <label className={`MuiFormLabel-root ${classes.labelNumberFormat}`}>
                  {Config.lang("ERP_Ty_gia")}
                </label>
                <NumberFormat
                  fullWidth
                  customInput={TextField}
                  thousandSeparator={true}
                  isNumericString={true}
                  disabled={true}
                  decimalScale={getDecimalQuantity.ExchangeRateDecimals}
                  value={ExchangeRate}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Ngay_ky")}
                  variant={"standard"}
                  margin={"normal"}
                  value={ContractDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <Combo.Employees
                  acceptCustomValue={true}
                  showClearButton={true}
                  disabled={true}
                  required={true}
                  shrink={true}
                  value={Employee}
                  dataSource={dataCboEmployees}
                  keyExpr={"EmployeeID"}
                  valueExpr={"EmployeeID"}
                  subTextExpr={"EmployeeID"}
                  stylingMode={"underlined"}
                  displayExpr={"EmployeeName"}
                  label={Config.lang("ERP_Nguoi_lap")}
                  onHoverAvatar={() => false}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Ngay_bat_dau")}
                  variant={"standard"}
                  margin={"normal"}
                  value={StartDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Ngay_ket_thuc")}
                  variant={"standard"}
                  margin={"normal"}
                  value={EndDate}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <TextField
                  label={Config.lang("ERP_Phuong_thuc_thanh_toan")}
                  variant={"standard"}
                  margin={"normal"}
                  value={PaymentMethodName}
                  InputLabelProps={{
                    shrink: true
                  }}
                  disabled={true}
                  fullWidth
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4}>
                <MoneyInput
                  thousandSeparator={','}
                  decimalDigit={2}
                  allowZero
                  label={Config.lang("Dung_sai")}
                  disabled
                  value={ExceedContract}
                  endIcon={<div>%</div>}
                  style={{ marginTop: 8 }}
                />
              </Col>
            </Row>
          </Col>
          <Col md={0} lg={3}></Col>
        </Row>
      </div>
    );
  }
}

W06F0003GeneralInfo.propTypes = {
  formDataMaster: PropTypes.object,
  getDecimalQuantity: PropTypes.object
};

export default compose(connect(
  (state) => ({
    dataCboEmployees: state.W06F0003.dataCboEmployees,
  }),
  dispatch => ({
    w06f0003Actions: bindActionCreators(W06F0003Actions, dispatch)
  })
), withStyles(styles, { withTheme: true }))(W06F0003GeneralInfo);