/**
 * @copyright 2020 @ DigiNet
 * @author ANHTAI
 * @create 06/08/2020
 * @Example
 */
import { TextField, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import SearchIcon from '@material-ui/icons/Search';
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as W91F0001Actions from "../../../../redux/W0X/W91F0001/W91F0001_actions";
import Icons from "../../../common/icons/";
import Modal from "../../../common/modal/modal";
import GridContainer from "../../../grid-container/grid-container";

const styles = ({
    searchInput: {
        marginBottom: '16px'
    },
    circleBtn: {
        borderRadius: '100%',
        minWidth: 0
    }
});
class W91F0001 extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            timeKeepingListPage: []
        };
        this.paramFilters = {
            StrSearch: "",
            skip: 0,
            limit: 10
        };
        this.data = '';
        this.timer = null;
    }

    loadTimekeepingList = (ObjectTypeID) => {
        const { skip, limit, StrSearch } = this.paramFilters;
        const params = {
            ObjectTypeID,
            StrSearch,
            skip,
            limit
        };
        this.setState({ loaded: true });
        this.props.w91f0001Actions.getTimeKeepingList(params, (error, data) => {
            this.setState({ loaded: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({ timeKeepingListPage: data })
            }
        });
    };

    componentDidMount = () => {
        const { ObjectTypeID, timeKeepingList, objectTypeIDBefore, reLoadPage } = this.props;
        if (ObjectTypeID !== objectTypeIDBefore || (_.isEmpty(timeKeepingList) && ObjectTypeID) || reLoadPage) {
            this.loadTimekeepingList(ObjectTypeID);
        }
    };

    onChangePage = page => {
        this.paramFilters.skip = page * this.paramFilters.limit;
        const { ObjectTypeID } = this.props;
        this.loadTimekeepingList(ObjectTypeID);
    };

    onChangePerPage = perPage => {
        this.paramFilters.skip = 0;
        this.paramFilters.limit = perPage;
        const { ObjectTypeID } = this.props;
        this.loadTimekeepingList(ObjectTypeID);
    };

    onClose = () => {
        const { onClose } = this.props;
        if (onClose) onClose();
    };

    onHide() {
        const { onHide } = this.props;
        if (onHide) {
            onHide(this.data);
        }
    }

    handleSearchData = (e) => {
        this.paramFilters.StrSearch = e;
    };

    onFilter = () => {
        this.paramFilters.skip = 0;
        const { ObjectTypeID } = this.props;
        this.loadTimekeepingList(ObjectTypeID);
    };

    onCloseModal = () => {
        const { onCloseModal } = this.props;
        onCloseModal(false);
    };

    render() {
        const { loaded, timeKeepingListPage } = this.state;
        let { selectedRowKeys, classes, timeKeepingList, showW91F0001 } = this.props;
        const timeKeepingData = !_.isEmpty(timeKeepingListPage) ? timeKeepingListPage : timeKeepingList;
        const { skip, limit } = this.paramFilters;

        return (
            <React.Fragment>
                <Modal open={showW91F0001} maxWidth={"lg"} fullWidth={true}>
                    <Modal.Title disableTypography>
                        <div className={"display_row align-center align-between"} style={{ width: "100%", margin: '4px 0' }}>
                            <div className={"display_row align-center"}>
                                <Typography variant="h6" className={"mgr10 text-uppercase"}>{Config.lang("ERP_Danh_sach_doi_tuong")}</Typography>
                            </div>
                            <div className={'display_row'}>
                                <IconButton
                                    aria-label="close"
                                    size={"small"}
                                    onClick={() => this.onCloseModal()}>
                                    <Icons name={"cancel_filled"} />
                                </IconButton>
                            </div>
                        </div>
                    </Modal.Title>
                    <Modal.Content>
                        <Row>
                            <Col xs={12} sm={12} md={5} lg={5}>
                                <label className={"mgb0"}>{Config.lang('ERP_Tim_kiem_doi_tuong')}</label>
                                <TextField
                                    className={classes.searchInput}
                                    placeholder={Config.lang('ERP_Tim_kiem_doi_tuong')}
                                    variant={"outlined"}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <Button className={classes.circleBtn} onClick={this.onFilter}>
                                                <SearchIcon />
                                            </Button>
                                        )
                                    }}
                                    // onChange={(e) => this.handleSearchData(e.target.value)}
                                    onChange={(e) => {
                                        clearTimeout(this.timer);
                                        let value = e.target.value;
                                        this.timer = setTimeout(() => {
                                            this.paramFilters.StrSearch = value;
                                            this.loadTimekeepingList(this.props.ObjectTypeID);
                                        }, [1000])
                                    }}
                                    fullWidth
                                />
                            </Col>
                            <Col xs={12} sm={12} md={12} lg={12}>
                                <GridContainer
                                    height={"calc(100vh - 250px)"}
                                    itemPerPage={limit}
                                    skipPerPage={skip}
                                    loading={loaded}
                                    selectedRowKey={selectedRowKeys}
                                    dataSource={_.get(timeKeepingData, "rows")}
                                    totalItems={_.get(timeKeepingData, "total")}
                                    showBorders={false}
                                    columnAutoWidth={true}
                                    showColumnLines={false}
                                    hoverStateEnabled={true}
                                    pagerFullScreen={false}
                                    typePaging={"remote"}
                                    onDbRowClick={(e) => {
                                        this.data = e.data;
                                        this.onHide();
                                    }}
                                    onChangePage={this.onChangePage}
                                    onChangePerPage={this.onChangePerPage}
                                >
                                    <Column dataField={"ObjectID"} caption={Config.lang("ERP_Ma_doi_tuong")} width={180} />
                                    <Column dataField={"ObjectNameU"} caption={Config.lang("ERP_Ten_doi_tuong")} width={380} />
                                    <Column dataField={"ObjectAddressU"} caption={Config.lang("ERP_Dia_chi")} width={280} />
                                    <Column dataField={"VATNo"} caption={Config.lang("ERP_Ma_so_thue")} width={120} />
                                    <Column dataField={"BankAccountNo"} caption={Config.lang("ERP_So_tai_khoan")} width={150} />
                                    <Column dataField={"TelNo"} caption={Config.lang("ERP_So_dien_thoai")} width={120} />
                                    <Column dataField={"FaxNo"} caption={Config.lang("ERP_So_Fax")} width={120} />
                                </GridContainer>
                            </Col>
                        </Row>
                    </Modal.Content>
                </Modal>
            </React.Fragment>
        )
    }
}

W91F0001.propTypes = {
    showW91F0001: PropTypes.bool,
    mode: PropTypes.number,
    ObjectTypeID: PropTypes.string,
    onHide: PropTypes.func,
    onCloseModal: PropTypes.func,
    reLoadPage: PropTypes.bool,
};

export default compose(connect((state) => ({
    timeKeepingList: state.W91F0001.timeKeepingList,
    objectTypeIDBefore: state.W91F0001.objectTypeIDBefore,
}), (dispatch) => ({
    w91f0001Actions: bindActionCreators(W91F0001Actions, dispatch)
})), withStyles(styles))(W91F0001);
