import { FormLabel as Label } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Typography from "@material-ui/core/Typography";
import { Column } from "devextreme-react/data-grid";
import _ from 'lodash';
import PropTypes from "prop-types";
import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import Config from '../../../../config/index';
import * as W06F0007Actions from '../../../../redux/W0X/W06F0007/W06F0007_actions';
import ButtonGeneral from "../../../common/button/button-general";
import Modal from '../../../common/modal/modal';
import GridContainer from "../../../grid-container/grid-container";

const styles = {
    spanDetail: {
        minHeight: 100,
        '& label': {
            width: '200px'
        },
        '& span': {
            paddingLeft: '10px',
            minWidth: '100px',
        }
    },
    divDetail: {
        marginTop: 10
    },
    numberFormatInput: {
        border: 'none !important',
        width: '100%',
        height: 41,
        '& input': {
            border: 'none !important'
        }
    },
    ellipsis: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
};

class W06F0007PopupAllocateDelivery extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            condition01: false,
            condition02: false,
            totalDataGrid: 0,
            dataGridDelivery: [],
            dataGridMappingInfo: []
        }
        this.dataGridDelivery = null;
    }

    componentDidMount = () => {
        const { dataGridDelivery: dataGridDeliveryProps = [], dataGridMappingInfo: dataGridMappingInfoProps = [] } = this.props;
        let dataGridDelivery = [];
        let dataGridMappingInfo = [];
        if (!_.isEmpty(dataGridDeliveryProps)) dataGridDelivery = dataGridDeliveryProps.map(item => ({ ...item }));
        if (!_.isEmpty(dataGridMappingInfoProps)) dataGridMappingInfo = dataGridMappingInfoProps.map(item => ({ ...item }));
        this.setState({
            dataGridDelivery,
            dataGridMappingInfo,
            totalDataGrid: dataGridDelivery.length
        });
    }

    handleUpdateNumberID = (idx) => {
        let number = idx + 1;
        if (number <= 9) {
            number = "000" + number;
        } else if (number > 9) {
            number = "00" + number;
        } else if (number > 99) {
            number = "0" + number;
        }
        return number;
    }

    groupByField = (array, key) => {
        return array.reduce((group, item) => {
            if (Object.keys(group).includes(item[key])) {
                group[item[key]].data = group[item[key]].data.concat(item);
            } else {
                group[item[key]] = {
                    data: [item],
                };
            }
            return group;
        }, {})
    }

    onSplitLot = async () => {
        this.dataGridDelivery.instance.saveEditData();
        const { dataGridMappingInfo, dataGridDelivery } = this.state;
        const { dataGridPacking } = this.props;
        let condition01 = false;
        let condition02 = false;
        let deliveryNo = [];
        let mappingNo = [];
        if (!_.isEmpty(dataGridDelivery)) {
            dataGridDelivery.forEach(item => {
                const quantityPack = _.isNull(item.QuantityPack) ? 0 : item.QuantityPack;
                if ((quantityPack || 0) !== (item.totalQuantitySplit || 0)) {  // Phải bằng số lượng đóng gói
                    deliveryNo.push(item.DeliveryNo);
                    item.WarningRow = 1;
                    condition01 = true;
                } else {
                    delete item["WarningRow"];
                }
            });
        }
        if (!_.isEmpty(dataGridMappingInfo)) {
            dataGridMappingInfo.forEach(item => {
                const quantity = _.isNull(item.Quantity) ? 0 : item.Quantity;
                if ((item.totalQuantitySplit || 0) > (quantity || 0)) { // Không đc lớn hơn SL Yêu cầu 
                    mappingNo.push(item.MappingNo);
                    item.WarningColumn = 1;
                    condition02 = true;
                } else {
                    delete item["WarningColumn"];
                }
            })
        }
        this.dataGridDelivery.instance.refresh();
        if (condition01) { // Bước 1 Điều kiện
            this.setState({ condition01 });
            if (!_.isEmpty(deliveryNo)) deliveryNo = deliveryNo.join(', ');
            const message = `${Config.lang("ERP_Tong_so_luong_dong_goi_cua_cac_mapping_phai_bang_so_luong_dong_goi_cua_chi_tiet_giao_hang")} ${deliveryNo}`;
            Config.popup.show("INFO", message);
        } else if (condition02) {
            this.setState({ condition01: false, condition02 });
            if (!_.isEmpty(mappingNo)) mappingNo = mappingNo.join(', ');
            const message = `${Config.lang("ERP_Tong_so_luong_chi_tiet_giao_hang_duoc_phan_bo_cua_mapping")} ${mappingNo} ${(Config.lang("ERP_Da_vuot_qua_so_luong_cho_phep")).toLowerCase()}`;
            Config.popup.show("INFO", message);
        }
        else { //Bước 2  Lưu
            const dataGridDeliveryClone = dataGridDelivery.map(item => ({ ...item }));
            const allDeliveryName = dataGridDelivery && dataGridDelivery.length > 0 ? Object.keys(dataGridDelivery[0]) : [];
            const sumNum01 = this.props.refW06F0007.sumData(dataGridPacking, 'Num01');
            dataGridMappingInfo.forEach((mappingItem, mappingIndex) => {
                if (!_.isEmpty(dataGridPacking) && mappingIndex === 0) {
                    mappingItem.QuantityPack = sumNum01;
                    mappingItem.packing = dataGridPacking && dataGridPacking.map((packingItem, packingIdx) => {
                        const number = this.handleUpdateNumberID(packingIdx);
                        if (packingIdx === 0) {
                            packingItem.Num02 = sumNum01 - packingItem.Num01;
                        } else {
                            packingItem.Num02 = dataGridPacking[packingIdx - 1].Num02 - packingItem.Num01;
                        }
                        return {
                            ...packingItem,
                            PackNo: packingItem.PackNo && mappingItem.MappingNo + ".P." + number
                        };
                    });
                } else {
                    mappingItem.QuantityPack = 0;
                }
                if (!_.isEmpty(dataGridDeliveryClone) && mappingItem?.totalQuantitySplit) {
                    let _number = 0;
                    const delivery = dataGridDeliveryClone && dataGridDeliveryClone.map((deliveryItem, deliveryIdx) => {
                        allDeliveryName.forEach(name => {
                            if (name.slice(0, 13) === "QuantitySplit" && name.slice(13) === mappingItem.MappingNo) {
                                deliveryItem.QuantityPack = deliveryItem[name];
                            }
                        });
                        const number = this.handleUpdateNumberID(_number);
                        _number = deliveryItem.QuantityPack ? _number + 1 : _number;
                        return {
                            ...deliveryItem,
                            QuantityPack: deliveryItem.QuantityPack,
                            DeliveryNo: mappingItem.MappingNo + ".D." + number
                        };
                    });
                    mappingItem.delivery = delivery.filter(rs => rs.QuantityPack);
                    const group = this.groupByField(mappingItem.delivery, 'DeliveryID');
                    Object.keys(group).map(gr => {
                        group[gr].data.map((itemG, indexG) => {
                            if (indexG === 0) {
                                itemG.QuantityRemain = itemG.Quantity - itemG.QuantityPack;
                            } else {
                                itemG.QuantityRemain = group[gr].data[indexG - 1].QuantityRemain - itemG.QuantityPack;
                            }
                            return itemG;
                        })
                        return gr;
                    });
                }
            });
            this.props.refW06F0007.onTachBtnAction(dataGridDelivery, dataGridMappingInfo);
        }
    }

    cellRenderDetailLeft = (e) => {
        const { classes } = this.props;
        const { DeliveryNo = "", DeliveryID = "", QuantityPack = "" } = e.data;
        return (
            <div className={classes.spanDetail}>
                <div className={classes.divDetail}>
                    <Label>{Config.lang("ERP_So_chi_tiet_giao_hang")}:</Label>
                    <span >{DeliveryNo}</span>
                </div>
                <div className={classes.divDetail}>
                    <Label>{Config.lang("ERP_Phuong_tien_van_chuyen")}:</Label>
                    <span>{DeliveryID}</span>
                </div>
                <div className={classes.divDetail}>
                    <Label>{Config.lang("ERP_So_luong_phuong_tien_da_dong")}:</Label>
                    <span>{QuantityPack}</span>
                </div>
            </div>
        )
    }

    renderHeaderColumns = (e, item) => {
        if (!e || _.isEmpty(item)) return false;
        const { classes } = this.props;
        return (
            <div style={{ textAlign: 'left' }}>
                <div className={classes.ellipsis}>{Config.lang("ERP_Mapping")}: {item.MappingNo}</div>
                <div className={classes.ellipsis}>{Config.lang("ERP_So_luong")}: {item.Quantity}</div>
            </div>
        )
    }

    renderNumberFormat = (e) => {
        if (!e) return false;
        const { condition01, condition02, dataGridMappingInfo } = this.state;
        const { data, rowIndex } = e.row;
        const { column } = e;
        const dataField = column.dataField;
        let decimalValue = data[dataField];
        let limitDecimal = 0;
        if (column) {
            const cellElement = e.component.getCellElement(rowIndex, column.dataField);
            if (condition01) {
                if (cellElement && data.WarningRow === 1) cellElement.style.backgroundColor = '#ff000020';
                if (cellElement && _.isUndefined(data.WarningRow)) cellElement.style.backgroundColor = 'transparent';
            } else if (condition02) {
                const allDeliveryName = Object.keys(dataGridMappingInfo[0]);
                dataGridMappingInfo.forEach(item => {
                    allDeliveryName.forEach(name => {
                        if (item?.WarningColumn === 1) {
                            const quantitySplitName = name.slice(0, 13);
                            const deliveryItemID = name.slice(13);
                            const mappingNo = dataField.slice(13);
                            if (quantitySplitName === "QuantitySplit" && deliveryItemID === data.DeliveryItemID && decimalValue === item[name] && item.MappingNo === mappingNo) {
                                if (cellElement) cellElement.style.backgroundColor = '#ff000020';
                            }
                        }
                    })
                })
            }
        }
        return <NumberFormat decimalScale={limitDecimal} value={decimalValue} displayType={"text"} thousandSeparator={true} />
    };

    handleSumQuantitySplit = (currentObj, nameLength, nameWish) => { // Tính tổng Object 
        let resultSum = 0;
        let totalQuantitySplit = [];
        if (!_.isEmpty(currentObj) && nameLength && !_.isEmpty(nameWish)) {
            let currentObjName = Object.keys(currentObj);
            currentObjName.forEach(name => {
                const quantitySplitName = name.slice(0, nameLength);
                if (quantitySplitName === nameWish) {
                    totalQuantitySplit.push(currentObj[name]);
                }
            });
        }
        if (!_.isEmpty(totalQuantitySplit)) resultSum = totalQuantitySplit.reduce((currentValue, sum) => currentValue + sum, 0);
        return resultSum;
    }

    render() {
        const { loading, totalDataGrid, dataGridDelivery, dataGridMappingInfo } = this.state;
        const { keyExpr, classes } = this.props;

        return (
            <div>
                <Modal open={true}
                    fullWidth={true}
                    maxWidth={"lg"}
                >
                    <Modal.Title disableTypography>
                        <div className={"display_row align-center align-between flex-wrap"}
                            style={{ width: "100%", margin: '4px 0' }}>
                            <div className={"display_row align-center"}>
                                <Typography variant={"h6"} className={"mgr10"}>{Config.lang("ERP_Phan_bo_chi_tiet_giao_hang")}</Typography>
                            </div>
                            <div>
                                <ButtonGeneral
                                    size={"large"}
                                    color={"primary"}
                                    variant={"contained"}
                                    name={Config.lang("ERP_Tach")}
                                    loading={loading}
                                    style={{ textTransform: 'uppercase', marginRight: 15 }}
                                    onClick={this.onSplitLot} />
                                <ButtonGeneral
                                    size={"large"}
                                    typeButton={"cancel"}
                                    name={Config.lang("ERP_Dong1")}
                                    disabled={loading}
                                    style={{ textTransform: 'uppercase' }}
                                    onClick={() => this.props.refW06F0007.onOpenList("AllocateDelivery", false)} />
                            </div>
                        </div>
                    </Modal.Title>
                    <Modal.Content>
                        <GridContainer
                            reference={ref => this.dataGridDelivery = ref}
                            totalItems={totalDataGrid}
                            dataSource={dataGridDelivery}
                            keyExpr={keyExpr}
                            gridProps={{
                                style: { maxHeight: 520 }
                            }}
                            // height={"calc(100vh - 180px)"}
                            disabled={false}
                            showRowLines={true}
                            columnAutoWidth={false}
                            pagerFullScreen={false}
                            typeShort={window.innerWidth < 768}
                            loadPanel={{
                                enabled: loading
                            }}
                            editing={{
                                mode: "cell",
                                refreshMode: "reshape",
                                allowUpdating: true,
                                texts: {
                                    confirmDeleteMessage: ""
                                }
                            }}
                            onRowUpdating={(e) => {
                                const quantitySplitLenght = 13; //QuantitySplit có 13 kí tự 
                                let currentColumnName = (Object.keys(e.newData))[0];
                                //<===================>Tính tổng totalQuantitySplit của DELIVERY<===================>
                                const allDeliveryName = Object.keys(e.oldData);
                                e.oldData[currentColumnName] = e.newData[currentColumnName];
                                if (!allDeliveryName.includes(currentColumnName)) {
                                    allDeliveryName.push(currentColumnName);
                                }
                                const resultSumDelivery = this.handleSumQuantitySplit(e.oldData, quantitySplitLenght, "QuantitySplit", e.newData[currentColumnName]);
                                e.oldData["totalQuantitySplit"] = resultSumDelivery; // Tính tổng Delevery
                                //<===================>Tính tổng totalQuantitySplit của DELIVERY<===================>

                                //<===================>SET QuantitySplit và Tính tổng Cho lưới Mapping<===================>
                                if (currentColumnName.length > quantitySplitLenght) {
                                    const quantitySplitName = currentColumnName.slice(0, quantitySplitLenght);
                                    if (quantitySplitName === "QuantitySplit") {
                                        const mappingNoName = currentColumnName.slice(quantitySplitLenght);
                                        dataGridMappingInfo.forEach(item => {
                                            if (item.MappingNo === mappingNoName) {
                                                const QuantitySplitByDeliveryItemID = "QuantitySplit" + e.oldData.DeliveryItemID;
                                                item[QuantitySplitByDeliveryItemID] = e.newData[currentColumnName];
                                                const resultSumMapping = this.handleSumQuantitySplit(item, quantitySplitLenght, "QuantitySplit");
                                                item["totalQuantitySplit"] = resultSumMapping; // Tính tổng Mapping Info
                                            }
                                        })
                                    }
                                }
                                //<===================>SET QuantitySplit và Tính tổng Cho lưới Mapping<===================>
                            }}
                            allowColumnResizing={true}
                        >
                            {/*======= CỘT DỌC DELIVERY==========*/}
                            <Column
                                minWidth={400}
                                allowEditing={false}
                                caption={Config.lang("ERP_Chi_tiet_can_phan_bo")}
                                cellRender={this.cellRenderDetailLeft} />
                            {/*======= CỘT DỌC DELIVERY==========*/}


                            {/*======= CỘT NGANG MAPPING==========*/}
                            {/* 2 Thằng này ta nghĩ nó nằm ở ngoài lưới */}

                            {dataGridMappingInfo && dataGridMappingInfo.map((item, idx) => {
                                const dataFieldName = "QuantitySplit" + item.MappingNo;
                                return (
                                    <Column
                                        key={idx}
                                        width={180}
                                        allowEditing={true}
                                        dataType={"number"}
                                        alignment={"right"}
                                        dataField={dataFieldName}
                                        headerCellRender={e => this.renderHeaderColumns(e, item)}
                                        cellRender={this.renderNumberFormat}
                                        editCellRender={(e) => {
                                            return (
                                                <NumberFormat
                                                    className={classes.numberFormatInput}
                                                    decimalScale={0}
                                                    thousandSeparator={true}
                                                    isNumericString={true}
                                                    value={e.value}
                                                    onValueChange={(c) => {
                                                        e.setValue(_.toNumber(c.value))
                                                    }}
                                                />
                                            )
                                        }}
                                    />
                                )
                            })}
                            {/*======= CỘT NGANG MAPPING==========*/}
                            <Column allowEditing={false} />
                            {/* Chỉ dùng để set đúng width của column */}
                        </GridContainer>
                    </Modal.Content>
                </Modal>
            </div >
        );
    }
}

W06F0007PopupAllocateDelivery.propTypes = {
    keyExpr: PropTypes.string,
    refW06F0007: PropTypes.any,
    dataGridDelivery: PropTypes.array,
    dataGridPacking: PropTypes.array,
    dataGridMappingInfo: PropTypes.array,
};
export default compose(
    connect((state) => ({}),
        dispatch => ({
            W06F0007Actions: bindActionCreators(W06F0007Actions, dispatch)
        })), withStyles(styles, { withTheme: true }))(W06F0007PopupAllocateDelivery);


