import React, { Component } from 'react';
import { LoadPanel } from 'devextreme-react/load-panel';
import ActionToolbar from "../../../common/toolbar/action-toolbar";
import ButtonGeneral from "../../../common/button/button-general";
import Config from '../../../../config/index';
import './W06F0004.scss';
import { FormGroup } from "react-bootstrap";
import { TextField } from "../../../common/form-material";
import { FormLabel as Label } from "@material-ui/core";
import GridContainer from "../../../grid-container/grid-container";
import { Column } from "devextreme-react/data-grid";
import { browserHistory } from 'react-router';
import { Combo } from "../../../common/form-material";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as W06F0004Actions from '../../../../redux/W0X/W06F0004/W06F0004_actions';
import * as GeneralActions from '../../../../redux/general/general_actions';
import Filter from "../../../filter/filter";
import moment from 'moment';
import withStyles from "@material-ui/core/styles/withStyles";
import GridActionBar from '../../../grid-container/grid-actionbar';
import IconButton from "@material-ui/core/IconButton";
import VisibilityIcon from "@material-ui/icons/Visibility";
import ButtonExportExcel from '../../../common/button/button-export-excel';
import _ from "lodash";
const styles = {
    span: {
        minHeight: 100,
        '& label': {
            width: '150px'
        },
        '& span': {
            paddingLeft: '10px',
            width: '350px',
            whiteSpace: 'pre-wrap'
        }
    },
    span2: {
        minHeight: 100,
        '& label': {
            width: '150px'
        },
        '& span': {
            paddingLeft: '10px',
            minWidth: '100px',
        }
    },
    columnActions: {
        height: '37px !important',
    },
};
class W06F0004 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            isPermission: 0,
            isPermissionView: 0,
            dataGrid: [],
            filterGridValue: [],
            totalDataGrid: 0,
            selectedRowKeys: [],
            dataCboListObjects: {
                rows: [],
                total: 0
            },
            dataCboListObjectsCC: {
                rows: [],
                total: 0
            },
            dataCboListInventoris: {
                rows: [],
                total: 0
            },
            loadingCboListObjects: false,
            loadingCboListObjectsCC: false,
            loadingCboListInventories: false,
            ContractNoSO: '',
            VoucherNo: '',
            LotNo: '',
            Buyer: '',
            ContractNoPO: '',
            Supplier: '',
            InventoryID: '',
            StrSearch: '',
            limit: 20,
            skip: 0,

            ReportID: '',
            formLoading: false,
            StoreParams: ''

        }
        this.filterCboListObject = {
            strSearch: "",
            ObjectTypeID: "",
            skip: 0,
            limit: 10
        };
        this.filterCboListObjectCC = {
            strSearch: "",
            ObjectTypeID: "CC",
            skip: 0,
            limit: 10
        };
        this.filterCboListInventories = {
            strSearch: "",
            ContractID: "",
            skip: 0,
            limit: 10
        };
        this.filterPage = {
            limit: 5,
            skip: 0
        };
        this.changePage = false;
        this.tmpSelectedRowKeys = [];
        this.tmpSelectedRowData = [];
    }
    componentDidMount() {
        this.loadPermission();
        this.loadCboListObjects();
        this.loadCboListObjectsCC();
        this.getCboListInventories();
    }

    loadCboReport = () => {
        let params = {
            ModuleID: '06',
            ReportTypeID: 'W06F0004'
        }
        this.props.GeneralActions.loadCboReport(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            this.setState({ cboReportPopupData: data });
        });
    }
    loadPermission = async () => {
        await this.props.GeneralActions.getPermission(["W06F0004", "W05F0006"], (isPer) => {
            if (!_.isEmpty(isPer)) {
                const indexW06F0004 = isPer.findIndex(item => item.ScreenID === "W06F0004");
                const indexW05F0006 = isPer.findIndex(item => item.ScreenID === "W05F0006");
                this.setState({
                    isPermission: isPer[indexW06F0004].Permission,
                    isPermissionView: isPer[indexW05F0006].Permission
                });
            }
        });
    };
    setLoading = (key, status = false) => {
        if (!key) return;
        this.setState({ ['loading' + key]: status });
    };
    loadCboListObjects = (isReset) => {
        const { skip, limit, ObjectTypeID, strSearch } = this.filterCboListObject;
        const params = {
            ObjectTypeID: ObjectTypeID ? ObjectTypeID : "KH",
            StrSearch: strSearch ? strSearch : "",
            skip: skip,
            limit: limit
        };
        this.setLoading('CboListObjects', true);
        this.props.W06F0004Actions.getListObjects(params, (error, data) => {
            this.setLoading('CboListObjects', false);
            if (error) {
                let message = error.message || Config.lang('ERP_Loi_chua_xac_dinh');
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboListObjects } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboListObjects: {
                        rows: isReset ? rows : dataCboListObjects.rows.concat(rows),
                        total: total
                    }
                });
            }
        });
    };
    loadCboListObjectsCC = (isReset) => {
        const { skip, limit, ObjectTypeID, strSearch } = this.filterCboListObjectCC;
        const params = {
            ObjectTypeID: ObjectTypeID ? ObjectTypeID : "CC",
            StrSearch: strSearch ? strSearch : "",
            skip: skip,
            limit: limit
        };
        this.setLoading('CboListObjectsCC', true);
        this.props.W06F0004Actions.getListObjects(params, (error, data) => {
            this.setLoading('CboListObjectsCC', false);
            if (error) {
                let message = error.message || Config.lang('ERP_Loi_chua_xac_dinh');
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboListObjectsCC } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboListObjectsCC: {
                        rows: isReset ? rows : dataCboListObjectsCC.rows.concat(rows),
                        total: total
                    }
                });
            }
        });
    };
    getCboListInventories = (isReset) => {
        const { skip, limit, ContractID, strSearch } = this.filterCboListInventories;
        const params = {
            ContractID: ContractID ? ContractID : "",
            StrSearch: strSearch ? strSearch : "",
            skip: skip,
            limit: limit
        };
        this.setLoading('CboListInventories', true);
        this.props.W06F0004Actions.getInventories(params, (error, data) => {
            this.setLoading('CboListInventories', false);
            if (error) {
                let message = error.message || Config.lang('ERP_Loi_chua_xac_dinh');
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                const { dataCboListInventoris } = this.state;
                const rows = data && data.rows ? data.rows : data;
                const total = data && data.total ? data.total : data.length;
                this.setState({
                    dataCboListInventoris: {
                        rows: isReset ? rows : dataCboListInventoris.rows.concat(rows),
                        total: total
                    }
                });
            }
        });
    };
    onTextValueChanged = (key, e) => {
        switch (key) {
            case "StrSearch":
                this.setState({ StrSearch: e.target.value });
                break;
            case "ContractNoSO":
                this.setState({ ContractNoSO: e.target.value });
                break;
            case "ContractNoPO":
                this.setState({ ContractNoPO: e.target.value });
                break;
            case "VoucherNo":
                this.setState({ VoucherNo: e.target.value });
                break;
            case "LotNo":
                this.setState({ LotNo: e.target.value });
                break;
            case "Buyer":
                this.setState({ Buyer: e.value !== null ? e.value.ObjectID : '' });
                break;
            case "Supplier":
                this.setState({ Supplier: e.value !== null ? e.value.ObjectID : '' });
                break;
            case "InventoryID":
                this.setState({ InventoryID: e.value !== null ? e.value.InventoryID : '' });
                break;
            case "ReportID":
                this.setState({ ReportID: e.value });
                break;
            default:
                break;
        }
    }
    renderFilters = () => {
        return (
            <Filter
                onTextChanged={(e) => this.onTextValueChanged('StrSearch', e)}
                placeholder={Config.lang("ERP_Noi_dung_can_tim_kiem")}
                renderFilter={() => {
                    return (
                        <>

                        </>
                    );
                }}
            />
        );
    };
    getMapping = (params) => {
        this.props.W06F0004Actions.getMapping(params, (errors, data) => {
            if (errors) {
                let message = errors.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show('INFO', message);
                return false;
            }
            this.setState({
                dataGrid: data.rows,
                totalDataGrid: data.total,
            });
        });
    }
    onSearch = () => {
        const { ContractNoSO, VoucherNo, LotNo, Buyer, ContractNoPO, Supplier, InventoryID, StrSearch } = this.state
        let filter = {
            ContractNoSO: ContractNoSO,
            VoucherNo: VoucherNo,
            LotNo: LotNo,
            Buyer: Buyer,
            ContractNoPO: ContractNoPO,
            Supplier: Supplier,
            InventoryID: InventoryID,
            StrSearch: StrSearch,
            limit: 5,
            skip: 0
        }
        this.getMapping(filter);
    }
    onChangePage = (page) => {
        const { ContractNoSO, VoucherNo, LotNo, Buyer, ContractNoPO, Supplier, InventoryID, StrSearch } = this.state
        this.filterPage.skip = page * this.filterPage.limit;
        let filter = {
            ContractNoSO: ContractNoSO,
            VoucherNo: VoucherNo,
            LotNo: LotNo,
            Buyer: Buyer,
            ContractNoPO: ContractNoPO,
            Supplier: Supplier,
            InventoryID: InventoryID,
            StrSearch: StrSearch,
            limit: this.filterPage.limit,
            skip: this.filterPage.skip
        }
        this.getMapping(filter);
        this.changePage = true;
    };

    onChangePerPage = (perPage) => {
        const { ContractNoSO, VoucherNo, LotNo, Buyer, ContractNoPO, Supplier, InventoryID, StrSearch } = this.state
        this.filterPage.skip = 0;
        this.filterPage.limit = perPage;
        let filter = {
            ContractNoSO: ContractNoSO,
            VoucherNo: VoucherNo,
            LotNo: LotNo,
            Buyer: Buyer,
            ContractNoPO: ContractNoPO,
            Supplier: Supplier,
            InventoryID: InventoryID,
            StrSearch: StrSearch,
            limit: this.filterPage.limit,
            skip: this.filterPage.skip
        }
        this.getMapping(filter);
        this.changePage = true;
    };
    cellRenderMapping = (e) => {
        const { classes } = this.props;
        return (
            <div className={classes.span2}>
                <div className="center-row-W06F0004">
                    <Label>{Config.lang('ERP_Ma_mapping')}</Label>
                    <span >{e.data.MappingID}</span>
                </div>
                <div className="center-row-W06F0004">
                    <Label>{Config.lang('ERP_So_mapping')}</Label>
                    <span>{e.data.MappingNo}</span>
                </div>
                <div className="center-row-W06F0004">
                    <Label>{Config.lang('ERP_Thoi_gian_dong_hang')}</Label>
                    <span>{moment(e.data.StartDate).format('DD/MM/YYYY')} - {moment(e.data.EndDate).format('DD/MM/YYYY')}</span>
                </div>
            </div>
        )
    }
    cellRenderPurchase = (e) => {
        const { classes } = this.props;
        return (
            <div className={classes.span2}>
                <div className="center-row-W06F0004">
                    <Label>{Config.lang('ERP_So_hop_dong_mua')}</Label>
                    <span >{e.data.ContractNoPO}</span>
                </div>
                <div className="center-row-W06F0004">
                    <Label>{Config.lang('ERP_Nha_cung_cap')}</Label>
                    <span>{e.data.SupplierName}</span>
                </div>
                <div className="center-row-W06F0004">
                    <Label>{Config.lang('ERP_Hang_hoa')}</Label>
                    <span>{e.data.InventoryIDPO} - {e.data.InventoryNamePO}</span>
                </div>
            </div>
        )
    }
    cellRenderSales = (e) => {
        const { classes } = this.props;
        return (
            <div className={classes.span}>
                <div className="center-row-W06F0004">
                    <Label>{Config.lang('ERP_So_hop_dong_ban')}</Label>
                    <span >{e.data.ContractNoSO}</span>
                </div>
                <div className="center-row-W06F0004">
                    <Label>{Config.lang('ERP_Khach_hang')}</Label>
                    <span>{e.data.BuyerName}</span>
                </div>
                <div className="center-row-W06F0004">
                    <Label>{Config.lang('ERP_Hang_hoa')}</Label>
                    <span>{e.data.InventoryIDSO} - {e.data.InventoryNameSO}</span>
                </div>
                <div className="center-row-W06F0004">
                    <Label>{Config.lang('ERP_Dot_trien_khai')} - Lot</Label>
                    <span>{e.data.VoucherNo} - {e.data.LotNo}</span>
                </div>
            </div>
        )
    }
    cellRenderPacking = (e) => {
        const { classes } = this.props;
        return (
            <div className={classes.span}>
                <div className="center-row-W06F0004">
                    <Label>{Config.lang('ERP_Quy_cach_dong_goi')}</Label>
                    <span >{e.data.PackingMethodName}</span>
                </div>
                <div className="center-row-W06F0004">
                    <Label>{Config.lang('ERP_Loai_bao_dong_goi')}</Label>
                    <span>{e.data.PackingTypeName}</span>
                </div>
                <div className="center-row-W06F0004">
                    <Label>{Config.lang('ERP_Nhan_mac')}</Label>
                    <span>{e.data.MarkingName}</span>
                </div>
                <div className="center-row-W06F0004">
                    <Label>{Config.lang('ERP_Thong_tin_bao_bi')}</Label>
                    <span>{e.data.PackNote}</span>
                </div>
            </div>
        )
    }
    cellRenderQuantity = (e) => {
        const { classes } = this.props;
        return (
            <div className={classes.span2}>
                <div className="center-row-W06F0004">
                    <Label style={{ width: 230 }}>{Config.lang('ERP_So_luong_bao_yeu_cau')}</Label>
                    <span >{e.data.QuantityPack}</span>
                </div>
                <div className="center-row-W06F0004">
                    <Label style={{ width: 230 }}>{Config.lang('ERP_So_luong_yeu_cau_mapping')}</Label>
                    <span>{e.data.Quantity}</span>
                </div>
                <div className="center-row-W06F0004">
                    <Label style={{ width: 230 }}>{Config.lang('ERP_So_luong_thuc_hien_mapping')}</Label>
                    <span>{e.data.QuantityActual}</span>
                </div>
                <div className="center-row-W06F0004">
                    <Label style={{ width: 230 }}>{Config.lang('ERP_So_luong_con_lai')}</Label>
                    <span>{e.data.QuantitRemain}</span>
                </div>
            </div>
        )
    }
    setSelected = async (e, cb) => {
        const currentSelectedRowKeys = e.selectedRowsData;
        const currentDeselectedRowKeys = e.currentDeselectedRowKeys;
        if (currentDeselectedRowKeys.length > 0) {
            this.tmpSelectedRowData = this.tmpSelectedRowData.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e.MappingItemID) < 0;
            });
            this.tmpSelectedRowKeys = this.tmpSelectedRowKeys.filter((e) => {
                return currentDeselectedRowKeys.indexOf(e) < 0;
            });
        } else if (currentSelectedRowKeys.length > 0) {
            currentSelectedRowKeys.forEach((val) => {
                if (this.tmpSelectedRowData.indexOf(val) < 0) {
                    this.tmpSelectedRowData.push(val);
                    this.tmpSelectedRowKeys.push(val.MappingItemID);
                }
            });
        }
        if (cb) cb();
    };
    onSelectionChanged = (e) => {
        const { selectedRowsData, selectedRowKeys } = e;
        if (this.changePage) {
            this.setState({ selectedRowKeys: this.tmpSelectedRowKeys });
            this.changePage = false;
        } else {
            let filterValue = [
                ["ContractIDP", "contains", selectedRowsData[0]?.ContractIDP],
                ["ContractIDS", "contains", selectedRowsData[0]?.ContractIDS],
                ["QuotationID", "contains", selectedRowsData[0]?.QuotationID],
                ["LotNo", "contains", selectedRowsData[0]?.LotNo],
                ["InventoryIDPO", "contains", selectedRowsData[0]?.InventoryIDPO]
            ];
            this.customReportFileName = (`TBGH ${selectedRowsData[0]?.ContractNoPO} ${selectedRowsData[0]?.SupplierShortName}`).replace(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/g, ' ');
            this.setSelected(e, () => {
                this.setState({
                    selectedRowKeys: e.selectedRowKeys,
                    filterGridValue: selectedRowKeys.length ? filterValue : []
                }, () => {
                    let params = [];
                    selectedRowsData.map(item => {
                        params.push([
                            { id: "MappingID", type: "VarChar", value: item.MappingID },
                            { id: "MappingItemID", type: "VarChar", value: item.MappingItemID }
                        ]);
                        return params;
                    });
                    const storeParams = [
                        { id: "arrayParam", type: "VarChar", value: params },
                        { id: "ReportID", type: "VarChar", value: this.state.ReportID },
                        { id: "DivisionID", type: "VarChar", value: Config.getDivisionID() || "" },
                        { id: "Language", type: "Varchar", value: Config.language || "84" },
                        { id: "UserID", type: "VarChar", value: Config.profile.UserID || "" },
                    ];
                    this.setState({ StoreParams: storeParams });
                });
            });

        }
    };
    renderButtonAction = e => {
        let params = {
            mode: 'view',
            MappingID: e.data.MappingID,
        };
        return (
            <GridActionBar >
                <IconButton
                    disabled={false}
                    style={{ padding: 4 }}
                    onClick={() => browserHistory.push({
                        pathname: Config.getRootPath() + 'W05F0007',
                        state: params
                    })}
                    aria-label="View"
                    color={'default'}
                >
                    <VisibilityIcon />
                </IconButton>
            </GridActionBar>
        )
    };
    render() {
        const { dataCboListObjects, loadingCboListObjects, dataCboListInventoris, loadingCboListInventories,
            ContractNoSO, ContractNoPO, VoucherNo, LotNo, dataCboListObjectsCC, loadingCboListObjectsCC,
            Buyer, InventoryID, Supplier, loading, dataGrid, totalDataGrid, selectedRowKeys, filterGridValue, isPermissionView,
            formLoading, StoreParams } = this.state;
        const { classes } = this.props;
        return (
            <div className="W06F0004">
                <LoadPanel
                    position={{ my: 'center', of: '#W06F0004' }}
                    visible={loading}
                    showIndicator={true}
                    shading={false}
                    showPane={true}
                />
                <FormGroup className="W06F004-ActionToolbar" style={{ marginBottom: Config.isMobile ? 50 : 15 }}>
                    <ActionToolbar allwaysTop title={Config.lang("ERP_Xuat_thong_bao_giao_hang")} onBack={() => browserHistory.goBack()} >
                        <div className="text-row center-row-W06F0004" style={{ width: '100%', }}>
                            {selectedRowKeys.length > 0 &&
                                <ButtonExportExcel
                                    name={Config.lang("ERP_Xuat_du_lieu")}
                                    size={"large"}
                                    color={"primary"}
                                    variant={"outlined"}
                                    style={{ textTransform: "uppercase" }}
                                    disabled={formLoading}
                                    ReportTypeID={"W06F0004"}
                                    ModuleID={"06"}
                                    StoreParams={StoreParams}
                                    customReportFileName={this.customReportFileName}
                                />
                            }

                        </div>
                    </ActionToolbar>
                </FormGroup>
                {/* <div className={"hidden"}>{this.renderFilters()}</div> */}
                <div className="W06F0004-content">
                    <FormGroup >
                        <div className="col-sm-12 col-md-3">
                            <TextField
                                label={Config.lang('ERP_So_hop_dong_ban')}
                                variant={"standard"}
                                margin={"normal"}
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={String(ContractNoSO)}
                                onChange={(e) => this.onTextValueChanged('ContractNoSO', e)}
                            />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <TextField
                                label={Config.lang('ERP_Dot_trien_khai')}
                                variant={"standard"}
                                margin={"normal"}
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={String(VoucherNo)}
                                onChange={(e) => this.onTextValueChanged('VoucherNo', e)}
                            />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <TextField
                                label={Config.lang('ERP_Ma_lot')}
                                variant={"standard"}
                                margin={"normal"}
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={String(LotNo)}
                                onChange={(e) => this.onTextValueChanged('LotNo', e)}
                            />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <Combo.LoadMore
                                dataSource={dataCboListObjects}
                                skip={this.filterCboListObject.skip}
                                limit={this.filterCboListObject.limit}
                                displayExpr={'ObjectNameU'}
                                keyExpr={'ObjectID'}
                                valueExpr={'ObjectID'}
                                value={Buyer}
                                loading={loadingCboListObjects}
                                shrink={true}
                                margin={'normal'}
                                stylingMode={'underlined'}
                                label={Config.lang('ERP_Khach_hang')}
                                showClearButton={true}
                                onValueChanged={(e) => this.onTextValueChanged('Buyer', e)}
                                onInputChanged={(e) => {
                                    this.filterCboListObject.strSearch = e.target.value;
                                    this.filterCboListObject.skip = 0;
                                    this.loadCboListObjects(true);
                                }}
                                itemRender={(e) => {
                                    const { data } = e;
                                    if (!data) return null;
                                    return data.ObjectID + ' - ' + data.ObjectNameU;
                                }}
                                onLoadMore={(e) => {
                                    this.filterCboListObject.skip = e.skip;
                                    this.filterCboListObject.limit = e.limit;
                                    this.loadCboListObjects();
                                }}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup >
                        <div className="col-sm-12 col-md-3">
                            <TextField
                                label={Config.lang('ERP_So_hop_dong_mua')}
                                variant={"standard"}
                                margin={"normal"}
                                fullWidth={true}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={String(ContractNoPO)}
                                onChange={(e) => this.onTextValueChanged('ContractNoPO', e)}
                            />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <Combo.LoadMore
                                dataSource={dataCboListObjectsCC}
                                skip={this.filterCboListObjectCC.skip}
                                limit={this.filterCboListObjectCC.limit}
                                displayExpr={'ObjectNameU'}
                                keyExpr={'ObjectID'}
                                valueExpr={'ObjectID'}
                                value={Supplier}
                                loading={loadingCboListObjectsCC}
                                shrink={true}
                                margin={'normal'}
                                stylingMode={'underlined'}
                                label={Config.lang('ERP_Nha_cung_cap')}
                                showClearButton={true}
                                onValueChanged={(e) => this.onTextValueChanged('Supplier', e)}
                                onInputChanged={(e) => {
                                    this.filterCboListObjectCC.strSearch = e.target.value;
                                    this.filterCboListObjectCC.skip = 0;
                                    this.loadCboListObjectsCC(true);
                                }}
                                itemRender={(e) => {
                                    const { data } = e;
                                    if (!data) return null;
                                    return data.ObjectID + ' - ' + data.ObjectNameU;
                                }}
                                onLoadMore={(e) => {
                                    this.filterCboListObjectCC.skip = e.skip;
                                    this.filterCboListObjectCC.limit = e.limit;
                                    this.loadCboListObjectsCC();
                                }}
                            />
                        </div>
                        <div className="col-sm-12 col-md-3">
                            <Combo.LoadMore
                                dataSource={dataCboListInventoris}
                                skip={this.filterCboListInventories.skip}
                                limit={this.filterCboListInventories.limit}
                                displayExpr={'InventoryNameU'}
                                keyExpr={'InventoryID'}
                                valueExpr={'InventoryID'}
                                value={InventoryID}
                                loading={loadingCboListInventories}
                                shrink={true}
                                margin={'normal'}
                                stylingMode={'underlined'}
                                label={Config.lang('ERP_Hang_hoa')}
                                showClearButton={true}
                                onValueChanged={(e) => this.onTextValueChanged('InventoryID', e)}
                                onInputChanged={(e) => {
                                    this.filterCboListInventories.strSearch = e.target.value;
                                    this.filterCboListInventories.skip = 0;
                                    this.getCboListInventories(true);
                                }}
                                itemRender={(e) => {
                                    const { data } = e;
                                    if (!data) return null;
                                    return data.InventoryID + ' - ' + data.InventoryNameU;
                                }}
                                onLoadMore={(e) => {
                                    this.filterCboListInventories.skip = e.skip;
                                    this.filterCboListInventories.limit = e.limit;
                                    this.getCboListInventories();
                                }}
                            />
                        </div>
                        <div className="col-sm-12 col-md-3" style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-end', height: 60 }}>
                            <ButtonGeneral
                                name={Config.lang('ERP_Tim_kiem')}
                                typeButton={'default'}
                                onClick={this.onSearch}
                            />
                        </div>
                    </FormGroup>
                    <FormGroup>
                        <Label style={{ color: '#0095FF', fontWeight: 'bold' }} className="cursor-pointer text-uppercase user-select" >{Config.lang('ERP_Thong_tin_mapping')}</Label>
                    </FormGroup>
                    <GridContainer
                        reference={ref => this.Grid = ref}
                        dataSource={dataGrid}
                        totalItems={totalDataGrid}
                        itemPerPage={this.filterPage.limit}
                        skipPerPage={this.filterPage.skip}
                        columnAutoWidth={true}
                        listPerPage={[5, 15, 30, 45, 60]}
                        keyExpr={'MappingItemID'}
                        typeShort={true}
                        height={600}
                        showColumnLines={false}
                        showRowLines={false}
                        onRowClick={this.onRowClick}
                        allowCellSelection={true}
                        rowAlternationEnabled={true}
                        hoverStateEnabled={true}
                        sorting={{
                            mode: "none"
                        }}
                        selection={{
                            allowSelectAll: true,
                            mode: "multiple",
                            selectAllMode: "allPages",
                            showCheckBoxesMode: "always"
                        }}
                        filterPanel={{
                            visible: false,
                        }}
                        filterBuilderPopup={{
                            onInitialized: (e) => {
                                e.component.dispose();
                                e.element.remove();
                            }
                        }}
                        filterValue={filterGridValue}
                        onSelectionChanged={this.onSelectionChanged}
                        selectedRowKey={selectedRowKeys}
                        onChangePage={this.onChangePage}
                        onChangePerPage={this.onChangePerPage}
                    >
                        <Column caption={Config.lang("ERP_Mapping")} minWidth={200} cellRender={this.cellRenderMapping} />
                        <Column caption={Config.lang("ERP_Hop_dong_mua")} minWidth={200} cellRender={this.cellRenderPurchase} />
                        <Column caption={Config.lang("ERP_Hop_dong_ban")} minWidth={200} cellRender={this.cellRenderSales} />
                        <Column caption={Config.lang("ERP_Dong_goi")} minWidth={200} cellRender={this.cellRenderPacking} />
                        <Column caption={Config.lang("ERP_So_luong")} minWidth={200} cellRender={this.cellRenderQuantity} />
                        <Column dataField="ContractIDP" visible={false} />
                        <Column dataField="ContractIDS" visible={false} />
                        <Column dataField="QuotationID" visible={false} />
                        <Column dataField="LotNo" visible={false} />
                        <Column dataField="InventoryIDPO" visible={false} />
                        <Column
                            fixed={true}
                            fixedPosition={"right"}
                            alignment="center"
                            cssClass={classes.columnActions}
                            cellRender={this.renderButtonAction}
                            visible={isPermissionView > 0 && !Config.isMobile}
                        />
                    </GridContainer>
                </div>
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        W06F0004Actions: bindActionCreators(W06F0004Actions, dispatch),
        GeneralActions: bindActionCreators(GeneralActions, dispatch)
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(W06F0004));