import { FormLabel as Label, Radio } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import { Column } from "devextreme-react/data-grid";
import PropTypes from "prop-types";
import React, { Component } from 'react';
import Config from '../../../../config/index';
import GridContainer from "../../../grid-container/grid-container";

const styles = {
    dataGridHover: {
        left: '0 !important',
        '& button': {
            marginBottom: '0 !important',
            height: 10,
            width: 10,
        }
    },
    columnGrid: {
        height: '37px !important',
        minHeight: '37px !important',
    },
    radioButton: {
        width: '61px !important'
    }
};

class W06F0007SO extends Component {

    onChangeSO = (data) => {
        const { dataGridSO } = this.props;
        dataGridSO.map(item => {
            if (item.LotNo === data.LotNo) {
                item.isUsed = 1;
            } else {
                item.isUsed = 0;
            }
            return item;
        });
        this.props.refW06F0007.onCheckSO();
        this.setState({ loading: false });
    }

    cellRenderRadio = (e) => {
        return (
            <Radio style={{ width: 21, height: 21 }}
                size={"small"}
                color={"primary"}
                onChange={() => this.onChangeSO(e.data)}
                value={e.data.isUsed}
                checked={e.data.isUsed === 1} />
        )
    }

    render() {
        const { dataGridSO, isView, classes, refW06F0007, btnActionStatus } = this.props;
        return (
            <div style={{ marginBottom: 30 }}>
                <Label style={{ fontWeight: 500, color: 'black', fontSize: 16, }} className={"cursor-pointer pdb10 text-title"} >{Config.lang("ERP_Lot_yeu_cau_tach")}</Label>
                <React.Fragment>
                    <GridContainer
                        reference={ref => this.gridTab0 = ref}
                        // disabled={btnActionStatus}
                        dataSource={dataGridSO}
                        columnAutoWidth={true}
                        listPerPage={[5, 10, 15, 30, 45, 60]}
                        itemPerPage={5}
                        typePaging={"normal"}
                        keyExpr={"LotNo"}
                        height={300}
                        typeShort={true}
                        pagerFullScreen={false}
                        showColumnLines={false}
                        hoverStateEnabled={true}
                        sorting={{
                            mode: "none"
                        }}
                    >
                        {!btnActionStatus &&
                            <Column
                                caption={Config.lang("ERP_Chon")}
                                alignment={"center"}
                                dataField={"isUsed"}
                                minWidth={50}
                                cellRender={this.cellRenderRadio}
                                visible={!isView}
                                cssClass={`${classes.columnGrid} ${classes.radioButton}`}
                            />
                        }
                        <Column
                            caption={Config.lang("ERP_So_lot")}
                            dataField={"LotNo"}
                            allowEditing={false}
                            minWidth={150}
                        />
                        <Column
                            caption={Config.lang("ERP_Ma_hang")}
                            dataField={"InventoryID"}
                            allowEditing={false}
                            minWidth={150}
                        />
                        <Column
                            caption={Config.lang("ERP_Ten_hang")}
                            dataField={"InventoryNameU"}
                            allowEditing={false}
                            minWidth={200}
                        />
                        <Column
                            caption={Config.lang("ERP_So_luong_lot")}
                            dataField={"SUMQuantity"}
                            allowEditing={false}
                            minWidth={150}
                            cellRender={refW06F0007.renderNumberFormat}
                        />
                        <Column
                            caption={Config.lang("ERP_So_luong_da_mapping")}
                            dataField={"MAPQuantity"}
                            allowEditing={false}
                            minWidth={200}
                            cellRender={refW06F0007.renderNumberFormat}
                        />
                        <Column
                            caption={Config.lang("ERP_So_luong_con_lai")}
                            dataField={"Quantity"}
                            allowEditing={false}
                            minWidth={150}
                            cellRender={refW06F0007.renderNumberFormat}
                        />
                        <Column
                            caption={Config.lang("ERP_So_hop_dong")}
                            dataField={"ContractNo"}
                            allowEditing={false}
                            minWidth={150}
                        />
                        <Column
                            caption={Config.lang("ERP_Khach_hang")}
                            dataField={"BuyerName"}
                            allowEditing={false}
                            minWidth={150}
                        />
                    </GridContainer>
                </React.Fragment>
            </div>
        );
    }
}

W06F0007SO.propTypes = {
    isView: PropTypes.bool,
    btnActionStatus: PropTypes.bool,
    dataGridSO: PropTypes.array,
    refW06F0007: PropTypes.any,
};
export default withStyles(styles)(W06F0007SO);