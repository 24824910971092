/**
 * @copyright 2020 @ DigiNet
 * @author TAIAU
 * @create 07/13/2020
 * @Example
 */
import _ from "lodash";
import moment from 'moment';
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Col, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as W06F0002Actions from "../../../../redux/W0X/W06F0002/W06F0002_actions";
import { Combo, TextField } from "../../../common/form-material";
import DateBoxPicker from "../../../common/form-material/date-box";
import NumberFormat from 'react-number-format';

class W06F0002GeneralInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataCboEmployees: {
        rows: [],
        total: 0
      },
      loadingCboEmployee: false,
      defaultEmployeeID: null,
    };
    this.filterCboEmployees = {
      strSearch: "",
      skip: 0,
      limit: 10
    };
  }

  componentDidMount = () => {
    const { mode, onChange, getCboContractType } = this.props;
    if (mode === "addFinal" && onChange) {
      const userID = Config.profile.UserID || "";
      const user = Config.getUser({ UserID: userID });
      if (user) {
        const employee = {
          EmployeeID: user.ObjectID || "",
          EmployeeName: user.ObjectNameU || "",
          UserPictureURL: user.UserPictureURL
        };
        onChange(employee.EmployeeID, "EmployeeID");
        onChange(employee, "Employee");
        this.setState({ defaultEmployeeID: employee })
      }
    }
    if (_.isEmpty(getCboContractType)) { // Không có load api
      this.loadCboContractType()
    } else { // Có thì load ContractNo
      this.handleLoadContractNo(getCboContractType);
    }
    this.loadCboEmployees();
  };

  handleLoadContractNo = (cboContractType) => {
    const { formData, mode, onChange } = this.props;
    if ((mode === "copy" || mode === "addFinal") && !_.isEmpty(formData.ContractTypeID)) {
      const dataContractType = cboContractType.find(item => item.ContractTypeID === formData.ContractTypeID);
      if (dataContractType && dataContractType.Auto === 0) {
        onChange("", "ContractNo")
      } else if (dataContractType && dataContractType.Auto === 1) {
        this.loadContractNo(formData.ContractTypeID);
      }
    }
  }

  loadCboContractType = () => {
    const { onLoading } = this.props;
    onLoading(true);
    this.props.w06f0002Actions.loadCboContractType((errors, data) => {
      onLoading(false);
      if (errors) {
        let message = errors.message || Config.lang("ERP_Loi_chua_xac_dinh");
        Config.popup.show("INFO", message);
        return false;
      }
      if (data) {
        this.handleLoadContractNo(data);
      }
    });
  };

  loadContractNo = (value) => {
    const { onLoading } = this.props;
    if (value) {
      const params = {
        ContractTypeID: value,
      };
      onLoading(true);
      this.props.w06f0002Actions.getContractNo(params, (errors, data) => {
        onLoading(false);
        if (errors) {
          let message = errors.message || Config.lang("ERP_Loi_chua_xac_dinh");
          Config.popup.show("INFO", message);
          return false;
        }
        if (data) {
          const { onChange, onChangeCbo } = this.props;
          const { ContractNo } = data;
          onChange(ContractNo, "ContractNo");
          onChangeCbo(ContractNo, "ContractNo");
        }
      });
    }
  };

  loadCboEmployees = (isReset) => {
    const param = {
      StrSearch: this.filterCboEmployees.strSearch,
      skip: this.filterCboEmployees.skip,
      limit: this.filterCboEmployees.limit
    };
    this.setState({ loading: true, loadingCboEmployee: true });
    this.props.w06f0002Actions.loadCboEmployees(param, (error, data) => {
      this.setState({ loading: false, loadingCboEmployee: false });
      if (error) {
        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
        Config.popup.show("INFO", message);
        return false;
      }
      if (data) {
        const { dataCboEmployees } = this.state;
        const d = data.rows ? data.rows : data;
        const total = data.total ? data.total : 0;
        this.setState({
          dataCboEmployees: {
            rows: isReset ? d : dataCboEmployees.rows.concat(d),
            total: total
          }
        });
      }
    });
  };

  numberFormat = (number, limitNumber = 0) => {
    const formatter = new Intl.NumberFormat("en-US", {
      minimumFractionDigits: limitNumber || 0,
      maximumFractionDigits: limitNumber || 0,
    });
    const _number = formatter.format(String(number));
    return !_number || _number === "NaN" ? 0 : _number;
  };

  loadDecimalQuanlity = (CurrencyID) => {
    const { onLoading } = this.props;
    const param = { CurrencyID };
    onLoading(true)
    this.props.w06f0002Actions.getDeicimalQuantity(param, (error, data) => {
      onLoading(false);
      if (error) {
        let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
        Config.popup.show("INFO", message);
        return false;
      }
    });
  };

  handleChange = async (fieldName, e) => {
    const { onChange, getCboCurrency } = this.props;
    const { value, selectedItem } = e;
    switch (fieldName) {
      case "EmployeeID": {
        const valueEmployee = !_.isNull(value) ? value : "";
        await onChange(valueEmployee.EmployeeID, "EmployeeID");
        await onChange(valueEmployee, "Employee");
        break;
      }
      case "ContractTypeID": {
        await onChange(selectedItem.ContractTypeID, fieldName);
        if (selectedItem.Auto === 1) {
          selectedItem && this.loadContractNo(selectedItem.ContractTypeID);
        } else { // can typing ContractNo
          await onChange("", "ContractNo");
        }
        break;
      }
      case "CurrencyID": {
        if (!_.isEmpty(getCboCurrency)) {
          const currencyChosen = getCboCurrency.find(item => item.CurrencyID === value);
          if (currencyChosen.ExchangeRate) {
            const { ExchangeRate } = currencyChosen;
            this.loadDecimalQuanlity(e.value);
            await onChange(ExchangeRate, "ExchangeRate");
          }
        }
        await onChange(e, "CurrencyID");
        break;
      }
      default:
        break;
    }
  };

  onChangeCbo(fieldName, e) {
    const { mode } = this.props;
    const valueID = _.get(e, "value", _.get(e, "target.value", e ? e : ""));
    if (mode !== "edit" || _.isNull(valueID)) return;
    const { onChangeCbo, getCboStatus, getCboContractType, getCboCurrency, getCboPaymentMethod } = this.props;
    let valueName = null;
    switch (fieldName) {
      case "EStatus": {
        const dataStatusChosen = getCboStatus.find(item => item.EStatus === valueID);
        valueName = dataStatusChosen.EStatusName;
        break;
      }
      case "ContractTypeID": {
        const dataContractType = getCboContractType.find(item => item.ContractTypeID === valueID.selectedItem.ContractTypeID);
        valueName = dataContractType.VoucherTypeName;
        break;
      }
      case "CurrencyID": {
        const dataCurrencyChosen = getCboCurrency.find(item => item.CurrencyID === valueID);
        valueName = dataCurrencyChosen.CurrencyName;
        break;
      }
      case "EmployeeID": {
        if (!valueID) {
          valueName = "";
        } else {
          if (valueID) {
            valueName = valueID.EmployeeName || ""
          }
        }
        break;
      }
      case "PaymentMethodID": {
        const dataPaymentMethodhosen = getCboPaymentMethod.find(item => item.PaymentMethodID === valueID);
        valueName = dataPaymentMethodhosen.PaymentMethodName;
        break;
      }
      default:
        break;
    }
    if (_.isNull(valueName)) return;
    onChangeCbo(valueName, fieldName);
  }

  render() {
    const { loadingCboEmployee, dataCboEmployees, defaultEmployeeID } = this.state;
    const { formData, onChange, errorForm, loading, getCboContractType, getCboStatus, getCboCurrency, getCboPaymentMethod, mode, classes } = this.props;
    const { Employee, EmployeeID, ContractNo, ContractTypeID, EStatus, CurrencyID, ContractDate, StartDate, EndDate, PaymentMethodID, ExchangeRate,
      ExceedContract
    } = formData;
    const iEmptyContractTypeID = _.isEmpty(ContractTypeID);
    const iEmptyContractNo = _.isEmpty(ContractNo);
    const iEmptyEStatus = _.isEmpty(EStatus);
    const iEmptyCurrencyID = _.isEmpty(CurrencyID);
    const iEmptyExchangeRate = _.isEmpty(_.toString(ExchangeRate));
    const isEmptyStartDate = _.isEmpty(StartDate);
    const iEmptyEndDate = _.isEmpty(EndDate);
    const iEmptyEmployeeID = _.isEmpty(EmployeeID);
    const isEmptyContractDate = _.isEmpty(ContractDate);
    const dataContractType = !_.isEmpty(getCboContractType) && mode !== "edit" ? (getCboContractType.find(item => item.ContractTypeID === formData.ContractTypeID)).Auto !== 0 : true;

    return (
      <div style={{ width: '100%' }}>
        <Row>
          <Col md={12} lg={9}>
            <Row>
              <Col xs={12} sm={6} md={4} lg={4} style={{ height: 70 }}>
                <Combo
                  label={Config.lang("ERP_Loai_hop_dong")}
                  error={iEmptyContractTypeID && _.get(errorForm, "ContractTypeID", false)}
                  dataSource={getCboContractType}
                  displayExpr={"VoucherTypeName"}
                  valueExpr={"ContractTypeID"}
                  stylingMode={"underlined"}
                  margin={"normal"}
                  shrink={true}
                  required={true}
                  showClearButton={true}
                  // disabled={loading || mode === "addFinal"}
                  disabled={true}
                  value={ContractTypeID}
                  itemRender={(e) => {
                    if (!e) return null;
                    return `${e.ContractTypeID} - ${e.VoucherTypeName}`;
                  }}
                  InputLabelProps={{ shrink: true }}
                  onSelectionChanged={e => {
                    if (e.selectedItem && e.selectedItem.ContractTypeID !== ContractTypeID) {
                      this.handleChange("ContractTypeID", e)
                      this.onChangeCbo("ContractTypeID", e)
                    } else if (_.isNull(e.selectedItem)) { //Onchange Clear Button Data
                      onChange(e.selectedItem, "ContractTypeID")
                      onChange(e.selectedItem, "ContractNo")
                    }
                  }}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} style={{ height: 70 }}>
                <TextField
                  error={iEmptyContractNo && _.get(errorForm, "ContractNo", false)}
                  label={Config.lang("ERP_So_hop_dong")}
                  variant={"standard"}
                  margin={"normal"}
                  required={true}
                  value={ContractNo}
                  InputLabelProps={{ shrink: true }}
                  disabled={loading || dataContractType}
                  inputProps={{ readOnly: dataContractType }}
                  fullWidth
                  onChange={e => onChange(e, "ContractNo")}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} style={{ height: 70 }}>
                <Combo
                  label={Config.lang("ERP_Trang_thai")}
                  error={iEmptyEStatus && _.get(errorForm, "EStatus", false)}
                  displayExpr={"EStatusName"}
                  stylingMode={"underlined"}
                  valueExpr={"EStatus"}
                  margin={"normal"}
                  showClearButton={true}
                  required={true}
                  shrink={true}
                  dataSource={getCboStatus}
                  disabled={loading}
                  value={mode === "addFinal" ? "1" : EStatus} //Default EStatus = 1
                  onValueChanged={(e) => {
                    onChange(e, "EStatus")
                    this.onChangeCbo("EStatus", e)
                  }}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} style={{ height: 70 }}>
                <Combo
                  label={Config.lang("ERP_Loai_tien")}
                  error={iEmptyCurrencyID && _.get(errorForm, "CurrencyID", false)}
                  margin={"normal"}
                  valueExpr={"CurrencyID"}
                  stylingMode={"underlined"}
                  displayExpr={"CurrencyID"}
                  showClearButton={true}
                  required={true}
                  shrink={true}
                  disabled={loading}
                  value={CurrencyID}
                  dataSource={getCboCurrency}
                  onValueChanged={e => {
                    if (_.isNull(e.value)) { //Onchange Clear Button Data
                      onChange(e.value, "CurrencyID")
                      onChange(e.value, "ExchangeRate")
                      this.setState({ ExchangeRate: 0 })
                    } else {
                      this.handleChange("CurrencyID", e)
                      this.onChangeCbo("CurrencyID", e)
                    }
                  }}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} style={{ height: 70 }}>
                <TextField
                  error={iEmptyExchangeRate && _.get(errorForm, "ExchangeRate", false)}
                  label={Config.lang("ERP_Ty_gia")}
                  variant={"standard"}
                  margin={"normal"}
                  value={_.toString(this.numberFormat(ExchangeRate))}
                  disabled={loading}
                  inputProps={{ readOnly: true }}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  onChange={(e) => onChange(e, "ExchangeRate")}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} style={{ height: 70 }}>
                <DateBoxPicker
                  label={Config.lang("ERP_Ngay_ky")}
                  error={isEmptyContractDate && _.get(errorForm, "ContractDate", false)}
                  height={30}
                  width={"100%"}
                  margin={"normal"}
                  stylingMode={"underlined"}
                  shrink={true}
                  required={true}
                  showClearButton={true}
                  useMaskBehavior={true}
                  disabled={loading}
                  value={ContractDate ? ContractDate : moment().format("YYYY-MM-DD")}
                  onValueChanged={e => onChange(e, "ContractDate")}
                  InputLabelProps={{
                    shrink: true,
                    style: { marginTop: -7 }
                  }}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} style={{ height: 70 }}>
                <Combo.Employees
                  acceptCustomValue={true}
                  dataSource={dataCboEmployees}
                  skip={this.filterCboEmployees.skip}
                  limit={this.filterCboEmployees.limit}
                  error={iEmptyEmployeeID && _.get(errorForm, "EmployeeID", false)}
                  displayExpr={"EmployeeName"}
                  keyExpr={"EmployeeID"}
                  valueExpr={"EmployeeID"}
                  subTextExpr={"EmployeeID"}
                  value={Employee || defaultEmployeeID}
                  stylingMode={"underlined"}
                  loading={loadingCboEmployee}
                  label={Config.lang("ERP_Nguoi_lap")}
                  showClearButton={true}
                  required={true}
                  shrink={true}
                  allowHover={false}
                  onValueChanged={e => {
                    if (_.isNull(e.value) && !_.isNull(defaultEmployeeID)) this.setState({ defaultEmployeeID: e.value })
                    this.handleChange("EmployeeID", e)
                    this.onChangeCbo("EmployeeID", e)
                  }}
                  onInputChanged={(e) => {
                    this.filterCboEmployees.strSearch = e.target.value;
                    this.filterCboEmployees.skip = 0;
                    this.loadCboEmployees(true);
                  }}
                  onLoadMore={(e) => {
                    this.filterCboEmployees.skip = e.skip;
                    this.filterCboEmployees.limit = e.limit;
                    this.loadCboEmployees();
                  }}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} style={{ height: 70 }}>
                <DateBoxPicker
                  error={isEmptyStartDate && _.get(errorForm, "StartDate", false)}
                  InputLabelProps={{
                    shrink: true,
                    style: { marginTop: -7 }
                  }}
                  height={30}
                  useMaskBehavior={true}
                  max={EndDate || ""}
                  label={Config.lang("ERP_Ngay_bat_dau")}
                  width={"100%"}
                  stylingMode={"underlined"}
                  required={true}
                  showClearButton={true}
                  shrink={true}
                  value={StartDate ? StartDate : moment().format("YYYY-MM-DD")}
                  onValueChanged={e => onChange(e, "StartDate")}
                  margin={"normal"}
                  disabled={loading}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} style={{ height: 70 }}>
                <DateBoxPicker
                  error={iEmptyEndDate && _.get(errorForm, "EndDate", false)}
                  InputLabelProps={{
                    shrink: true,
                    style: { marginTop: -7 }
                  }}
                  height={30}
                  useMaskBehavior={true}
                  min={StartDate || ""}
                  label={Config.lang("ERP_Ngay_ket_thuc")}
                  width={"100%"}
                  stylingMode={"underlined"}
                  required={true}
                  showClearButton={true}
                  shrink={true}
                  value={EndDate}
                  onValueChanged={e => onChange(e, "EndDate")}
                  margin={"normal"}
                  disabled={loading}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} style={{ height: 70 }}>
                <Combo
                  label={Config.lang("ERP_Phuong_thuc_thanh_toan")}
                  dataSource={getCboPaymentMethod}
                  displayExpr={"PaymentMethodName"}
                  valueExpr={"PaymentMethodID"}
                  stylingMode={"underlined"}
                  showClearButton={true}
                  margin={"normal"}
                  disabled={loading}
                  value={PaymentMethodID}
                  shrink={true}
                  itemRender={(e) => {
                    if (!e) return null;
                    return `${e.PaymentMethodID} - ${e.PaymentMethodName}`;
                  }}
                  onValueChanged={e => {
                    onChange(e, "PaymentMethodID")
                    this.onChangeCbo("PaymentMethodID", e)
                  }}
                />
              </Col>
              <Col xs={12} sm={6} md={4} lg={4} style={{ height: 70 }}>
                <label className={`MuiFormLabel-root ${classes.labelNumberFormat}`}>
                  {Config.lang("Dung_sai")}
                </label>
                <NumberFormat
                  fullWidth
                  customInput={TextField}
                  thousandSeparator={true}
                  isNumericString={true}
                  decimalScale={2}
                  value={String(ExceedContract)}
                  onValueChange={e => onChange(e, "ExceedContract")}
                  InputProps={{
                    endAdornment: "%",
                  }}
                />
              </Col>
            </Row>
          </Col>
          <Col md={0} lg={3}></Col>
        </Row>

      </div>
    );
  }
}

W06F0002GeneralInfo.propTypes = {
  loading: PropTypes.bool,
  mode: PropTypes.string,
  errorForm: PropTypes.object,
  formData: PropTypes.object,
  onChange: PropTypes.func,
  onLoading: PropTypes.func,
  onChangeCbo: PropTypes.func,
};

export default compose(
  connect(state => ({
    getCboStatus: state.W06F0002.getCboStatus,
    getCboCurrency: state.W06F0002.getCboCurrency,
    getCboContractType: state.W06F0002.getCboContractType,
    getCboPaymentMethod: state.W06F0002.getCboPaymentMethod,
  }),
    dispatch => ({
      w06f0002Actions: bindActionCreators(W06F0002Actions, dispatch)
    })))(React.forwardRef((props, ref) => <W06F0002GeneralInfo innerRef={ref} {...props} />));
