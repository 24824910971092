import IconButton from "@material-ui/core/IconButton";
import withStyles from "@material-ui/core/styles/withStyles";
import Tooltip from "@material-ui/core/Tooltip";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Column } from "devextreme-react/data-grid";
import _ from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { browserHistory } from "react-router";
import { bindActionCreators, compose } from "redux";
import Config from "../../../../config";
import * as W06F0003Actions from "../../../../redux/W0X/W06F0003/W06F0003_actions";
import Icons from "../../../common/icons/";
import GridActionBar from "../../../grid-container/grid-actionbar";
import GridContainer from "../../../grid-container/grid-container";
import PopoverAction from "../../../grid-container/popover-action";

const styles = {
    divIcon: {
        right: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItem: 'center',
        padding: '3px 11px',
        zIndex: 999,
        position: 'absolute',
        background: '#FFFFFF',
        boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.1)',
        listStyle: 'none',
        marginTop: '-17px',
        borderRadius: '4px',
    },
    divIconItem: {
        padding: 0,
        width: 30,
        height: 30,
        marginRight: 4,
        '&:last-child': {
            marginRight: 0
        }
    },
    tabTitle: {
        fontSize: 16,
        fontWeight: 500,
        marginRight: 40
    },
    customPadding: {
        '& .dx-datagrid-headers': {
            '& td': {
                paddingTop: '5px !important',
                paddingBottom: '5px !important',
            }
        }
    }
};

class W06F0003TabMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mappingGridLoaded: false,
            totalDataMapping: 0,
            currentMappingRow: {},
            dataMappingBySaleContract: []
        };
        this.filterMappingBySaleContract = {
            ContractID: "",
            QuotationID: "",
            QuotationItemID: "",
            skip: 0,
            limit: 10
        };
        this.popoverGrid = null;
    }

    componentDidMount = () => {
        const { dataMaster, permission } = this.props;
        if (dataMaster && dataMaster.ContractID && permission > 0) {
            this.handleLoadApiMapping();
        }
    }

    handleLoadApiMapping = () => {
        const { dataMaster, dataMapping, paramsMapping } = this.props;
        this.filterMappingBySaleContract.ContractID = dataMaster.ContractID;
        if (dataMapping && _.isEmpty(dataMapping.rows) && _.isEmpty(paramsMapping)) {
            this.loadMappingBySaleContract();
        } else {
            if (paramsMapping?.ContractID === dataMaster.ContractID) {// Có data và trùng ContractID chỉ setState
                this.setState({
                    dataMappingBySaleContract: dataMapping.rows || [],
                    totalDataMapping: dataMapping.total || 0
                })
            } else { // Khác conTractID laod lại
                this.loadMappingBySaleContract();
            }
        }
    }

    loadMappingBySaleContract = () => {
        const { skip, limit, ContractID, QuotationID, QuotationItemID } = this.filterMappingBySaleContract;
        const values = JSON.stringify([{ QuotationID, QuotationItemID }])
        const params = {
            ContractID,
            values,
            skip,
            limit
        };
        this.setState({ mappingGridLoaded: true });
        this.props.w06f0003Actions.loadMappingBySaleContract(params, (error, data) => {
            this.setState({ mappingGridLoaded: false });
            if (error) {
                let message = error.message || Config.lang("ERP_Loi_chua_xac_dinh");
                Config.popup.show("INFO", message);
                return false;
            }
            if (data) {
                this.setState({
                    dataMappingBySaleContract: data.rows ? data.rows : data,
                    totalDataMapping: data.total ? data.total : 0
                });
            }
        });
    };

    handleClick = (e, currentMappingRow) => {
        const target = e.currentTarget;
        this.setState({
            currentMappingRow
        });
        if (this.popoverGrid) this.popoverGrid.instance.show(target);
    };

    handleClose = (stateName) => {
        this.setState({ [stateName]: null });
    };

    popupActionMapping = () => {
        const { currentMappingRow } = this.state;
        const { classes, permission } = this.props;
        return (
            <>
                <PopoverAction
                    reference={ref => this.popoverGrid = ref}
                    position={Config.isMobile ? "right" : "left"}
                    maxWidth={300}
                    deferRendering={false}
                >
                    <div style={{ padding: 4 }}>
                        <Tooltip title={Config.lang("ERP_Xem")}>
                            <IconButton
                                disabled={!(permission > 1)}
                                aria-label={"View"}
                                className={classes.divIconItem}
                                onClick={() => this.onActionBtn("view", currentMappingRow)}
                            >
                                <Icons name={"view"} />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={Config.lang("ERP_Sua")}>
                            <IconButton
                                disabled={!(permission > 2)}
                                aria-label={"Edit"}
                                className={classes.divIconItem}
                                onClick={() => this.onActionBtn("view", currentMappingRow)}
                            >
                                <Icons name={"edit"} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </PopoverAction>
            </>
        );
    };

    onActionBtn = (mode, data) => {
        if (!data) return;
        let screen = "";
        let params = {};
        switch (mode) {
            case "view":
                params = {
                    MappingID: data.MappingID,
                    mode: "view"
                }
                screen = "W05F0007";
                break;
            case "edit":
                params = {
                    MappingID: data.MappingID,
                    mode: "edit"
                }
                screen = "W05F0007";
                break;
            default:
                break;
        }
        browserHistory.push({
            pathname: Config.getRootPath() + screen,
            state: params
        });
    };

    renderIconAction = (e) => {
        const { classes } = this.props;
        const { data } = e.row;
        return (
            <IconButton
                aria-label={"view"}
                aria-describedby={data.MappingItemID}
                className={classes.divIconItem}
                onClick={(e) => this.handleClick(e, data)}
                disabled={false}
            >
                <MoreVertIcon />
            </IconButton>
        );
    };

    renderBtnMappingAction = (e, mode) => {
        return (
            mode === "mobile" ?
                this.renderIconAction(e)
                : <GridActionBar>
                    {this.renderIconAction(e)}
                </GridActionBar>
        );
    };

    render() {
        const { classes } = this.props;
        const { mappingGridLoaded, dataMappingBySaleContract } = this.state;
        return (
            <>
                {/* Grid Mapping By Sale Contract */}
                <div className={classes.tabTitle} style={{ marginBottom: 15 }}>{Config.lang("ERP_Thong_tin_mapping")}</div>
                <div className={classes.customPadding}>
                    <GridContainer
                        totalItems={dataMappingBySaleContract.length}
                        dataSource={dataMappingBySaleContract}
                        loading={mappingGridLoaded}
                        typePaging={"normal"}
                        keyExpr={"MappingItemID"}
                        elementAttr={{ style: 'min-height: 200px' }}
                        showBorders={false}
                        columnAutoWidth={true}
                        pagerFullScreen={false}
                        hoverStateEnabled={true}
                        allowColumnResizing={true}
                        rowAlternationEnabled={true}
                    >
                        <Column
                            caption={Config.lang("ERP_Hanh_dong")}
                            visible={Config.isMobile}
                            alignment={"center"}
                            cellRender={(e) => this.renderBtnMappingAction(e, "mobile")}
                        />
                        <Column
                            caption={Config.lang("ERP_Ma_mapping")}
                            dataField={"MappingID"}
                            width={150}
                        />
                        <Column
                            caption={Config.lang("ERP_So_mapping")}
                            dataField={"MappingNo"}
                            width={150}
                            alignment={"right"}
                        />
                        <Column
                            caption={Config.lang("ERP_So_hop_dong_mua")}
                            dataField={"ContractNo"}
                            width={180}
                        />
                        <Column
                            caption={Config.lang("ERP_Nha_cung_cap")}
                            dataField={"Supplier"}
                            width={150}
                        />
                        <Column
                            caption={Config.lang("ERP_Ma_lot")}
                            dataField={"LotNo"}
                            width={180}
                        />
                        <Column caption={Config.lang("ERP_Ma_hang")}
                            cssClass={"no-padding"}>
                            <Column
                                caption={Config.lang("ERP_Hop_dong_mua")}
                                dataField={"InventoryIDPO"}
                                width={150}
                                cssClass={"no-padding"}
                            />
                        </Column>
                        <Column caption={Config.lang("ERP_Ten_hang")}>
                            <Column
                                caption={Config.lang("ERP_Hop_dong_mua")}
                                dataField={"InventoryNamePO"}
                                width={150}
                            />
                        </Column>
                        <Column caption={Config.lang("ERP_Ma_hang")}>
                            <Column
                                caption={Config.lang("ERP_Hop_dong_ban")}
                                dataField={"InventoryIDSO"}
                                width={150}
                            /></Column>
                        <Column caption={Config.lang("ERP_Ten_hang")}>
                            <Column
                                caption={Config.lang("ERP_Hop_dong_ban")}
                                dataField={"InventoryNameSO"}
                                width={150}
                            />
                        </Column>
                        <Column caption={Config.lang("ERP_So_luong")}
                            alignment={"right"}>
                            <Column
                                caption={Config.lang("ERP_Bao_yeu_cau")}
                                dataField={"QuantityPack"}
                                width={120}
                                alignment={"right"}
                            />
                        </Column>
                        <Column caption={Config.lang("ERP_So_luong_yeu_cau")}
                            alignment={"right"}>
                            <Column
                                caption={Config.lang("ERP_Mapping")}
                                dataField={"Quantity"}
                                width={180}
                                alignment={"right"}
                            />
                        </Column>
                        <Column caption={Config.lang("ERP_So_luong")}
                            alignment={"right"}
                            cssClass={"no-padding"}>
                            <Column
                                caption={Config.lang("ERP_Thuc_hien_mapping")}
                                dataField={"QuantityActual"}
                                width={200}
                                alignment={"right"}
                                cssClass={"no-padding"}
                            />
                        </Column>
                        <Column
                            caption={Config.lang("ERP_So_luong_con_lai")}
                            dataField={"QuantitRemain"}
                            width={150}
                            alignment={"right"}
                        />
                        <Column caption={Config.lang("ERP_Thoi_gian_dong_hang")} alignment={"center"} >
                            <Column
                                caption={Config.lang("ERP_Bat_dau")}
                                dataField={"StartDate"}
                                alignment={"center"}
                                dataType={"date"}
                                width={150}
                                format={"dd/MM/yyyy"}
                            />
                            <Column
                                caption={Config.lang("ERP_Ket_thuc")}
                                dataField={"EndDate"}
                                alignment={"center"}
                                dataType={"date"}
                                width={150}
                                format={"dd/MM/yyyy"}
                            />
                        </Column>
                        <Column
                            caption={Config.lang("ERP_Don_vi_det_bao")}
                            dataField={"UnitPackID"}
                            width={150}
                        />
                        <Column
                            fixed={true}
                            alignment={"right"}
                            fixedPosition={"right"}
                            visible={!Config.isMobile}
                            cellRender={(e) => this.renderBtnMappingAction(e, "desktop")}
                        />
                        {dataMappingBySaleContract.length > 0 && this.popupActionMapping()}
                    </GridContainer>
                </div>
            </>
        );
    }
}

W06F0003TabMapping.propsTypes = {
    permission: PropTypes.number,
    dataMaster: PropTypes.object,
};

export default compose(connect((state) => ({
    dataMapping: state.W06F0003.dataMapping,
    paramsMapping: state.W06F0003.paramsMapping,
}), dispatch => ({
    w06f0003Actions: bindActionCreators(W06F0003Actions, dispatch)
})), withStyles(styles))(W06F0003TabMapping);
